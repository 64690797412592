import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  BooleanField,
  EmailField,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import PasswordResetModal from "../Members/PasswordResetModal";
import ChangeActiveStatus from "../Members/ChangeActiveStatus";

export const SupporterGrid = (props: any) => {
  return (
    <>
      <List {...props} pagination={false}>
        <Datagrid rowClick="show">
          <TextField source="fullName" label="Name" />
          <TextField source="phoneNumber" label="Phone Number" />
          <TextField source="email" label="Email" />
          <BooleanField source="active" label="Active" />
        </Datagrid>
      </List>
    </>
  );
};

export const SupporterShow = (props: any) => {
  const { user } = useClearMindAdminContext();
  const isAuthorizedUser = () => {
    return (
      user.isSupportAdmin || user.isPartnerAdmin || user.isSuperAdmin
    );
  };
  const isAuthorizedAdmin = () => {
    return user.isSuperAdmin || user.isPartnerAdmin
  }

  return (
    <>
      <Show {...props}>
        <Grid container spacing={2}>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="fullName" label="Name" />
              <TextField source="phoneNumber" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <EmailField source="email" />
              <TextField source="memberName" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="supporterRole" label="Relationship to Member" />
              <BooleanField source="active" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            {isAuthorizedUser() && <PasswordResetModal baseUrl={process.env.REACT_APP_CLEARMIND_BACKEND_URL} {...props} />}
            <br />
            {isAuthorizedAdmin() && <ChangeActiveStatus baseUrl={process.env.REACT_APP_CLEARMIND_BACKEND_URL} {...props} />}
          </Grid>
        </Grid>
      </Show>
    </>
  );
};
