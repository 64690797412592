import { types, Instance } from "mobx-state-tree";
import DrugPanelResults from "./DrugPanelResults";

const HistoricalResult = types
  .model("HistoricalResult", {
    testSessionId: types.string,
    panelResults: types.maybe(types.array(DrugPanelResults)),
    dateTestTaken: types.string,
    testResult: types.string,
    substancesDetected: types.maybeNull(types.string)
  })
  .views((self) => ({
    get passFailColor() {
      if (self.testResult === "Misstep") {
        return "#E5AC2E";
      } else if (self.testResult === "Fail") {
        return "#2c2c2c";
      } else if (self.testResult === "Pass") {
        return "#20f3f3";
      } else {
        return "#ffff";
      }
    },
  }));

export default HistoricalResult;

export interface IResults extends Instance<typeof HistoricalResult> { }
export interface IResultSnapshot extends Instance<typeof HistoricalResult> { }
