import { TextInput, Create, SimpleForm, required, DateInput, SelectInput, NumberInput, maxLength } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import stateOptions from "./StateList";
import { phoneNumberFormatter, validateNumber } from "./MemberGrid";
import { useEffect, useState } from "react";
import axios from "axios";
import programOptions from "./ProgramsList";
import languageOptions from "./LanguageList";
import InputList from "../../CustomFields/InputList";

export const caseNumberFormatter = (value: string) => {
    if (!value) {
        return
    }
    // Filter out invalid characters
    const filteredValue = value.replace(/[^a-zA-Z0-9-]/g, "");
    return filteredValue
};
export const PartnerMemberCreate = (props: any) => {
    const { user } = useClearMindAdminContext();
    const [facilityPartnerList, setFacilityPartnerList] = useState<any>();
    const [showTestProvidedField, setShowTestProvidedField] = useState<boolean>(false);
    useEffect(() => {
        const getPartners = async () => await axios.get("/monitoring-partners")
        getPartners().then((res) => {
            const partnerArray: { id: any; name: any; }[] = [
                {
                    id: "",
                    name: "None",
                },
            ]
            res.data.forEach((partner: any) => {
                partnerArray.push({
                    id: partner.facilityCode,
                    name: partner.facilityName
                })
            });
            const sortedPartnerArray = partnerArray.slice(1).sort((a, b) => a.name.localeCompare(b.name));
            sortedPartnerArray.unshift(partnerArray[0]);
            setFacilityPartnerList(sortedPartnerArray)
        })
    }, [])
    const parseFacilityCode = (value: string) => {
        return [value];
    };
    const formatFacilityCode = (value: string[]) => {
        if (!value || value.length === 0) {
            return "";
        }
        return value[0];
    };
    const getFacilityCodeInput = () => {
        return user.isSuperAdmin ?
            <SelectInput
                source="facilityCode"
                label={"Facility Code"}
                defaultValue="None"
                fullWidth
                validate={[required()]}
                parse={parseFacilityCode}
                choices={facilityPartnerList}
            />
            :
            <TextInput source="facilityCode"
                fullWidth
                defaultValue={[user.facilityCode]}
                disabled
                validate={[required()]}
                parse={parseFacilityCode}
                format={formatFacilityCode}
            />
    }
    const onPackageChange = (e: any) => {
        if (e === -1) {
            setShowTestProvidedField(true)
        } else {
            setShowTestProvidedField(false)
        }
    }
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="email" fullWidth validate={[required()]} />
                <TextInput source="firstName" fullWidth validate={[required()]} />
                <TextInput source="lastName" fullWidth validate={[required()]} />
                <InputList
                    infoSpanText="If needed, you can add case numbers to your member"
                    arraySource={"caseNumberList"}
                    arrayLabel="Case Numbers"
                    inputSource={"caseNumber"}
                    inputFormat={caseNumberFormatter}
                    addButtonText="Add new case number"
                    inputValidator={[maxLength(40)]}
                />
                <SelectInput source="language" fullWidth validate={[required()]} choices={languageOptions} />
                <TextInput source="addedBy" fullWidth defaultValue={`${user.firstName} ${user.lastName}`} disabled />
                <TextInput source="phoneNumber" label="Cell Phone Number" format={phoneNumberFormatter} onBlur={(e) => { validateNumber(e.target.value); }} fullWidth validate={[required()]} />
                <DateInput source="birthdate" fullWidth validate={[required()]} />
                <TextInput source="streetAddress" fullWidth validate={[required()]} />
                <TextInput source="city" fullWidth validate={[required()]} />
                <SelectInput source="state" fullWidth validate={[required()]} choices={stateOptions} />
                <TextInput source="zipCode" fullWidth validate={[required()]} />
                <SelectInput source="numberOfTests" onChange={(e) => onPackageChange(e.target.value)} label="Testing Package" fullWidth validate={[required()]} choices={programOptions} />
                {showTestProvidedField && (
                    <NumberInput
                        source="testsProvided"
                        fullWidth
                        validate={[required()]}
                        label="Number of tests to provide"
                    />
                )}
                <TextInput source="role" fullWidth defaultValue={"Member"} disabled={true} validate={[required()]} />
                {getFacilityCodeInput()}
            </SimpleForm>
        </Create>
    );
};
