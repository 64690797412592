import { types, Instance } from "mobx-state-tree";
const DrugPanelResults = types.model("DrugPanelResults model", {
  result: types.string,
  controlLine: types.boolean,
  testLine: types.boolean,
  substanceName: types.maybeNull(types.string)
});

export default DrugPanelResults;
export interface IDrugPanelResults extends Instance<typeof DrugPanelResults> { }
