import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ChangeActiveStatus from "./ChangeActiveStatus";


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        mainButton: {
            backgroundColor: "#2196f3",
            marginTop: "12px",
            marginRight: "12px",
            color: "#FFF",
            "&:hover": {
                backgroundColor: "#66a7dc",
            },
            width: 255
        },
        yesButton: {
            margin: theme.spacing(1),
            backgroundColor: "#2DAF79",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#3EC0BE",
                color: "#FFF",
            }
        },
        noButton: {
            margin: theme.spacing(1),
            backgroundColor: "lightgray",
            color: "black",
            "&:hover": {
                backgroundColor: "darkgray",
                color: "#FFF",
            }
        },
        text: {
            color: "black",
        },
        buttonBox: {
            marginTop: "26px"
        },
        upperDivider: {
            marginBottom: "10px"
        },
        lowerDivider: {
            marginTop: "10px"
        },
        title: {
            marginBottom: "26px"
        },
        icon: {
            fontSize: "65px",
            color: "#2096f3"
        },
    }),
);

const ActiveStatusModal: React.FC = (props) => {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const body = (
        <div style={modalStyle} className={classes.paper} >
            <Box display="flex" justifyContent="center" >
                <HelpOutlineIcon className={classes.icon} />
            </Box >
            <Box className={classes.title} display="flex" justifyContent="center" >
                <h2>Are you sure?</h2>
            </Box >
            <Divider className={`${classes.upperDivider}`} />
            <Box display="flex" justifyContent="center">
                <Typography className={classes.text}>
                    Marking a user as inactive will remove them from all currently scheduled tests.
                </Typography>
            </Box>
            <Divider className={`${classes.lowerDivider}`} />
            <Box className={classes.buttonBox} display="flex" justifyContent="space-evenly">
                < ChangeActiveStatus style={{ margin: 8 }} baseUrl={process.env.REACT_APP_CLEARMIND_BACKEND_URL!} {...props} />
                <Button onClick={() => setShowChangePassword(!showChangePassword)} className={classes.noButton}>Go Back</Button>
            </Box>
        </div >
    );

    return (
        <>
            <Box display="flex">
                <Button className={classes.mainButton} onClick={() => setShowChangePassword(!showChangePassword)}>
                    Change To Inactive
                </Button>
            </Box>
            <Modal open={showChangePassword} >
                {body}
            </Modal >
        </>

    );
}

export default ActiveStatusModal