import { ChangeEventHandler, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput, required, BooleanInput, useRecordContext
} from "react-admin";
import { PartnerAutocomplete } from "./PartnerAutocomplete";

export const AdminUserEdit = (props: any) => {
  return <Edit {...props}>
    <SimpleForm>
      <Fields />
    </SimpleForm>
  </Edit>
};

const Fields = () => {
  const record = useRecordContext();
  const [selectedRole, setSelectedRole] = useState(record?.role);

  const onRoleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelectedRole(e.target.value);
  }

  const isPartner = selectedRole === "TreatmentPartnerAdmin" || selectedRole === "BusinessPartnerAdmin";

  const getLabelPrefix = () => {
    return selectedRole === "BusinessPartnerAdmin" ? "Business" : "Facility"
  }

  const getPartnerType = () => {
    return selectedRole === "BusinessPartnerAdmin" ? "business" : "treatment";
  }

  const phoneNumberFormatter = (value: string) => {
    if (!value) {
      return;
    }
    let newNum = value.replace(/\D/g, "");
    return newNum.substring(0, 10);
  };

  const validateNumber = (value: any) => {
    const compare = /^[0-9]{10}$/g;
    if (!value.match(compare)) {
      alert("Phone Number must be in 1234567890 format");
    }
  };

  return (
    <>
      <TextInput source="email" fullWidth validate={[required()]} />
      {isPartner && (
        <TextInput
          source="phoneNumber"
          format={phoneNumberFormatter}
          onBlur={(e) => {
            validateNumber(e.target.value);
          }}
          fullWidth
          validate={[required()]}
        />
      )}
      <TextInput source="firstName" fullWidth validate={[required()]} />
      <TextInput source="lastName" fullWidth validate={[required()]} />
      <SelectInput
        onChange={onRoleChange}
        source="role"
        choices={[
          { id: "SupportAdmin", name: "Support Admin" },
          { id: "SuperAdmin", name: "Super Admin" },
          { id: "ProctorAdmin", name: "Proctor Admin" },
          { id: "Proctor", name: "Proctor" },
          { id: "TreatmentPartnerAdmin", name: "Treatment Partner Admin" },
          { id: "BusinessPartnerAdmin", name: "Business Partner Admin" },
        ]}
        fullWidth
        validate={[required()]}
      />
      <BooleanInput source="active" />
      {isPartner && <PartnerAutocomplete partnerType={getPartnerType()} label={`${getLabelPrefix()} Code`} fullWidth />}
    </>
  );
}
