import React from "react";
import Grid from "@material-ui/core/Grid";
import ProctorCheckListItem from "./ProctorCheckListItem";
import FormLabel from "@material-ui/core/FormLabel";
import { ITestResult } from "../../Models/TestResult";
import { observer } from "mobx-react";
import TabNextButton from "./TabNextButton";
import { Record } from "react-admin";
import { ITestStep } from "../../Models/TestStep";

interface ProctorCheckListProps {
  testResult: ITestResult;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
  currentTabIndex: number;
  steps: ITestStep[];
  record: Record;
  setSeekToSeconds: (videoTimeInSeconds: number) => void;
}
const ProctorCheckList: React.FC<ProctorCheckListProps> = (props) => {
  const {
    testResult,
    setActiveTabIndex,
    currentTabIndex,
    steps,
    setSeekToSeconds } = props

  const getStepForIndex = (index: number) => {
    return steps[index] || {};
  }

  return (
    <>
      <Grid container direction="row" spacing={2} style={{ paddingLeft: 7 }}>
        <Grid item>
          <FormLabel>Pass</FormLabel>
        </Grid>
        <Grid item>
          <FormLabel>Review</FormLabel>
        </Grid>
        <Grid item>
          <FormLabel>Fail</FormLabel>
        </Grid>
      </Grid>
      {
        testResult.proctorQuestionsAndAnswers?.map((questionAndAnswer, i) => {
          const step = getStepForIndex(i)

          return <ProctorCheckListItem
            key={`proctor-check-list-item-${i}`}
            record={props.record}
            label={questionAndAnswer.question}
            answer={questionAndAnswer.answer}
            notes={questionAndAnswer.notes}
            onNotesChange={questionAndAnswer.onNotesChange}
            onAnswerChange={questionAndAnswer.onAnswerChange}
            step={step}
            testResult={testResult}
            setSeekToSeconds={setSeekToSeconds}
          />
        }
        )
      }
      {testResult.allProctorQuestionsAnswered && <TabNextButton setTabIndex={setActiveTabIndex} currentTabIndex={currentTabIndex} />}
    </>
  );
};

export default observer(ProctorCheckList);
