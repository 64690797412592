import axios from "axios";

const completeVideoUpload = async (memberId: string) => {
    try {
        const response = await axios.post(
            `/members/${memberId}/complete-video-upload`
        );
        if (response.status > 299){
            throw response;
        } 
        return { data: "Test Session Upload Completed" };
        
    } catch (e: any) {
        console.log(e);
        return { data: "No video uploads found. The video may not exist." }; 
    }
}

export default completeVideoUpload;