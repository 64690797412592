import { Instance, types } from "mobx-state-tree";


export const ScheduledEvent = types
  .model("Scheduled Event", {
    id: types.string,
    memberId: types.maybe(types.string),
    eventType: types.string,
    eventDate: types.string,
    eventDetails: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get dateKey() {
      return new Date(self.eventDate).toDateString().replace("/", "");
    },
    get localDateString() {
      return new Date(self.eventDate).toLocaleDateString();
    },
    get localeTimeString() {
      return new Date(self.eventDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
    },
  }))

export default ScheduledEvent;

export interface IScheduledEvent extends Instance<typeof ScheduledEvent> { }
