import React from "react";
import Button from "@material-ui/core/Button";
import Forward from "@material-ui/icons/Forward";
import Grid from "@material-ui/core/Grid";

interface TabNextButtonProps {
    setTabIndex: (tabIndex: number) => void;
    currentTabIndex: number
}

const TabNextButton: React.FC<TabNextButtonProps> = (props) => {
    const { setTabIndex, currentTabIndex } = props;
    return <Grid
        container
        direction="column"
        alignItems="center"
        style={{ marginTop: 10 }}
    >
        <Button
            onClick={() => setTabIndex(currentTabIndex + 1)}
            variant="contained"
            endIcon={<Forward />}
            size="large">
            Next
        </Button>
    </Grid>
}

export default TabNextButton;