import { Auth } from "aws-amplify";
import axios from "axios";
import { ReactNode, useCallback, useEffect, useState } from "react"
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Form, Message } from "semantic-ui-react";
import { CognitoUserAmplify } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui"
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import Loading from "../Loading/Loading";

interface SetupMfaAttributes {
    user: CognitoUserAmplify
    children: ReactNode
}
const SetupMfa: React.FC<SetupMfaAttributes> = ({ user, children }) => {
    const [qrCode, setQrCode] = useState("")
    const [userCode, setUserCode] = useState("")
    const [mfaSetupNeeded, setMfaSetupNeeded] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [verificationErrorMessage, setVerificationErrorMessage] = useState("")

    const [mfaVerified, setMfaVerified] = useState(false)

    const { promiseInProgress: qrCodePromiseInProgress } = usePromiseTracker({ area: "qr-code" })
    const { promiseInProgress: verificationPromiseInProgress } = usePromiseTracker({ area: "verification" })

    const checkMfaEnabled = useCallback(async () => {
        try {
            const mfaSettingList = await new Promise((resolve, reject) => user.getUserData((err, result) => {
                if (err) {
                    reject(err.message);
                }

                resolve(result?.UserMFASettingList ?? []);
            })) as string[];

            const isMfaSetup = mfaSettingList.indexOf("SOFTWARE_TOKEN_MFA") > -1
            const userInfo = await new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => user.getUserAttributes((err, result) => {
                if (err) {
                    reject(err.message);
                }

                resolve(result);
            }))

            if (!userInfo) {
                throw new Error("Attributes not found");
            }

            const mfaEnabledFromAttributes = userInfo.find(attribute => attribute.Name === "custom:mfaEnabled")?.Value.trim().toLocaleLowerCase() === "true";
            const userId = userInfo.find(attribute => attribute.Name === "sub")?.Value;

            if (!userId) {
                throw new Error("User id not found")
            }
            setMfaSetupNeeded(mfaEnabledFromAttributes && !isMfaSetup)

            if (mfaEnabledFromAttributes) {
                trackPromise(getQrCode(userId), "qr-code")
            }
        } catch (error: any) {
            console.error("Error checking MFA:", error)
            setErrorMessage("Error checking MFA status.")
        }
    }, [user]);

    const getQrCode = async (userId: string) => {
        try {
            const session = await Auth.currentSession()
            const jwtToken = session.getAccessToken().getJwtToken()

            const result = await axios.get(`qr-code?accessToken=${jwtToken}&id=${userId}`)

            setQrCode(result.data.replace(/"/g, ""))
        } catch (error: any) {
            console.error("Error getting QR Code:", error)
            if (error.response) {
                console.error("Response data:", error.response.data)
                console.error("Response status:", error.response.status)
                console.error("Response headers:", error.response.headers)
            } else if (error.request) {
                console.error("Request data:", error.request)
            } else {
                console.error("Error message:", error.message)
            }
            console.error("Error config:", error.config)
        }
    }

    const verifyMfa = async () => {
        try {
            setVerificationErrorMessage("")
            const userInfo = await new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => user.getUserAttributes((err, result) => {
                if (err) {
                    reject(err.message);
                }

                resolve(result);
            }));

            if (!userInfo) {
                throw new Error("Error getting user attributes");
            }
            const session = await Auth.currentSession()
            const jwtToken = session.getAccessToken().getJwtToken()
            const userId = userInfo.find(attribute => attribute.Name === "sub")?.Value;

            const result = await trackPromise(axios.post(`verify-software-token?accessToken=${jwtToken}&code=${encodeURIComponent(userCode)}&id=${userId}`), "verification")

            if (result.status === 200) {
                setMfaVerified(true)
            }
        } catch (error: any) {
            setVerificationErrorMessage("Code verification failed.  Please double check the code");
        }
    }

    useEffect(() => {
        checkMfaEnabled()
    }, [checkMfaEnabled])

    if (qrCodePromiseInProgress) {
        return <div style={{ textAlign: "center", marginTop: 425 }} >
            <Loading />
        </div >
    }

    if (mfaSetupNeeded && !mfaVerified) {
        return <div style={{ marginTop: 200, color: "#fff" }}>
            {errorMessage && <Message negative>{errorMessage}</Message>}
            <h3 style={{ textAlign: "center" }}>Scan this QR Code in your authenticator app, then enter the code below.</h3>
            <div style={{ textAlign: "center", margin: "20px" }}>
                {qrCode && <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" style={{ maxWidth: "300px", height: "auto" }} />}
            </div>
            <Form inverted style={{ margin: "auto", width: 300, textAlign: "center" }}>
                <Form.Input
                    value={userCode}
                    onChange={(e) => setUserCode(e.target.value)}
                    label="Enter Code:"
                    required
                    inverted
                    error={verificationErrorMessage ? verificationErrorMessage : undefined}
                />
                <Form.Button onClick={verifyMfa} disabled={!userCode} loading={verificationPromiseInProgress}>Confirm Code</Form.Button>
            </Form>
        </div>
    } else {
        return <>{children}</>
    }
}

export default SetupMfa;