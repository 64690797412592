import { Instance } from "mobx-state-tree";
import User from "../../Models/User";
import { MonitoringPartnerList } from "../Partners/MonitoringPartnerList";
import { SingleMonitoringPartner } from "../Partners/SingleMonitoringPartnerList";
import { AdminUserList } from "../Users/AdminUserList";
import { ProctorUserList } from "../Users/ProctorUserList";
import { ProctorUserCreate } from "../Users/ProctorUserCreate";
import { AdminUserCreate } from "../Users/AdminUserCreate";

export const getAdminUserList = (user: Instance<typeof User>) => {
    if (user.isProctorAdmin) {
        return ProctorUserList
    }
    if (user.isAdmin) {
        return AdminUserList
    }
};
export const getAdminUserCreate = (user: Instance<typeof User>) => {
    if (user.isProctorAdmin) {
        return ProctorUserCreate
    }
    if (user.isAdmin) {
        return AdminUserCreate
    }
};

export const monitoringPartnerLabel = (user: Instance<typeof User>) => {
    if (user.isAdmin) {
        return "Treatment Partners";
    }
    return "My Facility";
};

export const membersLabel = (user: Instance<typeof User>) => {
    if (user.isAdmin) {
        return "Members";
    }
    return "My Members";
};

export const PartnerList = (user: Instance<typeof User>) => {
    if (user.isAdmin) {
        return MonitoringPartnerList;
    }
    if (user.isPartner) {
        return SingleMonitoringPartner;
    }
    return undefined
};

export const WithFilter = (FilteredComponent: any, enforcedFilter: any) => (props: any) => (
    <FilteredComponent {...props} filter={enforcedFilter} />
)

export const WithInitialValues = (Component: any, initialValues: any) => (props: any) => (
    <Component {...props} initialValues={initialValues} />
)
