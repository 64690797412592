import { FunctionField, Labeled } from "react-admin"

const FacilityCodeShowField = () => {
    return <Labeled label="Facility Code">
        <FunctionField render={(record: any) => {
            if (Array.isArray(record?.facilityCode)) {
                return record?.facilityCode.join(", ")
            }

            return record.facilityCode;
        }} /></Labeled>
}

export default FacilityCodeShowField;