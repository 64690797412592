enum MemberTestResults {
    Pass = "Pass",
    PassByAllowableSubstance = "Pass - Allowable Substance",
    FailByDecline = "Decline",
    FailByMisstep = "Misstep",
    PassWithInvalidResult = "Pass - Invalid Result",
    FailByDetection = "Detection",
    SelfReported = "Self Reported",
    FailByInvalid = "Invalid Result"
}

export default MemberTestResults