import React, { useState, createContext, useContext } from "react"
import { Authenticator, useTheme, View } from "@aws-amplify/ui-react"
import logo from "../../logo.png"
import { I18n } from "aws-amplify"
import IdleTimer from "react-idle-timer"
import { Checkbox } from "semantic-ui-react"
import authStorage from "../../AuthenticationStorage"
import LogoFadeInContainer from "../../Components/Controls/LogoFadeContainer"
import SetupMfa from "./SetupMfa"

const SignOutContext = createContext({})

export const useSignOutContext = (): any => {
  return useContext(SignOutContext)
}

const ClearMindAdminAuthenticator: React.FC<any> = (props) => {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("remember-me") === "true"
  )

  const onIdle = (signOut: () => void) => {
    if (!rememberMe) {
      signOut()
    }
  }

  const onRememberMeChange = (_: any, data: any) => {
    setRememberMe(data.checked)
    authStorage.setRememberMe(data.checked)
    localStorage.setItem("remember-me", data.checked)
  }

  I18n.putVocabulariesForLanguage("en", {
    "Sign in to your account": "Welcome Back!",
    "Create a new account": "Create Account",
    Email: "Enter your email",
    Password: "Enter your password",
    Birthdate: "Birthday",
    "Family Name": "Last Name",
    "Given Name": "First Name",
    "Reset your password": "Forgot your password?",
    "Confirm TOTP Code": "Authenticator App Code"
  })

  const components = {
    Header() {
      const { tokens } = useTheme()
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <LogoFadeInContainer>
            <img src={logo} alt="clearmind-logo" style={imageStyle} />
          </LogoFadeInContainer>
        </View>
      )
    },
    SignUp: {
      FormFields() {
        return (
          <>
            <Authenticator.SignUp.FormFields />
          </>
        )
      },
    },
    SignIn: {
      Footer() {
        return (
          <>
            <Checkbox
              label={
                <label
                  style={{
                    color: "white",
                    fontSize: "12px",
                    fontFamily: "\"Comfortaa\", sans-serif",
                  }}
                >
                  Remember me
                </label>
              }
              onChange={onRememberMeChange}
              checked={rememberMe}
              data-testid="remember me"
              style={{
                fontSize: "12px",
                fontFamily: "\"Comfortaa\", sans-serif",
                top: -20,
                left: 28,
                position: "relative",
              }}
            />
            <Authenticator.SignIn.Footer />
          </>
        )
      },
    },
  }

  const imageStyle = { maxWidth: 340, margin: "auto", display: "flex" }
  const authProps = { ...props }
  delete authProps.children

  return (
    <Authenticator loginMechanisms={["email"]} components={components}
      signUpAttributes={[
        "email",
        "given_name",
        "family_name",
        "birthdate",
      ]} {...authProps}>
      {({ signOut, user }) => (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <IdleTimer onIdle={() => onIdle(signOut)} timeout={1000 * 60 * 30} startOnMount />
          <SignOutContext.Provider value={{ signOut }}>
            <SetupMfa user={user}>
              {props.children}
            </SetupMfa>
          </SignOutContext.Provider>
        </div>
      )}
    </Authenticator>
  )
}

export default ClearMindAdminAuthenticator
