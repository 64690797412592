import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Button, Grid, Header } from "semantic-ui-react"
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext"
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Loading from "../Loading/Loading";
import PreferenceSelection from "./PreferenceSelection"
import Typography from "@material-ui/core/Typography";
import MemberPreferenceListComponent from "./MemberPreferencesComponent";
import { NotificationPreferenceModel } from "../../Models/NotificationPreferenceModel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        MainButton: {
            marginTop: "16px !important",
            backgroundColor: "#2196f3 !important",
            color: "#FFF !important",
            "&:hover": {
                backgroundColor: "#66a7dc !important",
            }
        },
        container: {
            padding: "0 !important"
        },
        header: {
            marginTop: "24px !important",
            marginBottom: "16px !important",
            padding: "0 !important"
        },
        infoText: {
            marginBottom: "16px",
            fontWeight: 500,
            padding: "0 !important"
        },
        sectionHeader: {
            marginBottom: "16px",
            fontWeight: 600,
            padding: "0 !important"
        },
        icon: {
            fontSize: "15px",
            color: "#2096f3"
        },
        buttonContainer: {
            marginBottom: "8px !important"
        }
    }),
);

const NotificationPreferencePage: React.FC = () => {
    const [preferences] = useState(NotificationPreferenceModel.create())
    const { user } = useClearMindAdminContext();
    const [successSnackBar, setSuccessSnackBar] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [autoHideDuration, setAutoHideDuration] = useState(1500)
    const [snackBarContentMessage, setSnackBarContentMessage] = useState("")
    const classes = useStyles()
    useEffect(() => {
        if (preferences.isLoaded) {
            trackPromise(preferences.loadDefaultPreferences(user.id))
        }
    }, [preferences, user.id]);
    const savePreferences = () => {
        trackPromise(preferences.updateDefaultPreferences(user.id)).then(() => {
            setSnackBarContentMessage("You have successfully updated your notification preferences")
            setSuccessSnackBar(true)
            setOpenSnackBar(!openSnackBar)
            setAutoHideDuration(3000)
        }).catch((response) => {
            setSnackBarContentMessage(`${response}`)
            setSuccessSnackBar(false)
            setAutoHideDuration(6000)
            setOpenSnackBar(!openSnackBar)
        });
    }
    const snackBarClose = () => {
        setOpenSnackBar(!openSnackBar)
    }
    return (
        <Card>
            <CardContent>
                <Loading />
                <Grid container>
                    <Grid container className={classes.container} direction="column">
                        <Grid container className={classes.container} item xs={12}>
                            <Header className={classes.header}>Notificaton Preferences</Header>
                        </Grid>
                        <Grid container className={classes.container} item xs={12}>
                            <Typography className={classes.infoText} variant="subtitle1">
                                - You can choose to recieve email or text notifications of each notification type.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            Result Preferences
                        </Typography>
                    </Grid>
                    <Grid container direction="column">
                        <PreferenceSelection onEmailPreferenceChange={preferences.setPassResultEmail} onTextPreferenceChange={preferences.setPassResultText} emailValue={preferences.passResultEmail!} textValue={preferences.passResultText!} label={preferences.PassResultLabel} />
                        <PreferenceSelection onEmailPreferenceChange={preferences.setFailResultEmail} onTextPreferenceChange={preferences.setFailResultText} emailValue={preferences.failResultEmail!} textValue={preferences.failResultText!} label={preferences.FailResultLabel} />
                    </Grid>
                    <Grid>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            Member Update Preferences
                        </Typography>
                    </Grid>
                    <Grid container direction="column">
                        <PreferenceSelection onEmailPreferenceChange={preferences.setMemberDeleteAccountEmail} onTextPreferenceChange={preferences.setMemberDeleteAccountText} emailValue={preferences.memberDeleteAccountEmail!} textValue={preferences.memberDeleteAccountText!} label={preferences.MemberDeleteAccountLabel} />
                        <PreferenceSelection onEmailPreferenceChange={preferences.setMemberUpdateAllowableSubstancesEmail} onTextPreferenceChange={preferences.setMemberUpdateAllowableSubstancesText} emailValue={preferences.memberUpdateAllowableSubstancesEmail!} textValue={preferences.memberUpdateAllowableSubstancesText!} label={preferences.MemberUpdateAllowableSubstancesLabel} />
                    </Grid>
                    <Grid className={classes.buttonContainer} container xs={12}>
                        <Button onClick={savePreferences} className={classes.MainButton}>Save Default Preferences</Button>
                    </Grid>
                    <Grid>
                        <Typography className={classes.sectionHeader} variant="subtitle1">
                            Member Specific Preferences
                        </Typography>
                    </Grid>
                    <Grid container>
                        <MemberPreferenceListComponent />
                    </Grid>
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        open={openSnackBar}
                        autoHideDuration={autoHideDuration}
                        onClose={() => snackBarClose()}
                        key={"topright"}
                    >
                        <SnackbarContent style={{ backgroundColor: successSnackBar ? "#2DAF79" : "red" }}
                            message={<span >{snackBarContentMessage}</span>}
                        />
                    </Snackbar >
                </Grid>
            </CardContent>
        </Card>
    )
}
export default observer(NotificationPreferencePage)