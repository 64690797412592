import React, { createContext, useContext } from "react";
import User from "../Models/User";

import { IClearMindAdminContext } from "./IClearMindAdminContext";

const user = User.create({ id: "", isLoading: false, role: "VideoReviewer", facilityCode: "", firstName: "", lastName: "", });
const createContextDependencies = (): IClearMindAdminContext => {

    return {
        user,
    };
};

export const contextDependencies = createContextDependencies();

export const ClearMindAdminContextProvider = createContext(contextDependencies);

export const useClearMindAdminContext = (): IClearMindAdminContext => {
    return useContext(ClearMindAdminContextProvider);
};

export const ClearMindAdminContext: React.FC = (props) => {

    return (
        <ClearMindAdminContextProvider.Provider value={contextDependencies}>
            {props.children}
        </ClearMindAdminContextProvider.Provider>
    );
};
