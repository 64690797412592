import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CheckCircle from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

const MemberDetailGrid: React.FC = (props: any) => {
  const { record } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingBottom: "2rem",
      },
      paper: {
        padding: theme.spacing(2),
        backgroundColor: "#2196F3",
        borderRadius: "10px",
        color: "#fff",
        textAlign: "left",
        minHeight: 100
      },
      span: {
        fontWeight: 500,
      },
      image: {
        width: 70,
        maxHeight: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      imageContainer: {
        textAlign: "center",
      },
      subHeader: {
        fontSize: "18px",
      },
    })
  );
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={1}>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="h6" component="h6">
            Member ID:
          </Typography>
          <Typography variant="body2" component="p">
            {record.memberId}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Typography variant="h6" component="h6">
            Date Test Taken
          </Typography>
          <Typography variant="body2" component="p">
            {new Date(record.dateTestTaken).toLocaleString()}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>
                  Supporter Notification Consent
                </Typography>
              </Grid>
              <Grid item>
                {record.notificationConsentAgreement ? <CheckCircle /> : <RadioButtonUncheckedIcon />}
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>
                  Best Practices Protocol Agreement
                </Typography>
              </Grid>
              <Grid item>
                {record.wifiConfirmationAgreement ? <CheckCircle /> : <RadioButtonUncheckedIcon />}
              </Grid>
            </Grid>
            <Grid container item spacing={3}>
              <Grid item>
                <Typography>
                  Device & Materials Confirmation
                </Typography>
              </Grid>
              <Grid item>
                {record.requiredMaterialsAgreement ? <CheckCircle /> : <RadioButtonUncheckedIcon />}
              </Grid>
            </Grid>
          </Grid>
        </Paper >
      </Grid >
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" component="h6">
            Allowable Substances/Herbal Supplements
          </Typography>
          <Typography variant="body2" component="p">
            {record.acceptedSubstances || "None"}
          </Typography>
        </Paper>
      </Grid>
    </Grid >
  );
};
export default MemberDetailGrid;
