import { ITestPanelSnapshotIn } from "../TestPanel";

export const salivaConfirm12TestSideAPanels: ITestPanelSnapshotIn[] = [
    {
        substanceName: "Fentanyl",
        headerBackgroundColor: "#20191b",
        headerLabel: "FEN",
    },
    {
        substanceName: "Methadone",
        headerBackgroundColor: "#e8912d",
        headerLabel: "MTD",
    },
    {
        substanceName: "Oxycodone",
        headerBackgroundColor: "#ff97f8",
        headerLabel: "OXY",
    },
    {
        substanceName: "Marijuana",
        headerBackgroundColor: "#002255",
        headerLabel: "THC",
    },
    {
        substanceName: "Buprenorphine",
        headerBackgroundColor: "#762c19",
        headerLabel: "BUP",
    },
];

export const salivaConfirm12TestSideBPanels = [
    {
        substanceName: "Methamphetamines",
        headerBackgroundColor: "#00be42",
        headerLabel: "MET",
    },
    {
        substanceName: "Cocaine",
        headerBackgroundColor: "#ed8207",
        headerLabel: "COC",
    },
    {
        substanceName: "Opiates",
        headerBackgroundColor: "#6a016c",
        headerLabel: "OPI",
    },
    {
        substanceName: "Benzos",
        headerBackgroundColor: "#cc8a12",
        headerLabel: "BZO",
    },
    {
        substanceName: "Amphetamine",
        headerBackgroundColor: "#3bc6ff",
        headerLabel: "AMP",
    },
    {
        substanceName: "Barbiturates",
        headerBackgroundColor: "#ffdc34",
        headerLabel: "BAR",
    },
];