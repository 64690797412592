import { useState, useEffect } from "react";
import { GetBusinessPartners, GetTreatmentPartners } from "../Services/PartnerService";
import { AutocompleteInput } from "react-admin";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

interface PartnerAutocompleteProps {
    partnerType: "business" | "treatment",
    label?: string;
    fullWidth: boolean
}

export const PartnerAutocomplete: React.FC<PartnerAutocompleteProps> = (props) => {
    const { partnerType, label, fullWidth } = props;
    const [options, setOptions] = useState<object[]>([]);
    const { promiseInProgress } = usePromiseTracker({ area: "partners" });

    useEffect(() => {
        const partnersLoad = partnerType === "business" ? GetBusinessPartners() : GetTreatmentPartners();

        trackPromise(partnersLoad.then((partners) => {
            const options = partners?.map(partner => ({ id: partner.facilityCode, name: partner.facilityName })) ?? [];

            setOptions(options)
        }), "partners");
    }, [partnerType])

    return <AutocompleteInput source="facilityCode" choices={options} label={label} fullWidth={fullWidth} loading={promiseInProgress} />
}