import React from "react";
import {
    TextField,
    EmailField,
    SimpleShowLayout,
    Show,
    SelectField,
    useRecordContext,
    BooleanField
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import LogoUpload from "./LogoUpload";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";

const MonitoringPartnerShow: React.FC = (props: any) => {
    return (
        <>
            <Show {...props}>
                <SimpleShowLayout>
                    <LogoUpload />
                    <Fields />
                </SimpleShowLayout>
            </Show>
        </>
    );
};


export default MonitoringPartnerShow;

const Fields = () => {
    const testWindowLengthChoices = [{ id: 9, name: "9" }, { id: 8, name: "8" }, { id: 6, name: "6" }, { id: 4, name: "4", }, { id: 2, name: "2" }]
    const { user } = useClearMindAdminContext();
    const record = useRecordContext();

    const isBusiness = record?.partnerType === "business";

    const getLabelPrefix = () => {
        return isBusiness ? "Business" : "Facility"
    }

    return <Grid container spacing={2}>
        <Grid item>
            <SimpleShowLayout>
                <TextField source="facilityName" label={`${getLabelPrefix()} Name`} />
                <TextField source="facilityCode" label={`${getLabelPrefix()} Code`} />
            </SimpleShowLayout>
        </Grid>
        <Grid item>
            <SimpleShowLayout>
                <EmailField source="facilityAddress" label={`${getLabelPrefix()} Address`} />
                <TextField source="facilityPhoneNumber" label={`${getLabelPrefix()} Phone Number`} />
            </SimpleShowLayout>
        </Grid>
        <Grid item>
            <SimpleShowLayout>
                <TextField source="contactName" label="Contact Name" />
                <TextField source="contactEmail" label="Contact Email" />
            </SimpleShowLayout>
        </Grid>
        <Grid item>
            <SimpleShowLayout>
                {user.isSuperAdmin &&
                    <TextField source="partnerType" />
                }
                <SelectField source="testWindowLength" choices={testWindowLengthChoices} defaultValue={8} label="Test Window Length in Hours" />
            </SimpleShowLayout>
        </Grid>
        <Grid item>
            <SimpleShowLayout>
                <BooleanField source="mfaRequired" label="MFA Required" />
            </SimpleShowLayout>
        </Grid>
        <Grid>
            <SimpleShowLayout>
                <TextField source="governmentIdRequired" label={"Government Id Required For Members"} />
            </SimpleShowLayout >
        </Grid >
    </Grid >;
}
