import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useState } from "react";
import { Confirm, useCreate, useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress"

interface DeleteScheduledTestButtonProps {
    userTestingHour: any
    refresh: () => void;
}

const DeleteScheduledTestButton: React.FC<DeleteScheduledTestButtonProps> = (props) => {
    const { userTestingHour, refresh } = props;
    const [deleteOne, { loading }] = useCreate();  // I know I know
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const onDeleteClick = () => {
        setOpen(true);
    }

    const handleConfirm = () => {
        const data = {
            testDateUtc: userTestingHour.testingDateUtc,
            userId: userTestingHour.memberId,
            testHourUtc: userTestingHour.testingHourUtc
        }

        const options = {
            onSuccess: () => {
                notify(`Test deleted for ${userTestingHour.memberName}.`);
                refresh();
            },
            onError: () => {
                notify(`Failed to delete test deleted for ${userTestingHour.memberName}.`, { type: "error" });
            }
        }
        setOpen(false);
        deleteOne("delete-scheduled-test", data, options)
    }

    return <>
        <IconButton style={{ color: "white" }} size="small" onClick={onDeleteClick} disabled={loading}>
            {loading ? <CircularProgress size={18} style={{ color: "white" }} /> : <DeleteForeverIcon />}
        </IconButton>
        <Confirm
            isOpen={open}
            loading={false} // or manage a loading state if needed
            title="Are you sure?"
            content={`You are about to delete this test for ${userTestingHour.memberName}. Please schedule another test for this member if necessary to ensure they meet their testing requirements.`}
            onConfirm={handleConfirm}
            onClose={() => setOpen(false)}
            confirm="Yes"
            cancel="No"
        />
    </>
}

export default DeleteScheduledTestButton;