import { TextInput, SimpleForm, required, DateInput, SelectInput, Edit, NumberInput, useRecordContext, maxLength } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import stateOptions from "./StateList";
import InputList from "../../CustomFields/InputList";
import { phoneNumberFormatter, validateNumber } from "./MemberGrid";
import languageOptions from "./LanguageList";
import { caseNumberFormatter } from "./PartnerMemberCreate";

export const PartnerMemberEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <Fields />
            </SimpleForm>
        </Edit>
    );
};

const Fields = () => {
    const record = useRecordContext();
    const { user } = useClearMindAdminContext();

    return <>
        <TextInput source="email" fullWidth validate={[required()]} />
        <TextInput source="firstName" fullWidth validate={[required()]} />
        <TextInput source="lastName" fullWidth validate={[required()]} />
        <InputList
            infoSpanText="If needed, you can add or update case numbers for your member"
            arraySource={"caseNumberList"}
            arrayLabel="Case Numbers"
            inputSource={"caseNumber"}
            inputFormat={caseNumberFormatter}
            addButtonText="Add new case number"
            inputValidator={[maxLength(40)]}
        /> 
        <SelectInput source="language" fullWidth validate={[required()]} choices={languageOptions} />
        <TextInput source="phoneNumber" format={phoneNumberFormatter} onBlur={(e) => { validateNumber(e.target.value); }} fullWidth validate={[required()]} />
        <DateInput source="birthdate" fullWidth validate={[required()]} />
        <TextInput source="streetAddress" fullWidth validate={[required()]} />
        <TextInput source="city" fullWidth validate={[required()]} />
        <SelectInput source="state" fullWidth validate={[required()]} choices={stateOptions} />
        <TextInput source="zipCode" fullWidth validate={[required()]} />
        {user.isBusinessPartnerAdmin && <NumberInput source="testsProvided" fullWidth validate={[required()]} label="Number of Tests Provided" />}
        <SelectInput
            source="active"
            fullWidth
            validate={[required()]}
            choices={[
                { id: "true", name: "True" },
                { id: "false", name: "False" },
            ]} />
        {
            record?.onboardingComplete && <>
                <InputList
                    infoSpanText="You can add or make changes to your members ALLOWABLE MEDICATIONS below"
                    list={record?.medicationList}
                    arraySource={"medicationList"}
                    arrayLabel="Allowable Medications"
                    inputSource={"substance"}
                    addButtonText="Add Substance" />
                <InputList
                    infoSpanText="You can add or make changes to your members ALLOWABLE HERBAL SUPPLEMENTS below"
                    list={record?.supplementList}
                    arraySource={"supplementList"}
                    arrayLabel="Allowable Supplements"
                    inputSource={"substance"}
                    addButtonText="Add Substance" />
            </>
        }
        {
            user.isSuperAdmin && <InputList
                list={record?.facilityCode}
                arraySource={"facilityCode"}
                arrayLabel="Monitoring Partners"
                inputSource={""}
                addButtonText="Monitoring Partners" />
        }</>
}
