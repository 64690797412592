import "semantic-ui-css/semantic.min.css"
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Auth } from "aws-amplify";
import AppWithAuth from "./AppWithAuth";
import authStorage from "./AuthenticationStorage";
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import "@aws-amplify/ui-react/styles.css";

const config = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    storage: authStorage,
  },
};

Auth.configure(config);
// Sentry.init({
//   dsn: 'https://c613c21c5f74487fa3fd97ca100cf299@o1057568.ingest.sentry.io/6044494',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: .5,
//   environment: process.env.NODE_ENV
// });

ReactDOM.render(
  <React.StrictMode>
    <AppWithAuth />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
