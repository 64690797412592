import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MemberDetailGrid from "../Results/MemberDetailGrid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import ProctorSessionTab from "./ProctorSessionTab";
import ProctorVideoTab from "./ProctorVideoTab";
import { TestResult } from "../../Models/TestResult";
import TestStep, { ITestStep } from "../../Models/TestStep";
import getVideoUrl from "../../Services/VideoUrlRetriever";

const ProctorReview: React.FC = (props: any) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [sessionTabIndex, setSessionTabIndex] = useState(0);
  const [seekToSeconds, setSeekToSeconds] = useState(0);
  const [testResults] = useState(TestResult.create());
  const [stepModels, setStepModels] = useState<ITestStep[]>([]);
  const { steps } = props.record;

  useEffect(() => {
    if (!steps) {
      return;
    }
    const models = steps.map((step: any) => TestStep.create({ ...step, stepState: "before" })) as ITestStep[];

    const getStartTimeForIndex = (index: number) => {
      if (!models || models.length <= 0) {
        return 0;
      }
      if (index >= 7) {
        return 0;
      }
      return (models[index].timestamp - models[0].timestamp) / 1000;
    }

    const getStopTimeForIndex = (index: number) => {
      if (!models || models.length <= 0) {
        return 0;
      }

      if (index >= 7) {
        return 0;
      }
      return (models[index + 1].timestamp - models[0].timestamp) / 1000;
    }

    models.forEach((step, i) => {
      step.setStartTimeInSeconds(getStartTimeForIndex(i));
      step.setStopTimeInSeconds(getStopTimeForIndex(i));
    });

    setStepModels(models);
  }, [steps]);


  useEffect(() => {
    const fetchVideo = async () => {
      const url = await getVideoUrl(props.record.memberId, props.record.videoName);
      setVideoUrl(url);
    }

    fetchVideo();
  }, [props.record]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        paddingBottom: "2rem",
        padding: theme.spacing(3),
      },
      imageStyle: { maxWidth: 340, margin: "auto", display: "flex" },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },

      videoPaper: {
        padding: theme.spacing(2),
        backgroundColor: "#fafafa",
        borderRadius: "20px",
        textAlign: "center",
        position: "sticky",
        top: 0
      },
      checklistPaper: {
        padding: theme.spacing(3),
        backgroundColor: "#fafafa",
        borderRadius: "20px",
        color: "#fff",
        textAlign: "left",
      },
      testCubePaper: {
        padding: theme.spacing(3),
        backgroundColor: "#fafafa",
        borderRadius: "20px",
      },
      span: {
        fontWeight: 500,
      },
      image: {
        width: 70,
        maxHeight: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      },
      imageContainer: {
        textAlign: "center",
      },
      subHeader: {
        fontSize: "18px",
      },
    })
  );
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.root} container spacing={1}>
        <Grid item xs={12}>
          <MemberDetailGrid {...props} />
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.videoPaper}>
            <ProctorVideoTab
              {...props}
              sessionTabIndex={sessionTabIndex}
              videoUrl={videoUrl}
              steps={stepModels}
              testCompleteTimestamp={props.record.testCompleteTimestamp}
              seekToSeconds={seekToSeconds}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.videoPaper}>
            <ProctorSessionTab
              {...props}
              testResult={testResults}
              setSessionTabIndex={setSessionTabIndex}
              steps={stepModels}
              setSeekToSeconds={setSeekToSeconds}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default observer(ProctorReview);
