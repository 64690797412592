import axios from "axios";
import * as Sentry from "@sentry/react";

export const GetMembers = async (facilityCode: string) => {
  try {
    const path = !!facilityCode ? `/members?facilityCode=${facilityCode}` : "/members";
    const response = await axios.get<any[]>(path);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return undefined;
    } else {
      Sentry.captureException(error);
    }
    throw error;
  }
};