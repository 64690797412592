import axios from "axios";

export const getMember = async (memberId: string) => {
    try {
        const response = await axios.get(
            `/members/${memberId}`
        );
        if (response.status > 299) throw response;
        const data = await response.data;

        return data;
    } catch (e: any) {
        console.log(e);
    }
};

export const getMembersForFacilityCode = async (facilityCode: string)=>{
    try {
        const response = await axios.get(
            `/members?facilityCode=${facilityCode}`
        );
        if (response.status > 299) throw response;
        const data = await response.data;

        return data;
    } catch (e: any) {
        console.log(e);
    }
}