import {
    List,
    Datagrid,
    TextField,
    EmailField,
    required,
    email,
    SelectField,
    BooleanField,
} from "react-admin";
export const validateEmail = [email(), required()];

export const testWindowLengthChoices = [{ id: 9, name: "9" },{ id: 8, name: "8" }, { id: 6, name: "6" }, { id: 4, name: "4", }, { id: 2, name: "2" }]

export const MonitoringPartnerList = (props: any) => {
    const isBusiness = props.filter.partnerType === "business";

    const getLabelPrefix = () => {
        return isBusiness ? "Business" : "Facility"
    }

    return <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="facilityName" label={`${getLabelPrefix()} Name`} />
            <TextField source="facilityCode" label={`${getLabelPrefix()} Code`} />
            <TextField source="facilityAddress" label={`${getLabelPrefix()} Address`} />
            <TextField source="facilityPhoneNumber" label={`${getLabelPrefix()} Phone Number`} />
            <TextField source="contactName" />
            <EmailField source="contactEmail" />
            <SelectField source="testWindowLength" choices={testWindowLengthChoices} defaultValue={8} label="Test Window Length in Hours" />
            <BooleanField source="mfaRequired" label="MFA Required" />
        </Datagrid>
    </List >
};