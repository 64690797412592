import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField
} from "react-admin";


export const ProctorUserList = (props: any) => (
    <List {...props} filter={{ role: "Proctor" }} pagination={false}>
        <Datagrid rowClick="show">
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <TextField source="role" />
            <TextField source="facilityCode" />
            <BooleanField source="active" />
        </Datagrid>
    </List>
);
