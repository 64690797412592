import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { useRecordContext } from "react-admin";

const MAX_IMAGE_SIZE = 800; // Maximum image size (width or height) in pixels
const ACCEPTED_FILE_TYPES = ["image/jpeg", "image/png"]; // Accepted file types

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        img: {
            width: 200,
            height: 100
        },
        buttonContainer: {
            display: "flex",
        },
        saveButton: {
            marginRight: "8px"
        },
        accordionHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: "black"
        },
    })
);

const LogoUpload: React.FC = () => {
    const partner = useRecordContext();
    const [base64Image, setBase64Image] = useState<string | undefined>(undefined);
    const [expandAccordion, setExpandAccordion] = useState(false);
    const [successSnackBar, setSuccessSnackBar] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [autoHideDuration, setAutoHideDuration] = useState(1500)
    const [snackBarContentMessage, setSnackBarContentMessage] = useState("")
    const classes = useStyles();
    useEffect(() => {
        if (partner && partner.facilityLogo) {
            setBase64Image(partner.facilityLogo)
        }
    }, [partner])
    const toggleSnackBar = () => {
        setOpenSnackBar(!openSnackBar)
    }
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return
        }
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            if (img.width > MAX_IMAGE_SIZE || img.height > MAX_IMAGE_SIZE) {
                alert(`Image dimensions exceed ${MAX_IMAGE_SIZE}x${MAX_IMAGE_SIZE} pixels.`);
            } else if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
                alert("Invalid file type. Please upload a JPEG or PNG image.");
            } else {
                // Read the file as a data URL (base64)
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result && typeof reader.result === "string") {
                        setBase64Image(reader.result);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const handleRemoveImage = () => {
        setBase64Image(undefined);
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.value = "";
    };
    const handleSaveImage = async () => {
        if (base64Image) {
            partner.facilityLogo = base64Image
            trackPromise(axios.put(`/monitoring-partners/${partner.id}`, partner).then(() => {
                setSnackBarContentMessage(`You have successfully updated your Member Result Report logo`)
                setSuccessSnackBar(true)
                setOpenSnackBar(!openSnackBar)
                setAutoHideDuration(3000)
                setTimeout(() => setExpandAccordion(!expandAccordion), 3000)
            }).catch((response) => {
                setSnackBarContentMessage(`${response}`)
                setSuccessSnackBar(false)
                setAutoHideDuration(6000)
                setOpenSnackBar(!openSnackBar)
            }));
        }
    };
    return (
        <Accordion expanded={expandAccordion}>
            <AccordionSummary
                onClick={() => setExpandAccordion(!expandAccordion)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.accordionHeading}>Update / Upload Logo</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Logo Upload</Typography>
                    </Grid>
                    {base64Image === undefined && (
                        <Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="image/jpeg,image/png"
                                    type="file"
                                    id="image-upload-input"
                                    onChange={handleImageUpload}
                                    style={{ display: "none" }}
                                />
                            </Grid>
                            <label htmlFor="image-upload-input">
                                <Button variant="contained" color="secondary" component="span">
                                    Upload Image
                                </Button>
                            </label>
                        </Grid>
                    )}
                    {base64Image && (
                        <>
                            <Grid item xs={12}>
                                <img className={classes.img} src={base64Image || ""} alt="Selected" />
                            </Grid>
                            <Grid className={classes.buttonContainer} item xs={10}>
                                <Grid item>
                                    <Button className={classes.saveButton} variant="contained" disabled={partner.facilityLogo === base64Image} color="secondary" onClick={handleSaveImage}>
                                        Save Image
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={handleRemoveImage}>
                                        Remove Image
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={openSnackBar}
                    autoHideDuration={autoHideDuration}
                    onClose={() => toggleSnackBar()}
                    key={"topright"}
                >
                    <SnackbarContent style={{ backgroundColor: successSnackBar ? "#2DAF79" : "red" }}
                        message={<span >{snackBarContentMessage}</span>}
                    />
                </Snackbar >
            </AccordionDetails>
        </Accordion>

    );
};

export default LogoUpload;
