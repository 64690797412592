import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import MemberPreferenceDropdown from "./MemberPreferenceDropdown";
import { INotificationPreferenceModel } from "../../Models/NotificationPreferenceModel";
import { Button } from "semantic-ui-react";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        MainButton: {
            marginTop: "16px !important",
            backgroundColor: "#2196f3 !important",
            color: "#FFF !important",
            "&:hover": {
                backgroundColor: "#66a7dc !important",
            }
        },
        dropdownRoot: {
            "& .MuiTextField-root": {
                margin: theme.spacing(1),
                width: "25ch",
            },
        },
        heading: {
            color: "black"
        },
    })
);
interface memberPreferenceAccordianProps {
    memberPreference: INotificationPreferenceModel
    memberId: string,
    partnerId: string
}
const MemberPreferenceAccordion: React.FC<memberPreferenceAccordianProps> = (props) => {
    const { memberPreference, memberId, partnerId } = props;
    const [successSnackBar, setSuccessSnackBar] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false)
    const [autoHideDuration, setAutoHideDuration] = useState(1500)
    const [snackBarContentMessage, setSnackBarContentMessage] = useState("")
    const classes = useStyles();
    const savePreferences = async () => {
        await
            trackPromise(memberPreference.updateMemberPreferences(partnerId)).then(() => {
                setSnackBarContentMessage(`You have successfully updated individual preferences for your member`)
                setSuccessSnackBar(true)
                setOpenSnackBar(!openSnackBar)
                setAutoHideDuration(3000)
            }).catch((response) => {
                setSnackBarContentMessage(`${response}`)
                setSuccessSnackBar(false)
                setAutoHideDuration(6000)
                setOpenSnackBar(!openSnackBar)
            });
    }
    const snackBarClose = () => {
        setOpenSnackBar(!openSnackBar)
    }
    return (
        <Grid className={classes.root}>
            <Accordion
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <Typography className={classes.heading}>
                        Configure individual Preference
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className={classes.dropdownRoot}>
                        <Grid container>
                            <MemberPreferenceDropdown preferenceField={"passResult"} label={"Receive Pass Result Alerts"} memberPreference={memberPreference} memberId={memberId} partnerId={partnerId} />
                            <MemberPreferenceDropdown preferenceField={"failResult"} label={"Receive Fail Result Alerts"} memberPreference={memberPreference} memberId={memberId} partnerId={partnerId} />
                        </Grid>
                        <Grid container>
                            <MemberPreferenceDropdown preferenceField={"memberDeleteAccount"} label={"Receive Account Deletion Alerts"} memberPreference={memberPreference} memberId={memberId} partnerId={partnerId} />
                            <MemberPreferenceDropdown preferenceField={"memberUpdateAllowableSubstances"} label={"Receive Allowable Substance Alerts"} memberPreference={memberPreference} memberId={memberId} partnerId={partnerId} />
                        </Grid>
                        <Grid container>
                            <Button onClick={savePreferences} className={classes.MainButton}>Save Member Preference</Button>
                        </Grid>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        open={openSnackBar}
                        autoHideDuration={autoHideDuration}
                        onClose={() => snackBarClose()}
                        key={"topright"}
                    >
                        <SnackbarContent style={{ backgroundColor: successSnackBar ? "#2DAF79" : "red" }}
                            message={<span >{snackBarContentMessage}</span>}
                        />
                    </Snackbar >

                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}

export default MemberPreferenceAccordion