import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { useNotify, useRecordContext, useRefresh, useUpdate } from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            backgroundColor: "#2196f3",
            marginTop: "12px",
            marginRight: "12px",
            color: "#FFF",
            "&:hover": {
                backgroundColor: "#66a7dc",
            },
            width: 255
        },
    })
)
const AddTestsModal: React.FC = (props) => {
    const [open, setOpen] = useState(false);
    const [numberToAdd, setNumberToAdd] = useState(0);
    const record = useRecordContext();
    const [update, { loading }] = useUpdate();
    const notify = useNotify();
    const refresh = useRefresh();
    const styles = useStyles();

    const onAddClick = () => {
        const newQuantity = record.testsProvided + numberToAdd;

        const options = {
            onSuccess: () => {
                notify(`${numberToAdd} Tests Added`);
                refresh();
                setOpen(false);
            },
            onError: () => notify("Failed to add tests.", "error")
        }
        update("members", record.id, { ...record, testsProvided: newQuantity }, undefined, options);
    }

    return <>
        <Button onClick={() => setOpen(true)} className={styles.button} >
            Add Tests
        </Button>
        <Dialog
            open={open}
        >
            <DialogTitle>Add Tests</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the number of tests to add:
                </DialogContentText>
                <TextField
                    inputProps={{ min: 0 }}
                    autoFocus
                    margin="dense"
                    type="number"
                    fullWidth
                    value={numberToAdd}
                    onChange={(e) => setNumberToAdd(+e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={onAddClick} disabled={loading} >
                    {loading ? <CircularProgress size={21} /> : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default AddTestsModal
