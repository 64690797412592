import { List, Datagrid, TextField, TextInput, DateField, BooleanField, FunctionField, BooleanInput } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import { useState } from "react";

export const phoneNumberFormatter = (value: string) => {
  if (!value) {
    return;
  }
  let newNum = value.replace(/\D/g, "");
  return newNum.substring(0, 10);
};
export const validateNumber = (value: any) => {
  const compare = /^[0-9]{10}$/g;
  if (!value.match(compare)) {
    alert("Phone Number must be in 1234567890 format");
  }
};

export const MemberGrid = (props: any) => {
  const { user } = useClearMindAdminContext();
  const [isInActive, setInactive] = useState(false);

  const filters = [<BooleanInput defaultValue={isInActive} onChange={() => setInactive(!isInActive)} label="View Inactive Members" source="showInactive" />, <TextInput label="Facility Code Filter" source="facilityCode" />];
  return (
    <>
      <List {...props} filter={{ showInactive: isInActive, facilityCode: user.facilityCode ? user.facilityCode : "" }} pagination={false} filters={filters}>
        <Datagrid rowClick="show">
          {user.isAdmin && <TextField source="id" />}
          <TextField source="firstName" label="First Name" />
          <TextField source="lastName" label="Last Name" />
          <FunctionField label="Facility Code" render={(record: any) => record?.facilityCode.join(", ")} />
          <TextField source="language" label="Preferred Language" />
          <DateField source="onboardingDate" label="Onboarding Date" />
          <DateField source="testingStartDate" label="Testing Start Date" />
          <DateField source="dateLastTestTaken" label="Last Test Date" />
          <TextField source="lastTestResult" label="Last Test Result" />
          <TextField source="caseNumber" label="Case Numbers" />
          <BooleanField source="active" label="Active" />
        </Datagrid>
      </List>
    </>
  );
};