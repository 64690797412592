import axios from "axios";

const getVideoUrl = async (memberId: string, videoName: string) => {
    try {
        const response = await axios.get(
            `/test-session-video-download-url?memberId=${memberId}&videoName=${videoName}`
        );
        if (response.status > 299) throw response;
        const data = await response.data;

        return data;
    } catch (e: any) {
        console.log(e);
    }
}

export default getVideoUrl;