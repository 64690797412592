import { uuid4 } from "@sentry/utils";
import {
    SimpleForm,
    TextInput,
    Create,
    required, useNotify,
    useRedirect, SelectInput, BooleanInput
} from "react-admin";
import { validateEmail, testWindowLengthChoices } from "./MonitoringPartnerList";
import { GetSqid } from "../../Services/SqidGenerator";

export const PartnerCreate = (props: any) => {
    const { initialValues } = props;
    const isBusiness = initialValues?.partnerType === "business";

    const redirect = useRedirect();
    const notify = useNotify();

    const onFailure = (error: any) => {
        notify(error.body.errors[0].message, {
            type: "error",
            messageArgs: { smart_count: 1 },
        });
    };

    const onSuccess = () => {
        if (initialValues?.partnerType === "business") {
            redirect("list", "/business-monitoring-partners");
        } else {
            redirect("list", "/monitoring-partners");
        }
    };

    const transform = (data: any) => {
        const transformed = {
            ...data,
            id: uuid4(),
        };

        if (isBusiness) {
            transformed.facilityCode = GetSqid();
        }
        return transformed;
    };

    const getLabelPrefix = () => {
        return isBusiness ? "Business" : "Facility"
    }

    return (
        <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform}>
            <SimpleForm initialValues={initialValues}>
                <TextInput source="facilityName" label={`${getLabelPrefix()} Name`} fullWidth validate={[required()]} />
                {isBusiness || <TextInput source="facilityCode" label="Facility Code" fullWidth validate={[required()]} />}
                <TextInput source="facilityAddress" label={`${getLabelPrefix()} Address`} fullWidth validate={[required()]} />
                <TextInput source="facilityPhoneNumber" label={`${getLabelPrefix()} Phone Number`} fullWidth validate={[required()]} />
                <TextInput source="contactName" fullWidth validate={[required()]} />
                <TextInput source="contactEmail" fullWidth validate={validateEmail} />
                <BooleanInput source="mfaRequired" label="MFA Required" fullWidth />
                <SelectInput source="testWindowLength" fullWidth choices={testWindowLengthChoices} defaultValue={8} label="Test Window Length in Hours" />
            </SimpleForm>
        </Create>
    );
};
