import { Datagrid, EmailField, List, SelectField, TextField } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import { testWindowLengthChoices } from "./MonitoringPartnerList";

export const SingleMonitoringPartner = (props: any) => {
    const { user } = useClearMindAdminContext();
    return (
        <List {...props} filter={{ facilityCode: user.facilityCode }}>
            <Datagrid rowClick="show">
                <TextField source="facilityName" />
                <TextField source="facilityCode" />
                <TextField source="facilityAddress" />
                <TextField source="facilityPhoneNumber" />
                <TextField source="contactName" />
                <EmailField source="contactEmail" />
                <SelectField source="testWindowLength" choices={testWindowLengthChoices} defaultValue={8} label="Test Window Length in Hours" />
            </Datagrid>
        </List>
    )
};