export var Container = {
    fontFamily: "\"Comfortaa\", sans-serif",
    fontWeight: "400",
    width: "350px",
    margin: "auto",
    display: "flex",
    flexDirection: "column-reverse",
};
export var FormContainer = {};
export var FormSection = {
    position: "relative",
    marginBottom: "20px",
    textAlign: "left",
    display: "inline-block",
    minWidth: "350px",
    borderRadius: "6px",
};
export var FormField = {
    marginBottom: "22px",
};
export var SectionHeader = {
    color: "#1C3A6A",
    marginBottom: "30px",
    fontSize: "18px",
    fontWeight: "500",
    display: "none",
};
export var Toast = {
    padding: "15px",
    fontWeight: "600",
    color: "#fff",
    backgroundColor: "#aa2016",
    borderRadius: "4px",
    marginBottom: "10px",
};
export var SectionBody = {
    marginBottom: "30px",
};
export var SectionFooter = {
    fontSize: "14px",
    color: "white",
};
export var SectionFooterPrimaryContent = {
    marginLeft: "auto",
};
export var SectionFooterSecondaryContent = {
    marginRight: "auto",
    alignSelf: "center",
};
export var Input = {
    display: "block",
    width: "100%",
    padding: "16px",
    fontSize: "14px",
    fontFamily: "Comfortaa",
    color: "#152939",
    backgroundColor: "#fff",
    backgroundImage: "none",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    boxSizing: "border-box",
    marginBottom: "10px",
};
export var Button = {
  minWidth: "153px",
  display: "block",
  marginBottom: "7px",
  lineHeight: "1.42857143",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "middle",
  touchAction: "manipulation",
  cursor: "pointer",
  userSelect: "none",
  backgroundImage: "linear-gradient(to right,#3bcbbd,#3b91cb)",
  color: "#fff",
  border: "none",
  padding: "14px 0",
  letterSpacing: "1.1px",
  borderRadius: "4px",
  width: "100%",
  fontFamily: "Comfortaa",
  fontWeight: 550,
  textTransform: "uppercase",
};
export var SignInButton = {
  position: "relative",
  width: "100%",
  borderRadius: "9px",
  marginBottom: "10px",
  cursor: "pointer",
  padding: 0,
  fontFamily: "Comfortaa",
  color: "#fff",
  fontSize: "14px",
};
export var SignInButtonIcon = {
  position: "absolute",
  left: 0,
  "#google_signin_btn_icon": {
    backgroundColor: "#fff",
    borderRadius: "4px 0 0 4px",
    height: "30px",
    width: "30px",
    padding: "11px",
  },
  "#facebook_signin_btn_icon": {
    height: "33px",
    width: "18px",
    padding: "10px 14px",
  },
  "#amazon_signin_btn_icon": {
    padding: "10px",
    height: "32px",
    width: "32px",
  },
};
export var SignInButtonContent = {
  display: "block",
  padding: "18px 0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "center",
};
export var Strike = {
  width: "100%",
  textAlign: "center",
  borderBottom: "1px solid #bbb",
  lineHeight: "0.1em",
  margin: "32px 0",
  color: "#828282",
};
export var StrikeContent = {
  background: "#fff",
  padding: "0 25px",
  fontSize: "14px",
  fontWeight: "500",
};
export var ActionRow = {
  marginBottom: "15px",
};
export var FormRow = {
  marginBottom: "12px",
};
export var A = {
  color: "#04DDFF",
  cursor: "pointer",
};
export var Hint = {
  color: "white",
  fontSize: "11px",
  textAlign: "right",
};
export var Radio = {
  marginRight: "18px",
  verticalAlign: "bottom",
};
export var InputLabel = {
  color: "white",
  fontSize: "14px",
  marginBottom: "8px",
};
var Bootstrap = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,
  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,
  input: Input,
  button: Button,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,
  hint: Hint,
  radio: Radio,
  inputLabel: InputLabel,
  toast: Toast,
};
export default Bootstrap;

