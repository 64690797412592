import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

interface AlcoholTestImageProps {
  imageSideA?: string;
  imageSideB?: string;
  alcoholSideA?: string;
  alcoholSideB?: string;
}
const TestImageDisplay: React.FC<AlcoholTestImageProps> = (props) => {
  const { imageSideA, imageSideB, alcoholSideA, alcoholSideB } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      imageStyle: {
        maxWidth: 900,
        margin: "auto",
        display: "flex",
        width: "100%",
        borderRadius: "0 0 10px 10px",
        transform: "scaleX(-1)"
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
      imageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "left",
      },
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        justifyContent: "space-around",
      },
      imageHeader: {
        padding: theme.spacing(2),
        backgroundColor: "#2196F3",
        borderRadius: "10px 10px 0 0",
        color: "#fff",
        textAlign: "left",
        minHeight: 10,
      },
      text: {
        color: "black",
      },
    })
  );
  const classes = useStyles();

  const capturedImage =
    imageSideA || imageSideB || alcoholSideA || alcoholSideB;

  const getImageText = () => {
    if (imageSideA) {
      return "Test Side A";
    }
    if (imageSideB) {
      return "Test Side B";
    }
    if (alcoholSideA) {
      return "Alcohol Side A";
    }
    if (alcoholSideB) {
      return "Alcohol Side B";
    }
    return undefined;
  };

  const getImageUrl = () => {
    if (imageSideA) {
      return imageSideA;
    }
    if (imageSideB) {
      return imageSideB;
    }
    if (alcoholSideA) {
      return alcoholSideA;
    }
    if (alcoholSideB) {
      return alcoholSideB;
    }
    return undefined;
  };

  return (
    <>
      <Grid className={classes.container} item xs={12}>
        {capturedImage ? (
          <Box className={classes.imageContainer}>
            <Paper className={classes.imageHeader}>
              <Typography variant="h6" component="h6">
                {getImageText()}
              </Typography>
            </Paper>
            <img
              className={classes.imageStyle}
              src={getImageUrl()}
              alt={getImageText()}
            />
          </Box>
        ) : (
          <Box className={classes.imageContainer}>
            <Typography className={classes.text} variant="h6" component="h6">
              No Image captured.
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default TestImageDisplay;
