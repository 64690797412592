import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Modal from "@material-ui/core/Modal";
import { IUser } from "../../Models/User";

interface IFacilityCodeAlertProps {
  user: IUser;
}
const NoFacilityCodeAlert: React.FC<IFacilityCodeAlertProps> = (props: any) => {
  const { user } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      facilityCodeMessage: {
        padding: "30px 25px",
        textAlign: "center",
        outline: "none",
      },
      emailLink: {
        color: "#f6c30a",
        fontWeight: "bold",
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      icon: {
        fontSize: "75px",
        color: "#3DAAC0",
      },
    })
  );

  const classes = useStyles();

  return (
    <Modal open={!user.hasFacilityCode && user.requiresFacilityCode} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" className={classes.modal}>
      <Paper elevation={0} className={classes.facilityCodeMessage}>
        <ContactSupportIcon className={classes.icon} />
        <br /> <br />
        <Typography variant="h5" color="textPrimary" align="center">
          You must complete your account setup as a Monitoring Partner to access the Admin Portal.
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center">
          Please contact clearMINDnow Support at 888-242-4120 or at{" "}
          <a className={classes.emailLink} href="mailto:support@clearmindnow.com">
            support@clearmindnow.com
          </a>
          <br /> <br />
          <Typography variant="h4" color="textSecondary">
            Thanks!
          </Typography>
        </Typography>
      </Paper>
    </Modal>
  );
};
export default NoFacilityCodeAlert;
