import React, { useState } from "react";
import { TextField, EmailField, SimpleShowLayout, BooleanField, FunctionField } from "react-admin";
import MemberPastTestResults from "./MemberPastTestResults";
import { TabbedShowLayout, Tab } from "react-admin";
import MemberSupporterList from "./MemberSupporterList";
import Grid from "@material-ui/core/Grid";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import MemberFacilityPartner from "./MemberFacilityPartner";
import ChangeActiveStatus from "./ChangeActiveStatus";
import ActiveStatusModal from "./ActiveStatusModal";
import FacilityCodeShowField from "../../CustomFields/FacilityCodeShowField";
import TotalTestsTakenContext from "./TotalTestsTakenContext";
import AddTestsModal from "./AddTestsModal";
import ForcePasswordChangeModal from "./ForcePasswordChangeModal";
import MemberEventsDisplay from "./MemberEventsDisplay";

const MemberShow: React.FC = (props: any) => {
  const { user } = useClearMindAdminContext();
  const [totalTestsTaken, setTotalTestsTaken] = useState(-1);

  const isBusinessPartnerType = props.record.partnerType === "business";
  const isLoading = !props.record.partnerType && props.record.partnerType !== "";
  const memberNumberOfTests = props.record.numberOfTests;

  const isAuthorizedUser = () => {
    return user.isSupportAdmin || user.isPartnerAdmin || user.isSuperAdmin;
  };

  const isAuthorizedAdmin = () => {
    return user.isSuperAdmin || user.isPartnerAdmin;
  };

  const setActiveStatus = () => {
    return !props.record.active ? (
      <ChangeActiveStatus
        style={{
          color: "#FFF",
          width: 255,
          marginTop: 12,
          marginRight: 12,
          backgroundColor: "#2196f3",
        }}
        baseUrl={process.env.REACT_APP_CLEARMIND_BACKEND_URL!}
      />
    ) : (
      <ActiveStatusModal />
    );
  };
  const getPackageName = (numberOfTests: number) => {
    switch (numberOfTests) {
      case 9:
        return "Accountability Plus(9 tests per month)";
      case 7:
        return "Maintenance Package(7 tests per quarter)";
      case 5:
        return "Accountability Program(5 tests per month)";
      case -1:
        return "On Demand Testing(Manually scheduled testing only)";
      default:
        return "No package found for this member";
    }
  };

  const getButtons = () => {
    if (isLoading) {
      return null;
    }

    return (
      <>
        {memberNumberOfTests === -1 && <AddTestsModal />}
        {isAuthorizedUser() && <ForcePasswordChangeModal baseUrl={process.env.REACT_APP_CLEARMIND_BACKEND_URL} {...props} />}
        {isAuthorizedAdmin() && setActiveStatus()}
      </>
    );
  };

  const getTestingPackageOrTestsProvided = () => {
    if (isLoading) {
      return null;
    }
    return memberNumberOfTests === -1 ? (
      <FunctionField label="Tests Remaining" render={(record: any) => record.testsProvided - totalTestsTaken} />
    ) : (
      <FunctionField label="Testing Package" render={(record: any) => getPackageName(record.numberOfTests)} />
    );
  };

  return (
    <TotalTestsTakenContext.Provider value={{ totalTestsTaken, setTotalTestsTaken }}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="fullName" label="Name" />
              <TextField source="phoneNumber" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <FunctionField
                {...props}
                label={"Address"}
                render={(record: any) => (
                  <>
                    {record.streetAddress}
                    <br />
                    {record.city}, {record.zipCode}
                  </>
                )}
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <EmailField source="email" />
              <TextField source="allowableSubstances" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <BooleanField source="active" />
              <FacilityCodeShowField />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              {getTestingPackageOrTestsProvided()}
              <TextField source="language" label="Preferred Language" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>{getButtons()}</Grid>
        </Grid>
        <TabbedShowLayout>
          <Tab label="Test Results" children={<MemberPastTestResults {...props} />}></Tab>
          <Tab label="Supporters" children={<MemberSupporterList {...props} />}></Tab>
          {isBusinessPartnerType && (
            <Tab
              label="Facility Partner"
              children={props.record.facilityCode.map((facilityCode: string, index: any) => (
                <MemberFacilityPartner key={index} {...props} facilityCode={facilityCode} />
              ))}
            ></Tab>
          )}
          <Tab label="Events" children={<MemberEventsDisplay id={props.id} visible={props.record.eventsVisibleToMonitoringPartner} />}></Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </TotalTestsTakenContext.Provider>
  );
};

export default MemberShow;
