import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import VideoPlayer from "../Video/VideoPlayer";
import TestImageDisplay from "./TestImageDisplay";
import { Record } from "react-admin";
import { ITestStep } from "../../Models/TestStep";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabBar: {
    backgroundColor: "#4395CA",
  },
  tabs: {
    fontWeight: 800,
  },
  videoPaper: {
    padding: theme.spacing(2),
    backgroundColor: "#fafafa",
    borderRadius: "20px",
    textAlign: "left",
    position: "sticky",
    top: 0
  },
}));

interface ProctorVideoTabProps {
  sessionTabIndex: number;
  videoUrl: string;
  steps: ITestStep[];
  testCompleteTimestamp: number;
  seekToSeconds: number;
  record: Record;
}

const ProctorVideoTab: React.FC<ProctorVideoTabProps> = (props) => {
  const { sessionTabIndex, videoUrl, steps, testCompleteTimestamp, seekToSeconds } = props;
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  useEffect(() => {
    setActiveTabIndex(sessionTabIndex);
  }, [sessionTabIndex]);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs className={classes.tabBar} value={activeTabIndex} onChange={handleChange}>
          <Tab className={classes.tabs} label="Proctor Video" id="tab-0" />
          <Tab className={classes.tabs} label="Image Side A" id="tab-1" />
          <Tab className={classes.tabs} label="Alcohol Side A" id="tab-3" />
          <Tab className={classes.tabs} label="Image Side B" id="tab-2" />
          <Tab className={classes.tabs} label="Alcohol Side B" id="tab-4" />
        </Tabs>
      </AppBar>
      <TabPanel value={activeTabIndex} index={0}>
        <VideoPlayer
          url={videoUrl}
          steps={steps}
          testCompleteTimestamp={testCompleteTimestamp}
          seekToSeconds={seekToSeconds}
          memberId={props.record.memberId}
        />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <TestImageDisplay imageSideA={props.record.testSideAPhoto} />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={2}>
        <TestImageDisplay alcoholSideA={props.record.alcoholSideAPhoto} />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={3}>
        <TestImageDisplay imageSideB={props.record.testSideBPhoto} />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={4}>
        <TestImageDisplay alcoholSideB={props.record.alcoholSideBPhoto} />
      </TabPanel>
    </div>
  );
}
export default ProctorVideoTab
