import axios from "axios";

export const getMemberNotificaionPreferences = async (partnerId: string, memberId: string) => {
    try {
        const response = await axios({
            url: `/member-notification-preferences?monitoringPartnerUserId=${partnerId}&memberId=${memberId}`,
            baseURL: process.env.REACT_APP_API_BASE_URL,
        })
        
        const data = await response.data;

        return data;
    } catch (e: any) {
        console.log(e);
    }
};

export const getAllNotificaionPreferences = async (partnerId: string) => {
    try {
        const response = await axios({
            url: `/monitoring-partner-notification-preferences?monitoringPartnerUserId=${partnerId}`,
            baseURL: process.env.REACT_APP_API_BASE_URL,
        })
        if (response.status > 299) throw response;
        const data = await response.data;

        return data;
    } catch (e: any) {
        console.log(e);
    }
};