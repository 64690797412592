import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProctorCheckList from "./ProctorCheckList";
import TestCubeSideGrid from "../Results/TestCubeSideGrid";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import { observer } from "mobx-react";
import { ITestResult } from "../../Models/TestResult";
import { TabPanel } from "./TabPanel";
import { useRedirect, useNotify } from "react-admin";
import { trackPromise } from "react-promise-tracker";
import { ITestStep } from "../../Models/TestStep";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import { AxiosError } from "axios";

const theme = createTheme({
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: "#fff",
      },
    },
    MuiTab: {
      root: {
        padding: "6px 40px",
        "&:hover": {
          backgroundColor: "#3EC0BE",
          color: "#FFF",
        },
      },
    },
  },
});

interface ProctorSessionProps {
  record?: any;
  setSessionTabIndex: React.Dispatch<React.SetStateAction<number>>;
  testResult: ITestResult
  steps: ITestStep[];
  setSeekToSeconds: (videoTimeInSeconds: number) => void;
}

const ProctorSessionTab: React.FC<ProctorSessionProps> = (props) => {
  const { record, setSessionTabIndex, testResult, steps, setSeekToSeconds } = props;
  const { user } = useClearMindAdminContext();
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "20px",
    },
    tabBar: {
      backgroundColor: "#4395CA",
    },
    tabs: {
      fontWeight: 800,
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: "#2DAF79",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#3EC0BE",
        color: "#FFF",
      },
    }
  }));

  const classes = useStyles();
  useEffect(() => {
    const loadProctorResults = async () => {
      await trackPromise(testResult.load(record.id))
    }
    loadProctorResults()
  }, [record.id, testResult])


  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const notify = useNotify();

  const handleTabeIndexChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabIndex(newValue);
  };

  const redirect = useRedirect();


  const handleSaveClick = async () => {
    await trackPromise(testResult.saveResults(record.id));
    redirect("/test-sessions");
  };

  const handleSubmitClick = async () => {
    await trackPromise(testResult.submitResults(record.id, user.id)).catch(
      (error: AxiosError) => {
        if (error.response?.status === 400) {
          notify("Test session has already been submitted and cannot be updated.", { type: "warning" })
        } else {
          notify(`${error}`, { type: "error" })
        }
      });
    redirect("/test-sessions");
  };


  useEffect(() => {
    setSessionTabIndex(activeTabIndex)
  }, [setSessionTabIndex, activeTabIndex]);

  const onChangeTestCubeTypeOverride = (testCubeTypeOverride: string | undefined) => {
    testResult.setTestCubeTypeOverride(testCubeTypeOverride);
    testResult.resetResults(testResult, testCubeTypeOverride)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            className={classes.tabBar}
            value={activeTabIndex}
            onChange={handleTabeIndexChange}
          >
            <Tab className={classes.tabs} label="Proctor Questions" id="tab-0" />
            <Tab className={classes.tabs} label="Test Side A" id="tab-1" disabled={!testResult.allProctorQuestionsAnswered} />
            <Tab className={classes.tabs} label="Test Side B" id="tab-2" disabled={!testResult.isSideARecorded} />
          </Tabs>
        </AppBar>
        <TabPanel value={activeTabIndex} index={0}>
          <ProctorCheckList
            setActiveTabIndex={setActiveTabIndex}
            record={record}
            currentTabIndex={activeTabIndex}
            testResult={testResult}
            steps={steps}
            setSeekToSeconds={setSeekToSeconds}
          />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <TestCubeSideGrid
            whichSide="A"
            setActiveTabIndex={setActiveTabIndex}
            tabIndex={activeTabIndex}
            hasPhoto={record.testSideAPhoto}
            testResult={testResult}
            onChangeTestCubeTypeOverride={onChangeTestCubeTypeOverride}
          />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          <TestCubeSideGrid
            whichSide="B"
            setActiveTabIndex={setActiveTabIndex}
            tabIndex={activeTabIndex}
            hasPhoto={record.testSideBPhoto}
            testResult={testResult}
            onChangeTestCubeTypeOverride={onChangeTestCubeTypeOverride}
          />
        </TabPanel>
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<SaveAltIcon />}
            onClick={() => handleSaveClick()}
          >
            Save Results
          </Button>

          {testResult.allPanelResultsRecordedAndAllQuestionsAnswered && user.isProctorAdminOrSuperUserOrSuperAdminOrProctor && (
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              startIcon={<PublishIcon />}
              disabled={!testResult.allPanelResultsRecordedAndAllQuestionsAnswered}
              onClick={() => handleSubmitClick()}
            >
              Submit Results
            </Button>
          )}
        </Grid>
      </div>
    </MuiThemeProvider>
  );
};
export default observer(ProctorSessionTab);