import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-admin";
import DeleteScheduledTestButton from "./DeleteScheduledTestButton";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";

interface ITestSchedule {
  userTestingDays: { memberName: string; testingHourUtc: string, memberId: string, testingHourLocal: number, testingDateUtc: string }[];
  date: string;
  refresh: () => void;
}

const DailyTestSchedule: React.FC<ITestSchedule> = (props) => {
  const { userTestingDays, date, refresh } = props;
  const { user } = useClearMindAdminContext();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      name: {
        color: "white",
        fontSize: "16px",
        "&:hover": {
          color: "rgba(0, 0, 0, .54)"
        }
      },
      cardContent: {
        display: "flex",
        backgroundColor: "#4bc0d9",
        width: "100%",
        padding: "5px",
        marginBottom: "5px",
        flexDirection: "column",
        justifyContent: "left",
      },
      nameHeader: {
        color: "#ffff",
      },
      time: {
        fontSize: "12px",
        fontWeight: 500,
      },

      dateContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      dateHeader: {
        fontSize: "18px",
        fontWeight: 800,
        marginBottom: "-4px",
      },
      weekDay: {
        fontSize: "13px",
        fontWeight: 500,
      },
    })
  );

  const classes = useStyles();

  const getLocalTime = (date: string, localHour: number) => {
    const workingDate = new Date(date);
    workingDate.setHours(+localHour);

    const getStandard = ((localHour + 11) % 12) + 1;
    const amPm = localHour >= 12 ? "PM" : "AM";

    return `${getStandard} ${amPm}`;
  };

  const userTestingHours = userTestingDays.reduce(
    (dictonary: any, userTestingDay: any) => {
      if (!dictonary[userTestingDay.testingHourLocal]) {
        dictonary[userTestingDay.testingHourLocal] = [];
      }
      dictonary[userTestingDay.testingHourLocal].push(userTestingDay);
      return dictonary;
    },
    {}
  );

  const userTestingHoursKeys = Object.keys(userTestingHours).sort((a, b) => +a - +b);

  return (
    <>
      {userTestingHoursKeys.map((key) => (
        <Card className={classes.cardContent} key={key} style={{ width: 200 }}>
          <Typography
            className={classes.time}
            variant="h6"
            color="textSecondary"
          >
            {getLocalTime(date, +key)}
          </Typography>
          {userTestingHours[key].map((userTestingHour: any) => (
            <div key={`${userTestingHour.testingHourUtc}-${userTestingHour.memberId}`} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.name} style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: 0,
                flex: 1
              }}>
                <Link to={`/members/${userTestingHour.memberId}/show`} className={classes.name}>{userTestingHour.memberName}</Link>
              </Typography>
              {(user.isAdmin || user.isPartnerAdmin) && <DeleteScheduledTestButton userTestingHour={userTestingHour} refresh={refresh} />}
            </div>
          ))}
        </Card>
      ))}
    </>
  );
};

export default DailyTestSchedule;
