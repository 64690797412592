
import { types, Instance } from "mobx-state-tree";

const TestStep = types.model("TestStep", {
    index: types.number,
    timestamp: types.number,
    text: types.string,
    stepState: types.enumeration(["before", "during", "after"]),
    startTimeInSeconds: types.optional(types.number, 0),
    stopTimeInSeconds: types.optional(types.number, 0),
}).actions((self) => ({
    setStepState(currentPlaybackSeconds: number) {
        if (currentPlaybackSeconds < self.startTimeInSeconds || !self.stopTimeInSeconds) {
            self.stepState = "before";
        } else if (currentPlaybackSeconds > self.stopTimeInSeconds) {
            self.stepState = "after";
        } else {
            self.stepState = "during";
        }
    },
    setStartTimeInSeconds(seconds: number) {
        self.startTimeInSeconds = seconds;
    },
    setStopTimeInSeconds(seconds: number) {
        self.stopTimeInSeconds = seconds;
    }
}))

export default TestStep;

export interface ITestStep extends Instance<typeof TestStep> { }

