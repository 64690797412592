
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import Grid from "@material-ui/core/Grid";
import Rewind from "@material-ui/icons/Replay5";
import FastFoward from "@material-ui/icons/Forward5";
import Play from "@material-ui/icons/PlayArrow";
import Pause from "@material-ui/icons/Pause";
import Button from "@material-ui/core/Button";
import "react-step-progress-bar/styles.css";
import VideoProgressBar from "./VideoProgressBar";
import { Placeholder, PlaceholderImage } from "semantic-ui-react";
import { ITestStep } from "../../Models/TestStep";
import { Dimmer, Loader } from "semantic-ui-react";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import completeVideoUpload from "../../Services/CompleteVideoUpload";
import { useNotify } from "react-admin";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: "#2196f3",
      marginTop: "12px",
      marginRight: "12px",
      marginBottom: "10px",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#66a7dc",
      },
    },
  })
)
interface VideoPlayerProps {
  url: string;
  steps: ITestStep[];
  testCompleteTimestamp: number;
  seekToSeconds: number
  memberId: string;
}
const videoNotFoundError = "No video uploads found. The video may not exist.";


const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const { user } = useClearMindAdminContext();
  const [progress, setProgress] = useState<any>();
  const [playing, setPlaying] = useState(false);
  const [videoFound, setVideoFound] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentPlaybackSeconds, setCurrentPlaybackSeconds] = useState(0);
  const [duration, setDuration] = useState<number>(0);
  const playerRef = useRef<any>();
  const { url, steps, testCompleteTimestamp, seekToSeconds } = props;
  const [videoReady, setVideoReady] = useState(false);
  const [videoDataUrl, setVideoDataUrl] = useState("");
  const [videoSize, setVideoSize] = useState(640);
  const styles = useStyles();
  const notify = useNotify();

  useEffect(() => {
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];

      step.setStepState(currentPlaybackSeconds);
    };

  }, [currentPlaybackSeconds, steps])

  useEffect(() => {
    fetch(url)
      .then((response) => {
        if(!response.ok){
          setVideoFound(false)
          return Promise.reject("HTTP error");
        }
        return response.blob();
      })
      .then(blob => {
        const dataUrl = window.URL.createObjectURL(blob);
        setVideoDataUrl(dataUrl);
      }).catch((response)=>{
         setVideoFound(false)
      }
      );
  }, [url])

  useEffect(() => {
    if (steps && steps.length > 0) {
      setDuration((testCompleteTimestamp - steps[0].timestamp) / 1000);
    }
  }, [testCompleteTimestamp, steps])

  useEffect(() => {
    if (!seekToSeconds) {
      return;
    }

    playerRef.current?.seekTo(seekToSeconds);
  }, [seekToSeconds])

  const padNumber = (numberToPad: number) => {
    return numberToPad < 10
      ? `0 ${numberToPad.toString()}`
      : numberToPad.toString();
  };

  const onStepClickHandler = (stepTimeMark: number) => {
    playerRef.current?.seekTo(stepTimeMark);
  };

  const formatSeconds = (secondsToFormat: number) => {
    secondsToFormat = Number(secondsToFormat);
    const hours = Math.floor(secondsToFormat / 3600);
    const minutes = Math.floor((secondsToFormat % 3600) / 60);
    const seconds = Math.floor((secondsToFormat % 3600) % 60);

    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  };

  const onProgress = (progress: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    setProgress(progress);
    setCurrentPlaybackSeconds(Math.floor(progress.playedSeconds));
  };

  const onReady = (e: any) => {
    setVideoReady(true);
  }

  const increaseSize = () => {
    if (videoSize < 1280) {
      setVideoSize(videoSize + 25)
    }
  }

  const decreaseSize = () => {
    if (videoSize > 320) {
      setVideoSize(videoSize - 25)
    }
  }
  const onCompleteVideoUpload = async ()=>{
    await completeVideoUpload(props.memberId).then(
      (response)=>{
        if (response.data === "Test Session Upload Completed"){
          notify(response.data, "success")
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        }
        if (response.data === videoNotFoundError){
          notify(videoNotFoundError, "info");
        }
      }
    )
  }

  return (
    <>

      <Dimmer.Dimmable blurring dimmed={!videoReady} style={{ padding: 5 }}>
        {videoFound && <Dimmer active={!videoReady} inverted>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item style={{ color: "black", marginBottom:"12px" }}>
              Please wait while the video downloads.
            </Grid>
            <Grid item style={{marginTop:"48px"}}>
              <Loader />
            </Grid>
          </Grid>
        </Dimmer>}
        {!videoFound && <Dimmer active={!videoReady} inverted>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item style={{ color: "black" }}>
              Test session video could not be found.
              
            </Grid>
            <Grid item style={{ marginTop: "8px" }}>
              <CancelPresentationIcon style={{ color: "Black"}} />
            </Grid>
            {user.isSuperAdmin && 
            <Grid>
                <Button onClick={() => onCompleteVideoUpload()} className={styles.button}>
                  Try Finish Test Upload
                </Button>
              </Grid>}
          </Grid>
        </Dimmer>}
        <div>
          {!videoReady && <Placeholder fluid>
            <PlaceholderImage rectangular></PlaceholderImage>
          </Placeholder>
          }

          <div style={{ color: "black" }}>
            Video Size
          </div>
          <Button style={{ width: 105, margin: 5 }} variant="contained" color="default" onClick={increaseSize}>Bigger</Button>
          <Button style={{ width: 105, margin: 5 }} variant="contained" color="default" onClick={decreaseSize}>Smaller</Button>

          <ReactPlayer
            onProgress={onProgress}
            progressInterval={1}
            url={videoDataUrl}
            playbackRate={playbackRate}
            playing={playing}
            ref={playerRef}
            width="100%"
            height={videoSize}
            onReady={onReady}
            style={{
              position: videoReady ? "unset" : "absolute",
              visibility: videoReady ? "visible" : "hidden"
            }}
          />
          <VideoProgressBar
            playedSeconds={progress?.playedSeconds}
            steps={steps}
            duration={duration}
            onStepClick={onStepClickHandler}
          />
        </div>

        <br />
        <br />
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item>
              <Button
                onClick={() => setPlaying(true)}
                color={playing ? "secondary" : "default"}
                variant="contained"
              >
                <Play />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setPlaying(false)}
                color={!playing ? "secondary" : "default"}
                variant="contained"
              >
                <Pause />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                  playerRef.current?.seekTo(+progress.playedSeconds - 5)
                }
                variant="contained"
                color="default"
              >
                <Rewind />
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() =>
                  playerRef.current?.seekTo(+progress.playedSeconds + 5)
                }
                variant="contained"
                color="default"
              >
                <FastFoward />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid style={{ color: "#333" }} container spacing={4}>
            <Grid item>
              <h4>Current Playback Time</h4>
              {formatSeconds(progress?.playedSeconds)}
            </Grid>
            <Grid item>
              <h4>Playback Rate</h4>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => setPlaybackRate(1)}
                    color={playbackRate === 1 ? "secondary" : "default"}
                    variant="contained"
                  >
                    1x
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setPlaybackRate(1.5)}
                    color={playbackRate === 1.5 ? "secondary" : "default"}
                    variant="contained"
                  >
                    1.5x
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setPlaybackRate(2)}
                    color={playbackRate === 2 ? "secondary" : "default"}
                    variant="contained"
                  >
                    2x
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dimmer.Dimmable>
    </>
  );
};

export default VideoPlayer;
