import React from "react";
import { useSpring, animated } from "react-spring";
const LogoFadeInContainer: React.FC = (props) => {
    const animateStyle = useSpring({
        config: { duration: 1500 },
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
    });
    return (
        <>
            <animated.div style={animateStyle}>{props.children}</animated.div>
        </>
    );
};

export default LogoFadeInContainer;