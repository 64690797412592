
import { types, Instance, SnapshotIn } from "mobx-state-tree";

const TestPanel = types.model("Panel", {
    headerBackgroundColor: types.maybe(types.string),
    headerLabel: types.maybe(types.string),
    substanceName: types.maybe(types.string),
    controlLine: types.maybe(types.boolean),
    testLine: types.maybe(types.boolean),
    invalidChecked: types.maybe(types.boolean),
    passByAllowableSubstance: types.maybe(types.boolean),
})
    .views((self) => ({
        get isRecorded() {
            return self.controlLine || self.testLine || self.invalidChecked;
        }
    }))
    .actions((self) => ({
        setControlLineChecked: (value: boolean) => {
            self.controlLine = value
            self.invalidChecked = false;
        },
        setTestLineChecked: (value: boolean) => {
            self.testLine = value
            self.invalidChecked = false;
        },
        setInvalid: (value: boolean) => {
            self.invalidChecked = value
        },
        setPassByAllowableSubstance: (value: boolean) => {
            self.passByAllowableSubstance = value
        },
    }))


export default TestPanel;

export interface ITestPanel extends Instance<typeof TestPanel> { };
export interface ITestPanelSnapshotIn
    extends SnapshotIn<typeof TestPanel> { };

