import axios from "axios";
import * as Sentry from "@sentry/react";

export interface FacilityPartnerResponse {
  monitoringPartnerId: string;
  facilityName: string;
  facilityCode: string;
  facilityAddress: string;
  facilityPhoneNumber: string;
  contactName: string;
  contactEmail: string;
}

export const GetPartners = async () => {
  return GetPartnersWithType("");
};

export const GetTreatmentPartners = async () => {
  return GetPartnersWithType("treatment");
};

export const GetBusinessPartners = async () => {
  return GetPartnersWithType("business");
};

const GetPartnersWithType = async (partnerType: string) => {
  try {
    const path = !!partnerType ? `/monitoring-partners?partnerType=${partnerType}` : "/monitoring-partners"
    const response = await axios.get<FacilityPartnerResponse[]>(path);
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return undefined;
    } else {
      Sentry.captureException(error);
    }
    throw error;
  }
}
