
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  Show,
  DateField,
  BooleanField,
  SimpleShowLayout,
  Labeled,
  Edit,
  SimpleForm,
  SelectInput,
  useRedirect,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import VideoPlayer from "../Video/VideoPlayer";
import React, { useEffect, useState } from "react";
import getVideoUrl from "../../Services/VideoUrlRetriever";
import TestStep, { ITestStep } from "../../Models/TestStep";
import InputList from "../../CustomFields/InputList";


export const ProctoredResultsList = (props: any) => (
  <List
    {...props}
    filter={{ reviewed: true, testCompleted: true }}
    pagination={false}
  >
    <Datagrid rowClick="show">
      <DateField source="dateTestTaken" label="Date of Test" showTime />
      <DateField source="resultVerificationDate" label="Date Test Proctored" showTime />
      <BooleanField source="reviewed" />
      <BooleanField source="testCompleted" label="Test Completed" />
      <BooleanField source="notificationConsentAgreement" label="Notification Consent" />
      <BooleanField source="wifiConfirmationAgreement" label="Test Protocol Agreement" />
      <BooleanField
        source="requiredMaterialsAgreement"
        label="Device\Materials Confirmation"
      />
      <TextField source="acceptedSubstances" label="Acceptable Substances" />
      <ReferenceField label="Proctored By" source="resultVerifiedBy" reference="admin-users">
        <FunctionField render={(record: any) => `${record.firstName} ${record.lastName}`} />
      </ReferenceField>
    </Datagrid>
  </List>
);

const VideoPlayerWrapper: React.FC<any> = (props) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [steps, setSteps] = useState<ITestStep[]>([]);

  const classes = makeStyles({
    videoContainer: {
      maxWidth: 325
    },
    text: {
      color: "black",
    },
  })();

  useEffect(() => {
    const fetchVideo = async () => {
      const url = await getVideoUrl(props.record.memberId, props.record.videoName);
      setVideoUrl(url);
    }
    const stepModels = props.record.steps?.map((step: any) => TestStep.create({ ...step, stepState: "before" })) as ITestStep[] || [];
    setSteps(stepModels);
    fetchVideo();
  }, [props.record]);

  if (props.record.testResult === "Decline") {
    return <div>No video for declined test.</div>
  }

  return <div className={classes.videoContainer}>
    <Labeled label="Test Video">
      <VideoPlayer
        url={videoUrl}
        steps={steps}
        testCompleteTimestamp={props.record.testCompleteTimestamp}
        memberId={props.record.memberId}
        seekToSeconds={0} />
    </Labeled>
  </div>;
}


export const ProctoredResultsListShow = (props: any) => {
  const classes = makeStyles({
    root: {
      overflow: "visible",
    },
    videoContainer: {
      maxWidth: 500
    }
  })();

  return (
    <Show
      {...props}
      classes={{
        card: classes.root,
      }}
    >
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="fullName" label="Member Name" />
              <DateField source="dateTestTaken" label="Test Date Taken" />
              <DateField
                source="resultVerificationDate"
                label="Test Date Proctored"
                showTime
              />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="testResult" label="Test Result" />
              <TextField
                source="substancesDetected"
                label="Substances Detected"
              />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <VideoPlayerWrapper />
      </SimpleShowLayout>
    </Show>
  );
};

export const ProctoredResultEdit = (props: any) => {
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect("show", "/proctored-test-sessions", `${props.id}`)
  }
  return (
    <>
      <Edit onSuccess={onSuccess} undoable={false} {...props}>
        <SimpleForm>
          <SelectInput
            source="testResult"
            choices={[
              { id: "Pass", name: "Pass" },
              { id: "Pass - Allowable Substance", name: "Pass - Allowable Substance" },
              { id: "Decline", name: "Decline" },
              { id: "Detection", name: "Detection" },
              { id: "Misstep", name: "Misstep" },
              { id: "Pass - Invalid Result", name: "Pass - Invalid Result" },
              { id: "Invalid Result", name: "Invalid Result" },
            ]}
            fullWidth
          />
          <InputList
            infoSpanText="If needed, you can add or make changes to your members detected substances below"
            arraySource={"substancesDetectedList"}
            arrayLabel="substances Detected"
            inputSource={"substance"}
            addButtonText="Add Substance"
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

