import { flow, types, Instance, applySnapshot } from "mobx-state-tree";
import axios from "axios";
const User = types
  .model("UserModel", {
    id: types.string,
    isLoading: types.optional(types.boolean, false),
    firstName: types.string,
    lastName: types.string,
    phoneNumber: types.maybeNull(types.string),
    role: types.enumeration([
      "Admin",
      "VideoReviewer",
      "SuperUser",
      "SuperAdmin",
      "SupportAdmin",
      "ProctorAdmin",
      "Proctor",
      "TreatmentPartnerAdmin",
      "TreatmentPartnerUser",
      "BusinessPartnerAdmin",
      "",
    ]),
    facilityCode: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isPartnerAdmin() {
      return (self as any).isBusinessPartnerAdmin || (self as any).isTreatmentPartnerAdmin
    },
    get isTreatmentPartnerAdmin() {
      return self.role === "TreatmentPartnerAdmin";
    },
    get isBusinessPartnerAdmin() {
      return self.role === "BusinessPartnerAdmin";
    },
    get isTreatmentPartnerUser() {
      return self.role === "TreatmentPartnerUser";
    },
    get hasFacilityCode() {
      return self.facilityCode !== null;
    },
    get hasPhoneNumber(){
      return self.phoneNumber !== null && self.phoneNumber !== "";
    },
    get isSuperUser() {
      return self.role === "SuperUser";
    },
    get isSupportAdmin() {
      return self.role === "SupportAdmin";
    },
    get isSuperAdmin() {
      return self.role === "SuperAdmin";
    },
    get isProctorAdmin() {
      return self.role === "ProctorAdmin";
    },
    get isProctorAdminOrSuperUserOrSuperAdminOrProctor() {
      const roles = ["Proctor", "ProctorAdmin", "SuperUser", "SuperAdmin"]
      return (
        roles.includes(self.role)
      );
    },
    get isProctor() {
      return self.role === "Proctor";
    },
    get isAdmin() {
      return (
        self.role === "SupportAdmin" ||
        self.role === "SuperAdmin" ||
        self.role === "SuperUser"
      );
    },
    get isPartner() {
      return (
        self.role === "TreatmentPartnerUser" ||
        self.role === "TreatmentPartnerAdmin" ||
        self.role === "BusinessPartnerAdmin"
      );
    },
    get isPartnerOrAdmin() {
      return (
        self.role === "SupportAdmin" ||
        self.role === "SuperAdmin" ||
        self.role === "SuperUser" ||
        self.role === "TreatmentPartnerUser" ||
        self.role === "TreatmentPartnerAdmin" ||
        self.role === "BusinessPartnerAdmin"
      );
    },
    get isAdminOrProctor() {
      return (
        self.role === "SupportAdmin" ||
        self.role === "SuperAdmin" ||
        self.role === "ProctorAdmin" ||
        self.role === "Proctor"
      );
    },
    get requiresFacilityCode() {
      return (
        self.role === "Admin" ||
        self.role === "SupportAdmin" ||
        self.role === "TreatmentPartnerAdmin" ||
        self.role === "TreatmentPartnerUser" ||
        self.role === "BusinessPartnerAdmin"
      );
    }
  }))
  .actions((self: any) => {
    const wrapLoadingDuringAction = flow(function* (serviceAction: any) {
      self.isLoading = true;
      try {
        const result = yield serviceAction();
        self.isLoading = false;
        return result;
      } catch (err) {
        console.error(err);
        self.isLoading = false;
        throw err;
      }
    });
    return {
      load: flow(function* () {
        yield wrapLoadingDuringAction(
          flow(function* () {
            const result = yield axios({
              url: "/current-user",
              baseURL: process.env.REACT_APP_API_BASE_URL,
            });
            applySnapshot(self, result.data ?? {});
          })
        );
      }),
      savePhoneNumber: flow(function* (phoneNumber:string){
        self.phoneNumber = phoneNumber;
        yield axios({
          url: `/admin-users/${self.id}`,
          baseURL: process.env.REACT_APP_API_BASE_URL,
          method: "put",
          data: self
        })
      })
    };
  });

export default User;
export interface IUser extends Instance<typeof User> { }