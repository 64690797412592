import { Create, SimpleForm, TextInput, required } from "react-admin"

export const ProctorUserCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="email" fullWidth validate={[required()]} />
                <TextInput source="firstName" fullWidth validate={[required()]} />
                <TextInput source="lastName" fullWidth validate={[required()]} />
                <TextInput source="role" fullWidth defaultValue={"Proctor"} disabled={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    )
};