import styles from "./ErrorStyle.module.css";
import { Header, Segment } from "semantic-ui-react";

const ErrorPage: React.FC = () => {

    return (
        <Segment textAlign={"center"} basic placeholder>
            <Header as="p">
                <div>
                    <h1 className={styles.oops}>Oops!</h1>
                </div>
                <br />
                Something went wrong.
            </Header>
            <div>
                <p style={{ color: "#fff" }}>
                    If you keep experiencing problems please email:{" "}
                    <a
                        href="mailto:support@withclearmind.com"
                        style={{ color: "white" }}
                    >
                        support@withclearmind.com
                    </a>
                </p>
            </div>
        </Segment>
    );
};

export default ErrorPage;

