import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { trackPromise } from "react-promise-tracker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getMembersForFacilityCode } from "../../Services/GetMember";
import MemberPreferenceAccordion from "./MemberPreferenceAccordion";
import { NotificationPreferenceModel } from "../../Models/NotificationPreferenceModel";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        maxHeight: 300, // Set the maximum height for scrollability
        overflow: "auto", // Enable scrolling
    },
    container: {
        marginBottom: "8px",
        borderBottom: "1px solid black"
    },
    bottomItem: {
        marginBottom: "8px",
    },
    topItem: {
        marginTop: "8px",
        marginRight: "8px"
    },
    checkbox: {
        marginRight: "8px"
    }
}));

const MemberPreferenceListComponent: React.FC = () => {
    const classes = useStyles();
    const [members, setMembers] = useState<[]>([]);
    const [memberPreferences, setMemberPreferences] = useState<any[]>([]);
    const { user } = useClearMindAdminContext();
    const [checkedMap, setCheckedMap] = useState<{ [memberId: string]: boolean }>({});

    const handleChange = (memberId: string) => {
        setCheckedMap((prevCheckedMap) => ({
            ...prevCheckedMap,
            [memberId]: !prevCheckedMap[memberId],
        }));
    };
    useEffect(() => {
        trackPromise(
            getMembersForFacilityCode(user.facilityCode!)
                .then((res: any) => {
                    setMembers(res);
                })
        );
    }, [user.facilityCode]);
    useEffect(() => {
        const allMemberPreferences: any[] = []
        members.forEach((member: any) => {
            const individualPreference = NotificationPreferenceModel.create()
            individualPreference.setMemberId(member.id)
            allMemberPreferences.push(individualPreference)
        });
        setMemberPreferences(allMemberPreferences)
    }, [members])

    return (
        <Grid className={classes.root}>
            <List>
                {members.map((member: any) => (
                    <Grid className={classes.container} container>
                        <Grid className={classes.topItem} item xs={3}>
                            <ListItem key={member.id} role={undefined}>
                                <Grid xs={7}>
                                    <ListItemText primary={member.fullName} />
                                </Grid>
                                <Grid className={classes.checkbox} xs={5}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!checkedMap[member.id]}
                                                onChange={() => handleChange(member.id)}
                                                color="primary"
                                            />
                                        }
                                        label="Add Individual Preference"
                                    />
                                </Grid>
                            </ListItem>
                        </Grid>
                        {checkedMap[member.id] &&
                            <Grid className={classes.bottomItem} item xs={12}>
                                <MemberPreferenceAccordion memberPreference={memberPreferences.find((preference) => preference.memberId === member.id)} memberId={member.id} partnerId={user.id} />
                            </Grid>}

                    </Grid>
                ))}
            </List>
        </Grid>
    );
};

export default MemberPreferenceListComponent;
