import { useState } from "react";
import { useRecordContext, useRefresh } from "react-admin";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import axios from "axios";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        MainButton: {
            backgroundColor: "#2196f3",
            color: "#FFF",
            "&:hover": {
                backgroundColor: "#66a7dc",
            }
        },
    }),
);
interface ChangeActiveStatusProps {
    record?: any
    id?: any
    style?: React.CSSProperties
    baseUrl: string
}
const ChangeActiveStatus: React.FC<ChangeActiveStatusProps> = (props: any) => {
    const { baseUrl, id, style } = props;
    const productUrl = process.env.REACT_APP_CLEARMIND_BACKEND_URL
    const adminUrl = process.env.REACT_APP_API_BASE_URL
    const [loader, setLoader] = useState(false)
    const classes = useStyles()
    const refresh = useRefresh();
    const record = useRecordContext(props);
    if (!record) return null;
    const activeButtonLabel = () => {
        return record.active ? "Change To Inactive" : "Change To Active"
    }
    const changeActiveStatus = () => {
        setLoader(true)
        const activeFlag = record.active ? "false" : "true"
        if (baseUrl === adminUrl) {
            axios({ url: `/admin-users/${id}`, baseURL: baseUrl, method: "PUT", data: { active: activeFlag } }).then()
                .then(() => {
                    setLoader(false);
                    refresh();
                }).catch((response) => {
                    setLoader(false);
                    console.error(response);
                });
        }
        if (baseUrl === productUrl) {
            axios({ url: `/users/${record.userId}`, baseURL: baseUrl, method: "PATCH", data: { active: activeFlag } }).then()
                .then(() => {
                    setLoader(false);
                    refresh();

                }).catch((response) => {
                    setLoader(false);
                    console.error(response);
                });
        }
    }
    return (
        <Button style={style} onClick={changeActiveStatus} className={classes.MainButton}>{loader ? "Loading..." : activeButtonLabel()}</Button>
    )
}

export default ChangeActiveStatus