import React, { useEffect, useState } from "react";
import {
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    Button,
} from "react-admin";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

interface InputListProps {
    list?: any
    maxNumberOfFields?: number
    infoSpanText?: string
    arrayLabel?: string
    arraySource: string
    inputLabel?: string
    inputSource: string
    inputValidator?: any
    addButtonText?: string
    inputFormat?: any
}

const InputList: React.FC<InputListProps> = (props) => {
    const {
        list,
        maxNumberOfFields,
        infoSpanText,
        arrayLabel,
        arraySource,
        inputLabel,
        inputSource,
        inputValidator,
        addButtonText,
        inputFormat
    } = props;
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            infoSpan: {
                color: "#4395CA",
                fontWeight: 600
            },
            infoIcon: {
                color: "#4395CA",
            },
            infoContainer: {
                flexFlow: "row",
                color: "#333"
            }
        }),
    );
    const classes = useStyles();
    const [numberOfFieldsUsed, setNumberOfFieldsUsed] = useState(0)
    const [disableAdd, setDisableAdd] = useState(false)
    useEffect(() => {
        if (list) {
            setNumberOfFieldsUsed(list.length)
        }
    }, [list])

    const onAdd = () => {
        setNumberOfFieldsUsed(numberOfFieldsUsed + 1)
    }
    const onRemove = () => {
        setNumberOfFieldsUsed(numberOfFieldsUsed - 1)
    }

    const removeSpaces = (facilityCode: string) => {
      if (facilityCode == null || facilityCode === "") {
        return facilityCode;
      }
      const formatValue = facilityCode.replace(/ /g, "");
      return formatValue;
    };
    
    const addButton = () => {
        return (
            <Button
                style={{
                    color: "#4395CA",
                    outline: "solid",
                }}
                onClick={() => onAdd()}
                startIcon={<AddCircleOutlineIcon />}
                label={addButtonText ? addButtonText : "Add"}
            />
        )
    }
    const RemoveButton = () => {
        return (
            <Button
                style={{
                    color: "#4395CA",
                    outline: "solid",
                }}
                onClick={() => onRemove()}
                startIcon={<RemoveCircleOutlineIcon />}
                label="Remove"
            />
        )
    }
    useEffect(() => {
        if (maxNumberOfFields) {
            numberOfFieldsUsed === maxNumberOfFields ?
                setDisableAdd(true)
                :
                setDisableAdd(false)
        }
    }, [numberOfFieldsUsed, maxNumberOfFields])

    return (
      <>
        {!!infoSpanText && (
          <Grid container className={classes.infoContainer} direction="row" spacing={3}>
            <Grid item>
              <InfoIcon className={classes.infoIcon} />
            </Grid>
            <Grid item>
              <span className={classes.infoSpan}>{infoSpanText}</span>
            </Grid>
          </Grid>
        )}
        <ArrayInput label={arrayLabel || ""} source={arraySource}>
          <SimpleFormIterator disableAdd={disableAdd} addButton={addButton()} removeButton={RemoveButton()}>
            <TextInput format={inputFormat || removeSpaces} label={inputLabel || ""} source={inputSource} validate={inputValidator} />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
};
export default InputList;