import { Toolbar, SaveButton, Edit, SimpleForm, TextInput, required, SelectInput, useRecordContext, BooleanInput } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import { validateEmail, testWindowLengthChoices } from "./MonitoringPartnerList";


export const PartnerEdit = (props: any) => {
    const { user } = useClearMindAdminContext();  

    const TreatmentPartnerAdminToolbar = (props: any) => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    return (
        <Edit {...props}>
            <SimpleForm toolbar={user.isPartnerAdmin ? <TreatmentPartnerAdminToolbar /> : undefined}>
                <Fields />
            </SimpleForm>
        </Edit>
    );
};
export const governmentIdRequiredChoices = [{ id: true, name: "Yes" }, { id: false, name: "No" }]

const Fields = () => {
    const record = useRecordContext();
    const { user } = useClearMindAdminContext();
    const isBusiness = record?.partnerType === "business";

    const getLabelPrefix = () => {
        return isBusiness ? "Business" : "Facility"
    }

    return <>
        <TextInput source="facilityName" label={`${getLabelPrefix()} Name`} fullWidth validate={[required()]} />
        <TextInput source="facilityAddress" label={`${getLabelPrefix()} Address`} fullWidth validate={[required()]} />
        <TextInput source="facilityCode" disabled label={`${getLabelPrefix()} Code`} fullWidth validate={[required()]} />
        <TextInput source="facilityPhoneNumber" label={`${getLabelPrefix()} Phone Number`} fullWidth validate={[required()]} />
        <TextInput source="contactName" fullWidth validate={[required()]} />
        <TextInput source="contactEmail" fullWidth validate={validateEmail} />
        <BooleanInput source="mfaRequired" label="MFA Required" fullWidth />
        <SelectInput source="testWindowLength" fullWidth choices={testWindowLengthChoices} defaultValue={8} label="Test Window Length in Hours" />
        {user.isAdmin && <SelectInput source="governmentIdRequired" fullWidth choices={governmentIdRequiredChoices} defaultValue={false} label="Government Id Required" />}
    </>
}