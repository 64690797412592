import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@material-ui/core";
import Loading from "../../Components/Loading/Loading";
import { trackPromise } from "react-promise-tracker";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify } from "react-admin";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";

const useStyles = makeStyles({
    input: {
        "& input[type=number]": {
            "-moz-appearance": "textfield"
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    },
    MainButton: {
        marginTop: "16px !important",
        backgroundColor: "#2196f3 !important",
        color: "#FFF !important",
        "&:hover": {
            backgroundColor: "#66a7dc !important",
        }
    },
});
const PhoneNumberModal: React.FC = () => {
    const { user } = useClearMindAdminContext();
    const classes = useStyles();
    const notify = useNotify();
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [open, setOpen] = useState<boolean>(user.hasPhoneNumber === false ? true : false)
    const onSave = async () => {
        if (phoneNumber.length !== 10) {
            notify("To save, enter in your 10-digit phone number, starting with the area code.");
        } else {
            trackPromise(user.savePhoneNumber(phoneNumber))
            onClose()
        }
    };
    const onCancel = () => {
        // Will use a default to show the user declined to give phone number
        const defaultNumber = "1234567890";
        user.savePhoneNumber(defaultNumber)
        onClose()
    }
    const onClose = () => {
        setOpen(false)
    }

    if (!user.isPartner) {
        return null;
    }

    return (

        <Dialog
            BackdropProps={{
                invisible: true, // Disable background interactions
            }}
            open={open}
            aria-labelledby="phone-number-modal">
            <Loading />
            <DialogTitle id="phone-number-modal">Enter Phone Number</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter your phone number below to recieve text notifications about your members:
                </DialogContentText>
                <TextField
                    className={classes.input}
                    autoFocus
                    margin="dense"
                    id="phone-number"
                    label="Phone Number"
                    type="number"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button className={classes.MainButton} onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button className={classes.MainButton} onClick={onSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhoneNumberModal;
