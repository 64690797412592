import { types, flow, Instance, SnapshotIn, applySnapshot } from "mobx-state-tree";
import axios from "axios";
import TestPanel, { ITestPanelSnapshotIn } from "./TestPanel";
import ProctorQuestion from "./ProctorQuestion";
import { salivaConfirm12TestSideAPanels, salivaConfirm12TestSideBPanels } from "./TestTypes/SalivaConfirm12";
import { salivaConfirm14TestSideAPanels, salivaConfirm14TestSideBPanels } from "./TestTypes/SalivaConfirm14";
import { TestTypes } from "./TestTypes/TestTypes";

export const alcoholQuestion = "Alcohol panel read at correct time and result captured in checklist"
export const defaultProctorQuestions =
    [
        {
            question: "Mouth is empty.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 0,
            approximateVideoStopTimeInSeconds: 2
        },
        {
            question: "Test sealed.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 10,
            approximateVideoStopTimeInSeconds: 20
        },
        {
            question: "Test stick was taken from its wrapper.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 30,
            approximateVideoStopTimeInSeconds: 40
        },
        {
            question: "Member in frame and test stick in mouth for 5 min.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 50,
            approximateVideoStopTimeInSeconds: 60
        },
        {
            question: "Test in the correct place.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 70,
            approximateVideoStopTimeInSeconds: 80
        },
        {
            question: "Test stick was placed in test cube at the end of the 5 minute timer.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 90,
            approximateVideoStopTimeInSeconds: 100
        },
        {
            question: "Member placed the test cube in the designated spot in front of camera with test stick inside.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 110,
            approximateVideoStopTimeInSeconds: 120
        },
        {
            question: alcoholQuestion,
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 120,
            approximateVideoStopTimeInSeconds: 130
        },
        {
            question: "Member stays in frame for the duration of the testing period.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 130,
            approximateVideoStopTimeInSeconds: 140
        },
        {
            question: "Member successfully showed Side A and Side B of test.",
            answer: "",
            notes: "",
            approximateVideoStartTimeInSeconds: 140,
            approximateVideoStopTimeInSeconds: 150
        },
    ]


const _testResult = types.model("TestResult", {
    id: types.maybe(types.string),
    sideAResults: types.array(TestPanel),
    sideBResults: types.array(TestPanel),
    alcoholResult: types.maybeNull(types.string),
    proctorQuestionsAndAnswers: types.array(ProctorQuestion),
    testCubeType: types.maybe(types.string),
    testCubeTypeOverride: types.maybe(types.string)
}).views((self) => ({
    get allResults() {
        return self.sideAResults.concat(self.sideBResults)
    },
    get allPanelResultsRecordedAndAllQuestionsAnswered() {
        return self.sideAResults.every(panel => !!panel.isRecorded) && self.sideBResults.every(currentValue => !!currentValue.isRecorded) && self.proctorQuestionsAndAnswers?.every(currentValue => currentValue.question !== alcoholQuestion ? !!currentValue.answer : true) && !!self.alcoholResult;
    },
    get memberId() {
        return self.id
    },
    get allProctorQuestionsAnswered() {
        return self.proctorQuestionsAndAnswers?.every(panel => panel.question !== alcoholQuestion ? !!panel.answer : true) && self.proctorQuestionsAndAnswers.length > 0
    },
    get isSideARecorded() {
        return self.sideAResults.every(panel => !!panel.isRecorded) && self.sideAResults.length > 0 && !!self.alcoholResult
    },
    get isSideBRecorded() {
        return self.sideBResults.every(panel => !!panel.isRecorded) && self.sideBResults.length > 0
    },
    get alcoholValue() {
        switch (self.alcoholResult) {
            case "Pass":
                return 0
            case ">= 0.02%":
                return 25
            case ">= 0.08%":
                return 50
            case ">= 0.15%":
                return 75
            case ">= 0.30%":
                return 100
            default:
                return 0;
        }
    },
}))
    .actions((self) => ({
        setAlcoholResult: (value: number | number[]) => {
            switch (value) {
                case 0:
                    self.alcoholResult = "Pass"
                    break;
                case 25:
                    self.alcoholResult = ">= 0.02%"
                    break;
                case 50:
                    self.alcoholResult = ">= 0.08%"
                    break;
                case 75:
                    self.alcoholResult = ">= 0.15%"
                    break;
                case 100:
                    self.alcoholResult = ">= 0.30%"
                    break;
                default:
                    self.alcoholResult = "Pass"
                    break;
            }
        },
        setTestCubeTypeOverride: (value: string | undefined) => {
            if (!value) {
                return;
            }
            self.testCubeTypeOverride = value;
        },
        load: flow(function* (sessionId: string) {
            const results = yield axios.get(`/test-sessions/${sessionId}`)
            const testCubeResults = results.data.testCubeResults
            const proctorAnswers = results.data.proctorAnswers
            const testCubeType = results.data.testCubeType
            const testCubeTypeOverride = results.data.testCubeTypeOverride

            if (proctorAnswers && !testCubeResults) {
                const currentSessionSnapshot = {
                    proctorQuestionsAndAnswers: proctorAnswers
                }
                applySnapshot(self, currentSessionSnapshot ?? {});
            }

            if (testCubeResults) {
                const savedPanelResultsMap = testCubeResults.reduce((allPanels: any, panel: any) => {
                    const { substanceName } = panel;
                    return { ...allPanels, [substanceName]: panel };
                }, []);

                const currentSessionSnapshot = {
                    sideAResults: mapPanelSide(selectPanelSideA(getTestCubeType(self.testCubeTypeOverride, testCubeTypeOverride, testCubeType)), savedPanelResultsMap),
                    sideBResults: mapPanelSide(selectPanelSideB(getTestCubeType(self.testCubeTypeOverride, testCubeTypeOverride, testCubeType)), savedPanelResultsMap),
                    alcoholResult: results.data.alcoholResult,
                    proctorQuestionsAndAnswers: proctorAnswers
                }
                applySnapshot(self, currentSessionSnapshot ?? {});
            }

            if (!testCubeResults && !proctorAnswers) {
                const currentSessionSnapshot = {
                    sideAResults: selectPanelSideA(getTestCubeType(self.testCubeTypeOverride, testCubeTypeOverride, testCubeType)),
                    sideBResults: selectPanelSideB(getTestCubeType(self.testCubeTypeOverride, testCubeTypeOverride, testCubeType)),
                    alcoholResult: "Pass",
                    testCubeType: testCubeType,
                    proctorQuestionsAndAnswers: defaultProctorQuestions
                }
                applySnapshot(self, currentSessionSnapshot ?? {});
            }
        }),
        resetResults: (testResult: ITestResult, testCubeType: string | undefined) => {
            const currentSessionSnapshot = {
                sideAResults: selectPanelSideA(getTestCubeType(self.testCubeTypeOverride, testResult.testCubeTypeOverride, testResult.testCubeType)),
                sideBResults: selectPanelSideB(getTestCubeType(self.testCubeTypeOverride, testResult.testCubeTypeOverride, testResult.testCubeType)),
                alcoholResult: "Pass",
                testCubeType: self.testCubeType,
                testCubeTypeOverride: self.testCubeTypeOverride,
                proctorQuestionsAndAnswers: testResult.proctorQuestionsAndAnswers
            }
            applySnapshot(self, currentSessionSnapshot ?? {});
        },
        saveResults: flow(function* (sessionId: string) {
            const patch = {
                testCubeResults: self.allResults,
                alcoholResult: self.alcoholResult,
                proctorAnswers: self.proctorQuestionsAndAnswers,
                testCubeTypeOverride: self.testCubeTypeOverride
            }
            yield axios.patch(`/test-sessions/${sessionId}`, patch);
        }),
        submitResults: flow(function* (sessionId: string, userId: string) {
            const resultVerificationDate = new Date().toISOString();

            const patch = {
                testCubeResults: self.allResults,
                reviewed: true,
                alcoholResult: self.alcoholResult,
                proctorAnswers: self.proctorQuestionsAndAnswers,
                resultVerificationDate: resultVerificationDate,
                resultVerifiedBy: userId,
                testCubeTypeOverride: self.testCubeTypeOverride
            }
            yield axios.patch(`/test-sessions/${sessionId}`, patch);
        }),
    }));

const getTestCubeType = (selectedTestCubeTypeOverride: string | undefined, storedTestCubeTypeOverride: string | undefined, storedTestCubeType: string | undefined): string => {
    if (!!selectedTestCubeTypeOverride) {
        return selectedTestCubeTypeOverride
    }

    if (!!storedTestCubeTypeOverride) {
        return storedTestCubeTypeOverride
    }

    if (!!storedTestCubeType) {
        return storedTestCubeType
    }

    return ""
}

const selectPanelSideA = (testCubeType: string | undefined): ITestPanelSnapshotIn[] => {
    switch (testCubeType) {
        case TestTypes.SalivaConfirm14:
            return salivaConfirm14TestSideAPanels
        default:
            return salivaConfirm12TestSideAPanels;
    }
}

const selectPanelSideB = (testCubeType: string | undefined): ITestPanelSnapshotIn[] => {
    switch (testCubeType) {
        case TestTypes.SalivaConfirm14:
            return salivaConfirm14TestSideBPanels
        default:
            return salivaConfirm12TestSideBPanels;
    }
}

export const TestResult = types.snapshotProcessor(_testResult, {
    preProcessor(snapshot: ITestResultSnapshotIn) {
        const defaults = {
            proctorQuestionsAndAnswers: defaultProctorQuestions,
            sideAResults: salivaConfirm12TestSideAPanels,
            sideBResults: salivaConfirm12TestSideBPanels,
            alcoholResult: "Pass",
            testCubeType: TestTypes.SalivaConfirm12
        };

        if (!snapshot) {
            return defaults;
        }

        return { ...snapshot, ...defaults };
    }
});
const mapPanelSide = (panelSide: ITestPanelSnapshotIn[], savedPanelResultsMap: { [key: string]: ITestPanelSnapshotIn; }) => {
    const mappedResults = panelSide.map((panel) => {
        if (!panel.substanceName) {
            return {};
        }
        const savedPanel = savedPanelResultsMap[panel.substanceName];

        return {
            ...panel,
            controlLine: savedPanel.controlLine,
            testLine: savedPanel.testLine,
            invalidChecked: savedPanel.invalidChecked,
            passByAllowableSubstance: savedPanel.passByAllowableSubstance,
        };
    });

    return mappedResults
}

export interface ITestResult extends Instance<typeof TestResult> { }
export interface ITestResultSnapshotIn
    extends SnapshotIn<typeof _testResult> { }
