import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { ITestStep } from "../../Models/TestStep";
import AlcoholSlider from "../Results/AlcoholSlider";
import { alcoholQuestion, ITestResult } from "../../Models/TestResult";
import TestImageDisplay from "./TestImageDisplay";
import { Record } from "react-admin";
import Button from "@material-ui/core/Button";

interface ProctorCheckListItemProps {
  label: string;
  onAnswerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNotesChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  answer: string;
  step: ITestStep | undefined;
  testResult: ITestResult;
  notes?: string;
  setSeekToSeconds: (videoTimeInSeconds: number) => void;
  record: Record;
}

const ProctorCheckListItem: React.FC<ProctorCheckListItemProps> = (props) => {
  const {
    label,
    onAnswerChange,
    answer,
    notes,
    onNotesChange,
    step,
    testResult,
    setSeekToSeconds,
  } = props;
  

  const [sliderExpanded, setSliderExpanded] = useState(false);

  const GreenRadio = withStyles({
    root: {
      "&$checked": {
        color: "#2DAF79",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const YellowRadio = withStyles({
    root: {
      "&$checked": {
        color: "#E5AC2E",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const RedRadio = withStyles({
    root: {
      "&$checked": {
        color: "#DA1C57",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const convertSecondsToMinutesAndSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const minutesString = (`${0}${minutes}`).slice(-2);
    const secondsString = (`${0}${remainingSeconds}`).slice(-2);

    return `${minutesString}:${secondsString}`
  }

  const accordionClasses = makeStyles({
    beforeVideoTime: {
      backgroundColor: "#fafafa",
    },
    alcoholContent: {
      flexDirection: "column"
    },
    afterVideoTime: {
      color: "white",
      backgroundColor: "#4395CA",
    },
    button: {
      backgroundColor: "#2196f3",
      color: "#FFF",
      fontSize:"10px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      boxShadow: "0 9px 25px rgba(0, 0, 0, 0.1)",
      "&:hover": {
        backgroundColor: "#66a7dc",
        boxShadow: "0 9px 25px rgba(0, 0, 0, 0.14)", // Deepen shadow on hover
      },
      "&:active": {
        backgroundColor: "#66a7dc",
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.14)", // Maintain shadow on active state
        transform: "translateY(2px)", // Move the button down slightly
      }
    },
    duringVideoTime: {
      color: "white",
      animation: "$blinking 2s infinite;",
      animationDirection: "reverse",
    },
    "@keyframes blinking": {
      "0%": {
        backgroundColor: "#3EC0BE",
      },
      "50%": {
        backgroundColor: "#4395CA",
      },
      "100%": {
        backgroundColor: "#3EC0BE",
      },
    },
  })();

  let className;

  if (step?.stepState === "during") {
    className = accordionClasses.duringVideoTime;
  } else if (step?.stepState === "before") {
    className = accordionClasses.beforeVideoTime;
  } else if (step?.stepState === "after") {
    className = accordionClasses.afterVideoTime;
  } else {
    className = accordionClasses.beforeVideoTime;
  }

  const onClick = () => {
    if (!!step?.stopTimeInSeconds) {
      setSeekToSeconds(step.startTimeInSeconds);
    }
  }
  const onSliderPanelClick = () =>{
  setSliderExpanded(!sliderExpanded)
  }

  if(label === alcoholQuestion){
    return (
      <Accordion expanded={sliderExpanded}>
        <AccordionSummary className={className}>
          <Grid container direction="row" >
            <Grid item xs={4} container wrap="nowrap">
              <Button className={accordionClasses.button} onClick={onSliderPanelClick} >record Alcohol result</Button>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ paddingLeft: 8 }} variant="subtitle1">{label}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={accordionClasses.alcoholContent}>
          <TestImageDisplay alcoholSideA={props.record.alcoholSideAPhoto} />
          <AlcoholSlider testResult={testResult} />
        </AccordionDetails>
      </Accordion>
    )
  }
  return (
    <Accordion expanded={answer === "fail" || answer === "review"}>
      <AccordionSummary className={className}>
        <Grid container direction="row" >
          <Grid item xs={3} container wrap="nowrap">
            <RadioGroup
              row
              onChange={onAnswerChange}
              value={answer}
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
              }}
            >
              <FormControlLabel style={{ paddingRight: 8 }} value="pass" control={<GreenRadio />} label="" />
              <FormControlLabel style={{ paddingRight: 8 }} value="review" control={<YellowRadio />} label="" />
              <FormControlLabel style={{ paddingRight: 8 }} value="fail" control={<RedRadio />} label="" />
            </RadioGroup>
          </Grid>
          <Grid item xs={9} onClick={onClick}>
            <Typography style={{ paddingLeft: 8 }} variant="subtitle1">{label}</Typography>
            {!!step?.stopTimeInSeconds &&
              <Typography style={{ paddingLeft: 8 }} variant="subtitle2">{convertSecondsToMinutesAndSeconds(step.startTimeInSeconds)} - {convertSecondsToMinutesAndSeconds(step.stopTimeInSeconds)}</Typography>
            }
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          id="filled-multiline-static"
          label="Notes"
          multiline
          rows={4}
          variant="filled"
          value={notes || ""}
          fullWidth
          onChange={(e) => onNotesChange!(e)}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default observer(ProctorCheckListItem);
