import { Instance, types } from "mobx-state-tree";

const ProctorQuestion = types.model("ProctorQuestion", {
    question: types.string,
    answer: types.string,
    notes: types.string,
}).actions((self) => ({
    onAnswerChange(e: any) {
        self.answer = e.target.value;
    },
    onNotesChange(e: any) {
        self.notes = e.target.value;
    }
}))

export default ProctorQuestion
export interface IProctorQuestion extends Instance<typeof ProctorQuestion> { } 