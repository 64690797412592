import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";
import NearMeIcon from "@material-ui/icons/NearMe";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    mainButton: {
      backgroundColor: "#2196f3",
      marginTop: "12px",
      marginRight: "12px",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#66a7dc",
      },
      width: 255,
    },
    yesButton: {
      margin: theme.spacing(1),
      backgroundColor: "#2DAF79",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#3EC0BE",
        color: "#FFF",
      },
    },
    noButton: {
      margin: theme.spacing(1),
      backgroundColor: "lightgray",
      color: "black",
      "&:hover": {
        backgroundColor: "darkgray",
        color: "#FFF",
      },
    },
    text: {
      color: "black",
    },
    buttonBox: {
      marginTop: "26px",
    },
    upperDivider: {
      marginBottom: "10px",
    },
    lowerDivider: {
      marginTop: "10px",
    },
    title: {
      marginBottom: "26px",
    },
    icon: {
      fontSize: "65px",
      color: "#2096f3",
    },
  })
);
interface PasswordForceChangeModalProps {
  id?: any;
  baseUrl: string;
}
const ForcePasswordChangeModal: React.FC<PasswordForceChangeModalProps> = (props) => {
  const { id, baseUrl } = props;
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [loader, setLoader] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(1500);
  const [snackBarContentMessage, setSnackBarContentMessage] = useState("");
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const issueTempPassword = async () => {
    setLoader(true);
    await axios({ url: `user/${id}/force-password-change`, baseURL: baseUrl, method: "post" })
      .then(() => {
        setLoader(false);
        setSnackBarContentMessage("Success!");
        setSuccessSnackBar(true);
        setOpenSnackBar(!openSnackBar);
        setAutoHideDuration(1500);
      })
      .catch((response) => {
        setLoader(false);
        setSnackBarContentMessage(`${response}`);
        setSuccessSnackBar(false);
        setAutoHideDuration(6000);
        setOpenSnackBar(!openSnackBar);
      });
  };
  const snackBarClose = () => {
    setShowChangePassword(!showChangePassword);
    setOpenSnackBar(!openSnackBar);
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Box display="flex" justifyContent="center">
        <HelpOutlineIcon className={classes.icon} />
      </Box>
      <Box className={classes.title} display="flex" justifyContent="center">
        <h2>Are you sure?</h2>
      </Box>
      <Divider className={`${classes.upperDivider}`} />
      <Box display="flex" justifyContent="center">
        <Typography className={classes.text}>Select “Yes” to reset password for user.</Typography>
      </Box>
      <Divider className={`${classes.lowerDivider}`} />
      <Box className={classes.buttonBox} display="flex" justifyContent="space-evenly">
        <Button onClick={() => issueTempPassword()} className={classes.yesButton}>
          {loader ? "Loading..." : "Yes"}
        </Button>
        <Button onClick={() => setShowChangePassword(!showChangePassword)} className={classes.noButton}>
          No
        </Button>
      </Box>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={openSnackBar} autoHideDuration={autoHideDuration} onClose={() => snackBarClose()} key={"topright"}>
        <SnackbarContent style={{ backgroundColor: successSnackBar ? "#2DAF79" : "red" }} message={<span>{snackBarContentMessage}</span>} />
      </Snackbar>
    </div>
  );
  return (
    <>
      <Box display="flex">
        <Button startIcon={<NearMeIcon />} className={classes.mainButton} onClick={() => setShowChangePassword(!showChangePassword)}>
          Force Password Change
        </Button>
      </Box>
      <Modal open={showChangePassword}>{body}</Modal>
    </>
  );
};
export default ForcePasswordChangeModal;
