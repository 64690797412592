import React from "react";
import { useSignOutContext } from "./ClearMindAdminAuthenticator";
import MenuItem from "@material-ui/core/MenuItem";
import ExitIcon from "@material-ui/icons/ExitToApp";

const LogoutButton = () => {
    const { signOut } = useSignOutContext();
    const onclick = () => {
        signOut();
    }
    return <MenuItem
        onClick={onclick}
    >
        <ExitIcon /> Logout
    </MenuItem>
}

export default LogoutButton;