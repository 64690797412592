import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";

interface ScrubberProps {
  duration: number;
  onStepClick: (stepPlaybackTime: number) => void;
  percent: number;
}

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "100%",
    backgroundColor: "red",
  },
  thumb: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "-6.5px",
    right: "5px",
    "& .MuiSlider-thumb": {
      color: "#838383",
      height: "22px",
      width: "22px",
      zIndex: 1,
    },
    "& .MuiSlider-rail": {
      opacity: "0",
    },
    "& .MuiSlider-track": {
      opacity: "0",
    },
  },
});

const Scrubber: React.FC<ScrubberProps> = (props) => {
  const { duration, onStepClick, percent } = props;
  const classes = useStyles();

  const handleChange = (_: any, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      const updateSeconds = Math.round((newValue / 100) * duration);
      onStepClick(updateSeconds);
    }
  };

  return (
    <div className={classes.root}>
      <Slider
        className={classes.thumb}
        value={percent}
        onChange={handleChange}
        aria-labelledby="continuous-slider"
      />
    </div>
  );
};
export default Scrubber;
