const programOptions = [

    {
        name: "Accountability Program(5 tests per month)",
        id: 5
    },
    {
        name: "Accountability Plus(9 tests per month)",
        id: 9
    },
    {
        name: "Maintenance Package(7 tests per quarter)",
        id: 7
    },
    {
        name: "On Demand Testing(Manually scheduled testing only)",
        id: -1
    }
]

export default programOptions