import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { INotificationPreferenceModel } from "../../Models/NotificationPreferenceModel";

const choices = [
    {
        value: "email",
        label: "Email Only",
    },
    {
        value: "text",
        label: "Text Only",
    },
    {
        value: "both",
        label: "Both",
    },
    {
        value: "none",
        label: "None",
    },
];

interface MemberPreferenceDropdownProps {
    preferenceField: string;
    label: string;
    memberPreference: INotificationPreferenceModel
    memberId: string,
    partnerId: string
}

const MemberPreferenceDropdown: React.FC<MemberPreferenceDropdownProps> = (props) => {
    const { preferenceField, label, memberPreference, memberId, partnerId } = props
    const [preference, setPreference] = React.useState("");
    useEffect(() => {
        const loadMemberAndSetPreference = async () => {
            await memberPreference.loadMemberPreference(partnerId, memberId)
            setPreference(memberPreference.getPreferenceFieldValue(preferenceField))
        }
        if (memberPreference) {
            loadMemberAndSetPreference()
        }
    }, [memberId, memberPreference, partnerId, preferenceField])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPreference(event.target.value);
        memberPreference.setMemberPreference(preferenceField, event.target.value)
    };
    return (
        <Grid item >
            <TextField
                id={preferenceField}
                select
                label={label}
                value={preference}
                onChange={handleChange}
            >
                {choices.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    );
}
export default MemberPreferenceDropdown
