import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"
import React, { useState } from "react";
import { useCreate, useNotify } from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MemberAutocomplete from "./MemberAutocomplete";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: "#2196f3",
      marginTop: "12px",
      marginRight: "12px",
      marginBottom: "10px",
      color: "#FFF",
      "&:hover": {
        backgroundColor: "#66a7dc",
      },
    },
  })
);
interface TriggerTestProps {
  facilityCode: string;
  refresh: () => void;
}

export const TriggerTest: React.FC<TriggerTestProps> = (props) => {
  const { user } = useClearMindAdminContext();
  const { facilityCode, refresh } = props;
  const notify = useNotify();
  const styles = useStyles();
  const [create, { loading }] = useCreate();
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [memberId, setMemberId] = useState("");

  const getTimeZoneIdentifier = (timeZone: any) => {
    switch (timeZone) {
      case "Eastern Standard Time":
        return "America/New_York";
      case "Central Standard Time":
        return "America/Chicago";
      case "Mountain Standard Time":
        return "America/Denver";
      case "Pacific Standard Time":
        return "America/Los_Angeles";
      default:
        return "America/Chicago";
    }
  };
  const getUtcDateStrings = () => {
    const now = new Date();
    now.setHours(now.getHours() + 1);

    // Format the local date in the selected timezone
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: timeZone,
    } as Intl.DateTimeFormatOptions;

    const localDateString = now.toLocaleString("en-US", options);
    const formattedLocalDate = new Date(localDateString);

    const utcYear = formattedLocalDate.getUTCFullYear();
    const utcMonth = formattedLocalDate.getUTCMonth() + 1; // Months are 0-based in JavaScript
    const utcDay = formattedLocalDate.getUTCDate();
    const utcHour = formattedLocalDate.getUTCHours();
    return [`${utcMonth}/${utcDay}/${utcYear}`, utcHour.toString()];
  };

  const onAddClick = () => {
    const [utcDate, utcHour] = getUtcDateStrings();

    const data = {
      testDateUtc: utcDate,
      userId: memberId,
      testHourUtc: utcHour,
      schedulerId: user.id,
    };

    const options = {
      onSuccess: () => {
        notify("Test added to next hour's schedule.");
        refresh();
        setOpen(false);
      },
      onFailure: (error: any) => {
        notify(error.body.errors[0].message, {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      },
      onError: () => {
        notify("Failed to add test.");
      },
    };
    create("scheduled-tests", data, options);
  };

  const disableAddButton = () => {
    return !memberId;
  };

  const onMemberChange = (newValue: any) => {
    if (!newValue) {
      setMemberId("");
    } else {
      const tz = getTimeZoneIdentifier(newValue.timeZone);
      setTimeZone(tz);
      setMemberId(newValue.id);
    }
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} className={styles.button} startIcon={<AddIcon />}>
        Trigger Test
      </Button>
      <Dialog open={open}>
        <DialogTitle>Trigger Test</DialogTitle>
        <DialogContent>
          Test notification will go out at the top of the next hour.
          <br />
          <br />
          <MemberAutocomplete facilityCode={facilityCode} fullWidth label="Member" onChange={onMemberChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={onAddClick} disabled={loading || disableAddButton()}>
            {loading ? <CircularProgress size={21} /> : "Trigger"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};