import { ICognitoStorage } from "amazon-cognito-identity-js"
export class AuthenticationStorage implements ICognitoStorage {
    _sessionStorage: Storage;
    _localStorage: Storage;
    _storage: Storage;
    constructor(sessionStorage: Storage, localStorage: Storage) {
        this._sessionStorage = sessionStorage;
        this._localStorage = localStorage;
        this._storage = this._localStorage.getItem("remember-me") ? this._localStorage : this._sessionStorage;
    }

    setRememberMe(rememberMe: boolean) {
        if (rememberMe) {
            this._storage = this._localStorage;
        } else {
            this._storage = this._sessionStorage;
        }
    }

    setItem(key: string, value: string) {
        return this._storage.setItem(key, value);
    }

    getItem(key: string) {
        return this._storage.getItem(key);
    }

    removeItem(key: string) {
        this._storage.removeItem(key);
    }

    clear() {
        this._storage.clear();
    }
}

const _authenticationStorage = new AuthenticationStorage(window.sessionStorage, window.localStorage);

export default _authenticationStorage;
