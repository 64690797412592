const languageOptions = [
    {
        id: "en",
        name: "English"
    },
    {
        id: "es",
        name: "Spanish"
    }
];

export default languageOptions;
