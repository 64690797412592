import React from "react";
import ClearMindAdminAuthenticator from "./Components/Authenticator/ClearMindAdminAuthenticator";
import Bootstrap from "./Components/Authenticator/AuthenticatorTheme";
import App from "./App";
import { ClearMindAdminContext } from "./ClearMindAdminContext/ClearMindAdminContext";
import * as Sentry from "@sentry/react";
import ErrorPage from "./Error/ErrorPage";

const AppWithAuth: React.FC = () => {
    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
            <ClearMindAdminAuthenticator usernameAttributes={"email"} theme={Bootstrap}>
                <ClearMindAdminContext>
                    <App />
                </ClearMindAdminContext>
            </ClearMindAdminAuthenticator>
        </Sentry.ErrorBoundary>
    );
};

export default AppWithAuth;