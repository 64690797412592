import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "#4183C4",
      width: 100,
    },
  })
);
interface ResultsPdfDownloadButtonProps {
  id: any;
  date: string;
}

const ResultsPdfDownloadButton: React.FC<ResultsPdfDownloadButtonProps> = (props) => {
  const { id, date } = props;
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: `result-pdf-button-${id}` });

  const getResultPdf = async () => {
    const response = await trackPromise(axios.get(`/test-results-pdf/${id}`), `result-pdf-button-${id}`);
    const buffer = Buffer.from(response.data, "base64");
    const blob = new Blob([buffer], { type: "application/pdf" });

    fileDownload(blob, `${date} - Result Report.pdf`);
  };

  return (
    <Button size="small" startIcon={promiseInProgress || <GetAppIcon />} className={classes.button} onClick={getResultPdf} disabled={promiseInProgress}>
      {promiseInProgress ? <CircularProgress size={15} /> : "Download"}
    </Button>
  );
};

export default ResultsPdfDownloadButton;
