import { Instance, cast, destroy, flow, types } from "mobx-state-tree";
import ScheduledEvent, { IScheduledEvent } from "./ScheduledEvent";
import { GetScheduledEventsByMemberId } from "../Services/ScheduledEventsService";


export const ScheduledEventsStore = types
  .model({ scheduledEvents: types.array(ScheduledEvent) })
  .actions((self) => ({
    add: (scheduledEvent: IScheduledEvent) => {
      self.scheduledEvents.push(scheduledEvent);
    },
    remove: (scheduledEvent: IScheduledEvent) => {
      destroy(scheduledEvent);
    },
    load: flow(function* (memberId: string) {
      var events = yield GetScheduledEventsByMemberId(memberId);
      self.scheduledEvents = events.map((event: any) => {
        event.eventDate = `${event.eventDate}Z`;
        return ScheduledEvent.create(event);
      });
    }),
    pushChange() {
      self.scheduledEvents = cast([...self.scheduledEvents]);
    },
  }))
  .views((self) => ({
    get mappedEvents() {
      const mapEvents = self.scheduledEvents.reduce((prev: any, current) => {
        if (!prev[current.dateKey]) {
          prev[current.dateKey] = [];
        }
        prev[current.dateKey].push(current);
        return prev;
      }, {});
      return mapEvents;
    },
  }));

export interface IScheduledEventStore extends Instance<typeof ScheduledEventsStore> {}
