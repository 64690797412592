import React from "react";
import { TextField, useGetList, LoadingIndicator, RecordContextProvider, SimpleShowLayout } from "react-admin";
import { Grid, Typography } from "@material-ui/core";


const MemberFacilityPartner: React.FC = (props: any) => {
    const { facilityCode } = props;

    const { data, loading } = useGetList(
        "monitoring-partners",
        { page: 1, perPage: 10 },
        { field: "facilityName", order: "ASC" },
        { facilityCode: facilityCode },
    );
    if (loading) { return <LoadingIndicator />; }

    const monitoringPartnerRecord = data[Object.keys(data)[0]];

    if (!monitoringPartnerRecord) {
        return <Typography style={{ color: "black" }}>
            Monitoring Partner for monitoring partner code <b>{facilityCode}</b> not found.
        </Typography>
    }

    return (

        <RecordContextProvider value={monitoringPartnerRecord}>
            <Grid container spacing={2}>
                <Grid item >
                    <SimpleShowLayout>
                        <TextField source="facilityName" label="Facility Name" />
                        <TextField source="facilityPhoneNumber" label="Facility Phone Number" />
                        <TextField source="facilityCode" label="Facility Code" />
                    </SimpleShowLayout>
                </Grid>
                <Grid item >
                    <SimpleShowLayout>
                        <TextField source="contactName" label="Contact Name" />
                        <TextField source="contactEmail" label="Contact Email" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </RecordContextProvider>
    );
};

export default MemberFacilityPartner;
