import axios from "axios";

const getWeeklyTestSchedule = async (date: Date, facilityCode: string) => {
  try {
    const response = await axios.get(
      `/user-testing-schedule/week?facilityCode=${facilityCode}&date=${date.toLocaleDateString()}`
    );
    if (response.status > 299) throw response;
    const data = await response.data;

    return data;
  } catch (e: any) {
    console.log(e);
  }
};

export default getWeeklyTestSchedule;
