import { Show } from "react-admin";
import MemberShow from "./MemberShow";


export const MembersShow = (props: any) => {
    return (
        <Show
            {...props}
        >
            <MemberShow {...props} />
        </Show>
    );
};
