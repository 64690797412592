import React from "react";
import {
  Datagrid,
  ListContextProvider,
  TextField,
  LoadingIndicator
} from "react-admin";
import { reduceForDataGrid, useChildResourceQuery } from "../../DataProvider";

const MemberSupporterList: React.FC = (props: any) => {
  const { id } = props;

  const { data, loaded } = useChildResourceQuery("members", "supporters", id);

  const dataGridData = reduceForDataGrid(data, "userId");

  if (!loaded || !dataGridData) {
    return <LoadingIndicator />;
  }

  return (
    <ListContextProvider
      value={{
        data: dataGridData,
        ids: dataGridData.ids,
        currentSort: { field: "id", order: "ASC" },
        basePath: "members/{member-id}/supporters",
        resource: "member-supporters",
        selectedIds: [],
      }}
    >
      <Datagrid>
        <TextField source="fullName" label="Full Name" />
        <TextField source="phoneNumber" label="Phone #" />
        <TextField source="email" />
        <TextField source="supporterRole" label="Relationship" />
      </Datagrid>
    </ListContextProvider>
  );
};

export default MemberSupporterList;
