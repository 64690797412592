import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import { AxiosInitializer } from "../AxiosInitializer";
import { useClearMindAdminContext } from "../ClearMindAdminContext/ClearMindAdminContext";

interface UserInitializerProps {
    setIsInitialized: (value: boolean) => void;
}

export const UserInitializer: React.FC<UserInitializerProps> = observer((props) => {

    const { setIsInitialized } = props;
    const [isInitializing, setIsInitializing] = useState(false);
    const { user } = useClearMindAdminContext();

    // const checkSubscription = async () => {
    //   setIsActiveSubscription(await userStore.checkSubscriptionStatus());
    // };

    useEffect(() => {
        if (!isInitializing) {
            setIsInitializing(true);
            AxiosInitializer().then(() => {
                return user.load();
            }).then(() => {
                setIsInitialized(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Loader active />
});
