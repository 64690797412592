import { observer } from "mobx-react";
import { useState } from "react";
import { theme } from "./theme";
import DefaultIcon from "@material-ui/icons/ViewList";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import {
  Admin,
  AppBar,
  DashboardMenuItem,
  Layout,
  Menu,
  MenuItemLink,
  Resource,
  UserMenu,
  getResources,
  useGetResourceLabel,
} from "react-admin";
import { AdminUserEdit } from "./Components/Users/AdminUserEdit";
import { AdminUserShow } from "./Components/Users/AdminUserShow";
import { UserInitializer } from "./UserInitializer/UserInitializer";
import UserIcon from "@material-ui/icons/Group";
import {
  TestSessionList,
  TestSessionShow,
} from "./Components/Results/ProctorSessionGrid";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import dataProvider from "./DataProvider";
import AccountIcon from "@material-ui/icons/AccountBox";
import DateRangeIcon from "@material-ui/icons/DateRange";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import { PartnerEdit } from "./Components/Partners/PartnerEdit";
import { PartnerCreate } from "./Components/Partners/PartnerCreate";
import Loading from "./Components/Loading/Loading";
import {
  getAdminUserList,
  getAdminUserCreate,
  PartnerList,
  membersLabel,
  monitoringPartnerLabel,
  WithFilter,
  WithInitialValues,
} from "./Components/Services/ResourceService";
import {
  MemberGrid,
} from "./Components/Members/MemberGrid";
import { PartnerMemberEdit } from "./Components/Members/PartnerMemberEdit";
import { MembersShow } from "./Components/Members/MembersShow";
import { PartnerMemberCreate } from "./Components/Members/PartnerMemberCreate";
import { useClearMindAdminContext } from "../src/ClearMindAdminContext/ClearMindAdminContext";
import {
  SupporterShow,
  SupporterGrid,
} from "./Components/SupporterList/SupporterGrid";
import {
  ProctoredResultEdit,
  ProctoredResultsList,
  ProctoredResultsListShow,
} from "./Components/ProctoredResults/ProctoredResultsList";
import LogoutButton from "./Components/Authenticator/LogoutButton";
import logo from "../src/oneLineLogo.png";
import { ShowMemberInWeeklySchedule } from "./Components/WeeklyTestSchedule/WeeklyTestResource";
import PartnerShow from "./Components/Partners/PartnerShow";
import NoFacilityCodeAlert from "./Components/Alerts/NoFacilityCodeAlert";
import MemberTestResultsComponent from "./Components/MemberTestResults/MemberTestResultsComponent";
import NotificationPreferencePage from "./Components/NotificationPreferences/NotificationPreferencePage";
import { Route } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import PhoneNumberModal from "./Components/MonitoringPartner/PhoneModal";

const MyMenu = (props: any) => {
  const { hasDashboard, dense } = props;
  const { user } = useClearMindAdminContext();

  const getResourceLabel = useGetResourceLabel();
  const resources = useSelector(getResources, shallowEqual) as Array<any>;
  const children = (
    <>
      {hasDashboard && <DashboardMenuItem dense={dense} />}
      {resources
        .filter(r => r.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: `/${resource.name}`,
              state: { _scrollToTop: true },
            }}
            primaryText={getResourceLabel(resource.name, 2)}
            leftIcon={
              resource.icon ? (
                <resource.icon />
              ) : (
                <DefaultIcon />
              )
            }
            dense={dense}
          />
        ))}
      {user.isPartnerAdmin && <MenuItemLink
        key="preferences"
        to={{
          pathname: `/monitoring-partner-preferences`,
          state: { _scrollToTop: true },
        }}
        primaryText="Notification Preferences"
        leftIcon={<SettingsIcon />
        }
        dense={dense}
      />}
    </>
  );
  return <Menu children={children} />
}

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const { user } = useClearMindAdminContext();

  if (isInitialized) {
    const ClearMindUserMenu = (props: any) => (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <img style={{ height: "40px" }} src={logo} alt={"Logo"} />
        </div>
        <UserMenu {...props}>
          <LogoutButton />
        </UserMenu>
      </>
    );
    const MyAppBar = (props: any) => <AppBar userMenu={<ClearMindUserMenu {...props} />} />;

    const ClearMindLayout = (props: any) => <Layout {...props} appBar={MyAppBar} menu={MyMenu} />;

    const customeRoutes = user.isPartnerAdmin ? [<Route path="/monitoring-partner-preferences" component={NotificationPreferencePage} />] : []

    return (
      <>
        <Loading />
        <NoFacilityCodeAlert user={user} />
        <Admin theme={theme} dataProvider={dataProvider} layout={ClearMindLayout} customRoutes={customeRoutes}>
          <Resource name="admin-users" list={getAdminUserList(user)} edit={user.isSuperAdmin ? AdminUserEdit : undefined} show={AdminUserShow} create={getAdminUserCreate(user)} icon={UserIcon} options={{ label: "Admin Portal Users" }} />
          <Resource name="test-sessions" list={user.isAdminOrProctor ? TestSessionList : undefined} show={TestSessionShow} icon={AssignmentIcon} options={{ label: "Test Sessions" }} />
          <Resource name="proctored-test-sessions" list={user.isAdminOrProctor ? ProctoredResultsList : undefined} edit={user.isPartnerOrAdmin || user.isProctorAdmin ? ProctoredResultEdit : undefined} show={ProctoredResultsListShow} icon={AssignmentIcon} options={{ label: "Proctored Test Sessions" }} />
          <Resource name="members" list={user.isPartnerOrAdmin ? MemberGrid : undefined} edit={user.isPartnerOrAdmin ? PartnerMemberEdit : undefined} show={MembersShow} create={user.isPartnerOrAdmin ? PartnerMemberCreate : undefined} icon={AccountIcon} options={{ label: membersLabel(user) }} />
          <Resource name="supporters" list={user.isAdmin ? SupporterGrid : undefined} show={SupporterShow} icon={AccountIcon} options={{ label: "Supporters" }} />
          <Resource
            name="monitoring-partners"
            icon={GroupWorkIcon}
            show={PartnerShow}
            list={user.isSuperAdmin || user.isTreatmentPartnerAdmin ? WithFilter(PartnerList(user), { partnerType: "treatment" }) : undefined}
            edit={user.isSuperAdmin || user.isTreatmentPartnerAdmin ? PartnerEdit : undefined}
            create={user.isSuperAdmin ? WithInitialValues(PartnerCreate, { partnerType: "treatment" }) : undefined}
            options={{ label: monitoringPartnerLabel(user) }}
          />
          <Resource
            name="business-monitoring-partners"
            icon={BusinessIcon}
            show={PartnerShow}
            list={user.isSuperAdmin || user.isBusinessPartnerAdmin ? WithFilter(PartnerList(user), { partnerType: "business" }) : undefined}
            edit={user.isSuperAdmin || user.isBusinessPartnerAdmin ? PartnerEdit : undefined}
            create={user.isSuperAdmin ? WithInitialValues(PartnerCreate, { partnerType: "business" }) : undefined}
            options={{ label: user.isBusinessPartnerAdmin ? "My Facility" : "Business Partner Admin" }}
          />
          <Resource
            name="user-testing-schedule/week"
            icon={DateRangeIcon}
            list={
              user.isPartnerOrAdmin || user.isProctorAdmin ? ShowMemberInWeeklySchedule : undefined
            }
            options={{ label: "Weekly Test Schedule" }}
          />
          <Resource
            name="member-test-results"
            icon={AssignmentTurnedInIcon}
            list={
              user.isPartnerOrAdmin ? MemberTestResultsComponent : undefined
            }
            options={{ label: "Member Test Results" }}
          />
          <PhoneNumberModal />
        </Admin>
      </>
    );
  }

  return <UserInitializer setIsInitialized={setIsInitialized} />;
}

export default observer(App);

