import { useState, useEffect } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { GetMembers } from "../Services/MembersService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface MemberAutocompleteProps {
    facilityCode: string,
    label?: string;
    fullWidth: boolean
    onChange: (newValue: any) => void;
}

export const MemberAutocomplete: React.FC<MemberAutocompleteProps> = (props) => {
    const { facilityCode, label, fullWidth, onChange } = props;
    const [options, setOptions] = useState<object[]>([]);
    const { promiseInProgress } = usePromiseTracker({ area: "members" });

    useEffect(() => {
        trackPromise(GetMembers(facilityCode).then((members) => {
            const options = members?.sort((a, b) => {
                if (a.lastName < b.lastName) return -1;
                if (a.lastName > b.lastName) return 1;
                return 0;
            }).map(member => ({ id: member.userId, name: member.fullName, timeZone: member.timeZone })) ?? [];

            setOptions(options)
        }), "members");
    }, [facilityCode])

    const onAutocompleteChange = (event: any, newValue: any) => {
        onChange(newValue);
    }

    return <Autocomplete
        options={options}
        getOptionLabel={(option: any) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} variant="outlined" fullWidth />}
        onChange={onAutocompleteChange}
        loading={promiseInProgress}
        fullWidth={fullWidth}
    />
}

export default MemberAutocomplete;