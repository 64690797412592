import { observer } from "mobx-react-lite";
import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { usePromiseTracker } from "react-promise-tracker";


const Loading: React.FC = () => {

    const { promiseInProgress } = usePromiseTracker();

    return < div>
        <Dimmer active={promiseInProgress} page>
            <Loader />
        </Dimmer>
    </div>

}

export default observer(Loading);