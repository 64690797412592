import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { EnumType } from "typescript";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { getTestResults } from "../../Services/UserServices";
import { trackPromise } from "react-promise-tracker";
import MemberTestResults from "./MemberTestResults";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import ResultsPdfDownloadButton from "./ResultsPdfDownloadButton";

interface ITestSession {
  id: string;
  alcoholResult: string;
  panelResults: Array<string>;
  dateTestTaken: string;
  details: string;
  memberId: string;
  fullName: string;
  testMethod: EnumType;
  substancesDetected: string;
  testResult: string;
  memberPhoneNumber: string;
}
export const formatDate = (date: string): string => {
  try {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString();
  } catch {
    console.log(`Could not format date ${date}`);
    return "";
  }
};
const MemberTestResultsComponent: React.FC = (props) => {
  const [resultFilter, setResultFilter] = useState("");
  const [memberTestResults, setMemberTestResults] = useState<ITestSession[] | undefined>();

  const passedTestResults = memberTestResults?.filter((result) => {
    return result.testResult.toLowerCase() === MemberTestResults.Pass.toLowerCase() || 
    result.testResult.toLowerCase() === MemberTestResults.PassByAllowableSubstance.toLowerCase() || 
    result.testResult.toLowerCase() === MemberTestResults.PassWithInvalidResult.toLowerCase();
  });

  const failedTestResults = memberTestResults?.filter((result) => {
    return (
      result.testResult.toLowerCase() === MemberTestResults.FailByDecline.toLowerCase() ||
      result.testResult.toLowerCase() === MemberTestResults.FailByInvalid.toLowerCase() ||
      result.testResult.toLowerCase() === MemberTestResults.FailByMisstep.toLowerCase() ||
      result.testResult.toLowerCase() === MemberTestResults.FailByDetection.toLowerCase()
    );
  });

  const { user } = useClearMindAdminContext();
  const populateTestResults = async (facilityCode: string | null) => {
    const results = await getTestResults(facilityCode);
    setMemberTestResults(results);
  };

  useEffect(() => {
    if (!memberTestResults) {
      trackPromise(populateTestResults(user.facilityCode));
    }
  }, [memberTestResults, resultFilter, user]);

  const handleResultFilter = (e: any) => {
    setResultFilter(e.target.value);
  };

  const getResultColor = (result: string) => {
    if (result.toLowerCase() === MemberTestResults.Pass.toLowerCase() || result.toLowerCase() === MemberTestResults.PassByAllowableSubstance.toLowerCase()) {
      return "#2EAF7A";
    } else if (result.toLowerCase() === MemberTestResults.FailByDetection.toLowerCase()) {
      return "#D91C57";
    } else {
      return "#E4AC2C";
    }
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      cointainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
          alignItems: "center",
        },
        [theme.breakpoints.up("sm")]: {
          alignItems: "left",
        },
      },
      testResult: {
        color: "#333",
        fontWeight: 500,
        fontSize: "12px",
        marginLeft: "5px",
        display: "inline-flex",
      },
      cardContent: {
        display: "flex",
        backgroundColor: "#dee2e6",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "300px",
        },
        [theme.breakpoints.up("sm")]: {
          maxWidth: "400px",
        },
        padding: "5px",
        marginBottom: "5px",
        flexDirection: "column",
        justifyContent: "left",
      },
      time: {
        fontSize: "12px",
        fontWeight: 500,
      },
      formControl: {
        margin: "10px 0px",
        maxWidth: 160,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      resultIndicator: {
        height: "10px",
        width: "10px",
        borderRadius: "10px",
        marginTop: "2px",
        display: "inline-flex",
      },
      resultContainer: {
        disply: "flex",
      },
    })
  );

  const sortTestSessions = (a: ITestSession, b: ITestSession) => {
    if (a.dateTestTaken < b.dateTestTaken) {
      return 1;
    }

    if (a.dateTestTaken > b.dateTestTaken) {
      return -1;
    }

    return 0;
  };


  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace("+1", "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}-${match[3]} `;
    }
    return phoneNumber;
  };

  const classes = useStyles();

  const getFilteredResults = (filterValue: string): ITestSession[] | undefined => {
    if (filterValue === "pass") {
      return passedTestResults;
    } else if (filterValue === "fail") {
      return failedTestResults;
    }
    return memberTestResults?.filter((r) => r.testResult !== MemberTestResults.SelfReported);
  };

  return (
    <Grid className={classes.cointainer}>
      <FormControl className={classes.formControl}>
        <InputLabel shrink>Filter By</InputLabel>
        <NativeSelect value={resultFilter} onChange={(e) => handleResultFilter(e)} name="filter" className={classes.selectEmpty} inputProps={{ "aria-label": "filter" }}>
          <option value={""}>None</option>
          <option value={"fail"}>Fail</option>
          <option value={"pass"}>Pass</option>
        </NativeSelect>
      </FormControl>
      <Typography variant="h6" color="textSecondary">
        Member Test Results
      </Typography>
      {getFilteredResults(resultFilter)
        ?.sort(sortTestSessions)
        .map((result) => {
          return (
            <Card className={classes.cardContent}>
              <div className={classes.resultContainer}>
                <div
                  className={classes.resultIndicator}
                  style={{
                    backgroundColor: getResultColor(result.testResult),
                  }}
                ></div>
                <Typography className={classes.testResult}>Result: {result.testResult}</Typography>
              </div>
              <Typography className={classes.time} variant="h6" color="textSecondary">
                Name: {result.fullName}
              </Typography>
              <Typography className={classes.time} variant="h6" color="textSecondary">
                Date: {formatDate(result.dateTestTaken)}
              </Typography>
              <Typography className={classes.time} variant="h6" color="textSecondary">
                Substances Detected: {result.substancesDetected}
              </Typography>
              <Typography className={classes.time} variant="h6" color="textSecondary">
                Phone Number: <a href={"tel:" + result.memberPhoneNumber}>{formatPhoneNumber(result.memberPhoneNumber)}</a>
              </Typography>
              <ResultsPdfDownloadButton date={formatDate(result.dateTestTaken)} id={result.id} />
            </Card>
          );
        })}
    </Grid>
  );
};

export default MemberTestResultsComponent;
