import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  DELETE_MANY,
  fetchUtils,
  useQuery,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { Auth } from "aws-amplify";
import axios from "axios";

const adminBaseUrl = process.env.REACT_APP_API_BASE_URL;
const productBaseUrl = process.env.REACT_APP_CLEARMIND_BACKEND_URL;

const fetchJson = async (url: any, options: any = {}) => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  options.user = {
    authenticated: true,
    token: token
  };
  return fetchUtils.fetchJson(url, options);
};

const getChildResourceList = (resource: string, payload: { id: string, childResource: string }) => {

  return axios.get(`${resource}/${payload.id}/${payload.childResource}`);
}

const dataProviders = [
  {
    dataProvider: jsonServerProvider(adminBaseUrl, fetchJson),
    resources: ["admin-users"],
  },
  {
    dataProvider: {
      ...jsonServerProvider(productBaseUrl, fetchJson),
      getChildResourceList,
    },
    resources: [
      "test-sessions",
      "proctored-test-sessions",
      "user-testing-schedule/week",
      "users",
      "monitoring-partners",
      "member-test-results",
      "test-results",
      "members",
      "supporters",
      "scheduled-tests",
      "delete-scheduled-test"
    ],
  },
];

const dataProviderFactory = (type: any, resource: any, params: any) => {
  if (resource === "business-monitoring-partners") {
    resource = "monitoring-partners";
  }

  const dataProviderMapping = dataProviders.find((dp) =>
    dp.resources.includes(resource));

  const mappingType: any = {
    [GET_LIST]: "getList",
    [GET_ONE]: "getOne",
    [GET_MANY]: "getMany",
    [GET_MANY_REFERENCE]: "getManyReference",
    [CREATE]: "create",
    [UPDATE]: "update",
    [UPDATE_MANY]: "updateMany",
    [DELETE]: "delete",
    [DELETE_MANY]: "deleteMany",
    "getChildResourceList": "getChildResourceList",
  };

  return dataProviderMapping?.dataProvider[mappingType[type]](resource, params);
};

export const useChildResourceQuery = (parentResource: string, childResource: string, parentId: string) => {
  return useQuery(
    { resource: parentResource, type: "getChildResourceList", payload: { id: parentId, childResource: childResource } },
  );
}

export const reduceForDataGrid = (data: any[], idField: string, mapMethod: (record: any) => any = (record) => record) => {
  return data?.reduce((previous: any, current) => {
    previous[current[idField]] = mapMethod(current);
    previous.ids.push(current[idField])
    return previous;
  }, { ids: [] });
}

export default dataProviderFactory