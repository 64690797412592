import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
} from "react-admin";

export const AdminUserList = (props: any) => (
    <List {...props} pagination={false}>
        <Datagrid rowClick="show">
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
            <EmailField source="email" />
            <TextField source="role" />
            <TextField source="facilityCode" label="Facility Code" />
            <BooleanField source="active" />
            <BooleanField source="mfaEnabled" label="MFA Enabled" />
        </Datagrid>
    </List>);
