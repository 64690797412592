import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ScheduledEventsStore } from "../../Models/ScheduledEventsStore";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { LoadingIndicator, RecordContextProvider, SimpleShowLayout, TextField } from "react-admin";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { IScheduledEvent } from "../../Models/ScheduledEvent";

interface MemberEventsDisplayProps {
  id?: any;
  visible: boolean;
}

const MemberEventsDisplay: React.FC<MemberEventsDisplayProps> = (props: any) => {
  const { promiseInProgress } = usePromiseTracker({ area: "events-load" });
  const [events] = useState(ScheduledEventsStore.create());
  const [orderedDayList, setOrderedDayList] = useState<Date[]>([]);
  const { id, visible } = props;

  const hasEventsInCurrentMonth = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const isEventsInRemainingCurrentMonth = Object.keys(events.mappedEvents).some((dateKey) => {
      const eventDate = new Date(dateKey);
      return eventDate >= currentDate && eventDate <= new Date(currentYear, currentMonth, lastDayOfMonth);
    });
    return isEventsInRemainingCurrentMonth;
  };

  const useStyles = makeStyles({
    card: {
      maxWidth: 345,
      backgroundColor: "#fafafa",
      margin: "20px 0px",
    },
    cardContainer: {
      flexGrow: 1,
      overflow: "hidden",
    },
    message: {
      color: "red",
      height: 50,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    trackPromise(events.load(id), "events-load");
    getOrderedDayList();
  }, [events, id]);

  const getOrderedDayList = () => {
    const today = new Date();
    const endOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const differenceInTime = Math.abs(+endOfCurrentMonth - +today);
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    const days = [];

    for (let i = 0; i <= differenceInDays; i++) {
      const workingDate = new Date();
      workingDate.setDate(workingDate.getDate() + i);
      days.push(workingDate);
    }

    const listOfMonths = days.reduce((prev: any, curr) => {
      const month = curr.getMonth();

      if (!prev[month]) {
        prev[month] = [];
      }

      prev[month].push(curr);

      return prev;
    }, {});

    const reverseMonthList: Date[][] = [];

    for (let key in listOfMonths) {
      reverseMonthList.unshift(listOfMonths[key]);
    }

    setOrderedDayList(reverseMonthList.flat());
  };



  interface EventCardProps {
    events: any;
  }

  const EventCard: React.FC<EventCardProps> = (props: any) => {
    const { events } = props;
    return (
      <>
        {events.map((selectedDay: IScheduledEvent, index: any) => (
          <Card key={`event-day-list-${index}`} className={classes.card}>
            <RecordContextProvider value={selectedDay}>
              <Grid container spacing={1}>
                <CardContent>
                  <SimpleShowLayout>
                    <TextField source="localDateString" label="" />
                    <Divider />
                    <TextField source="localeTimeString" label="Time" />
                    <TextField source="eventType" label="Type" />
                    {selectedDay.eventDetails && <TextField source="eventDetails" label="Details" />}
                  </SimpleShowLayout>
                </CardContent>
              </Grid>
            </RecordContextProvider>
          </Card>
        ))}
      </>
    );
  };

  if (promiseInProgress || !events) {
    return <LoadingIndicator />;
  }

  if (!visible) {
    return <p className={classes.message}>This members events are set to private.</p>;
  }

  if (!hasEventsInCurrentMonth()) {
    return <p className={classes.message}>This member has no scheduled events to display this month.</p>;
  }

  return (
    <div className={classes.cardContainer}>
      {orderedDayList?.map((day: Date, index: any) => {
        const selectedDay: any[] = events.mappedEvents[day.toDateString().replace("/", "")];
        const isNullData = selectedDay === undefined;

        if (isNullData) {
          return null;
        }

        return (
          <>
            <React.Fragment key={`event-day-${index}`}>
              <EventCard events={selectedDay} key={index} />
            </React.Fragment>
          </>
        );
      })}
    </div>
  );
};

export default MemberEventsDisplay;
