import React, { useContext, useEffect } from "react";
import { ListContext } from "react-admin";
import TotalTestsTakenContext from "./TotalTestsTakenContext";
import { Datagrid, DateField, ListContextProvider, TextField, LoadingIndicator, ReferenceField } from "react-admin";
import { useChildResourceQuery, reduceForDataGrid } from "../../DataProvider";
import HistoricalResult from "../../Models/HistoricalResult";
import ResultsPdfDownloadButton from "../MemberTestResults/ResultsPdfDownloadButton";
import { formatDate } from "../MemberTestResults/MemberTestResultsComponent";


const ResultPdfField: React.FC<{ record?: any }> = ({ record }) => (
  <ResultsPdfDownloadButton
    date={formatDate(record?.dateTestTaken)}
    id={record?.id}
  />
);
const MemberPastTestResults: React.FC = (props: any) => {

  const { data, loaded } = useChildResourceQuery("members", "test-results", props.id);

  const dataGridData = reduceForDataGrid(data, "testSessionId", (record) => HistoricalResult.create(record));

  if (!loaded || !dataGridData) {
    return <LoadingIndicator />
  }

  return (
    <ListContextProvider 
    value={{
      data: dataGridData,
      ids: dataGridData.ids,
      currentSort: { field: "id", order: "ASC" },
      basePath: "/test-results",
      resource: "test-results",
      selectedIds: []
    }}>
      <TotalRecordsSetter />
      <Datagrid>
        <ReferenceField label="Date Test Taken" link={(record, reference) => `/${reference}/${record.testSessionId}/show`} source="testSessionId" reference="proctored-test-sessions">
          <DateField source="dateTestTaken" />
        </ReferenceField>
        <TextField source="testResult" label="Test Result" />
        <TextField source="substancesDetected" label="Substances Detected" />
        <ReferenceField link={false}label={"Download"} source="testSessionId" reference="proctored-test-sessions"  >
          <ResultPdfField  />
        </ReferenceField>
      </Datagrid>
    </ListContextProvider>
  );
};

const TotalRecordsSetter = () => {
  const listContext = useContext(ListContext);
  const { total } = listContext;

  const { setTotalTestsTaken } = useContext(TotalTestsTakenContext);

  useEffect(() => {
    if (!total) {
      setTotalTestsTaken(0);
    } else {
      setTotalTestsTaken(total);
    }
  }, [total, setTotalTestsTaken]);

  return null;
};


export default MemberPastTestResults;
