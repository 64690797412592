import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Step, ProgressBar } from "react-step-progress-bar";
import Scrubber from "./Scrubber";
import styles from "./ProgressBar.module.css";
import { ITestStep } from "../../Models/TestStep";

interface VideoProgressBarProps {
  onStepClick: (stepPlaybackTime: number) => void;
  playedSeconds: number;
  steps: ITestStep[];
  duration: number;
}

const VideoProgressBar: React.FC<VideoProgressBarProps> = (props) => {
  const { onStepClick, playedSeconds, steps, duration } = props;
  const [percent, setPercent] = useState(0);
  const [stepMarks, setStepMarks] = useState<number[]>([]);

  useEffect(() => {
    const percent = Math.round((playedSeconds / duration) * 100);
    setPercent(percent);
  }, [playedSeconds, duration])


  useEffect(() => {
    let stepPercentages: number[] = [];

    if (!steps) {
      return;
    }

    for (let i = 0; i < steps.length; ++i) {
      const stepSeconds = (steps[i].timestamp - steps[0].timestamp) / 1000
      const stepPercent = Math.round((stepSeconds / duration) * 100);

      stepPercentages.push(stepPercent);
    }

    setStepMarks(stepPercentages);
  }, [steps, duration]);

  const onClick = (stepTimestamp: number) => {
    if (!steps || steps.length === 0) {
      return;
    }

    const stepSeconds = (stepTimestamp - steps[0].timestamp) / 1000

    onStepClick(stepSeconds)
  }

  return (
    <>
      <div
        style={{
          margin: "0",
          padding: "10px 0px",
          border: "0",
          fontSize: "100%",
          verticalAlign: "baseline",
          boxSizing: "border-box",
        }}
      >
        <Scrubber
          duration={duration}
          percent={percent}
          onStepClick={onStepClick}
        />
        <ProgressBar
          style={{ position: "relative" }}
          stepPositions={stepMarks}
          percent={percent}
          filledBackground="#1F97F3"
        >
          {steps?.map((step, key) => {
            return (
              <Step
                key={key}
                position={stepMarks[step.index]}
                transition="scale"
              >
                {() => (
                  <div
                    onClick={() => onClick(step.timestamp)}
                    className={styles.step}
                  >
                    {step.index}
                  </div>
                )}
              </Step>
            );
          })}
        </ProgressBar>
      </div>
    </>
  );
};

export default observer(VideoProgressBar);
