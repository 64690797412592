import React, { useEffect, useState } from "react";
import getWeeklyTestSchedule from "../../Services/GetWeeklySchedule";
import DailyTestSchedule from "./DailyTestSchedule";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import { trackPromise } from "react-promise-tracker";
import PartnerSelector from "../PartnerCodeFilterSelector/PartnerCodeFilterSelector";
import { AddScheduledTest } from "./AddScheduledTest";
import { TriggerTest } from "./TriggerTest";

interface ITestSchedule {
  [key: string]: { memberName: string; testingHourUtc: string, memberId: string, testingHourLocal: number, testingDateUtc: string }[];
}

const WeeklyTestSchedule: React.FC = () => {
  const [weeklySchedule, setWeeklySchedule] = useState<ITestSchedule>({});
  const [dateToGet, setDateToGet] = useState(new Date());
  const { user } = useClearMindAdminContext();

  const determineInitialFacilityCode = () => {
    if (user.facilityCode) {
      return user.facilityCode;
    } else if (user.isSuperAdmin || user.isProctorAdmin) {
      return "All";
    } else {
      return "";
    }
  };

  const [facilityCode, setFacilityCode] = useState<string>(determineInitialFacilityCode());

  const days = [0, 1, 2, 3, 4, 5, 6];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dateContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      dateHeader: {
        fontSize: "18px",
        fontWeight: 800,
        marginBottom: "-4px",
      },
      weekDay: {
        fontSize: "13px",
        fontWeight: 400,
      },
      dateButton: {
        maxHeight: 75
      }
    })
  );

  const classes = useStyles();

  const getData = async (facilityCode: string, dateToGet: Date) => {
    const weeklyTestScheduleResponse = await getWeeklyTestSchedule(dateToGet, facilityCode);
    const keys = Object.keys(weeklyTestScheduleResponse.userTestTimes);
    const utcTestingDates = keys?.reduce((prev: any, curr) => {
      const testingHours = weeklyTestScheduleResponse.userTestTimes[curr];

      testingHours.forEach((hour: any) => {
        const utcDate = new Date(curr);

        utcDate.setUTCHours(hour.testingHourUtc);

        if (!prev[utcDate.toLocaleDateString()]) {
          prev[utcDate.toLocaleDateString()] = [];
        }

        prev[utcDate.toLocaleDateString()].push({ ...hour, testingHourLocal: utcDate.getHours(), testingDateUtc: curr });
      });
      return prev;
    }, {});
    setWeeklySchedule(utcTestingDates);
  };

  useEffect(() => {
    trackPromise(getData(facilityCode, dateToGet));
  }, [facilityCode, dateToGet]);

  const incrementDate = () => {
    const newDate = new Date(dateToGet.setDate(dateToGet.getDate() + 7));
    setDateToGet(newDate);
  };

  const decrementDate = () => {
    const newDate = new Date(dateToGet.setDate(dateToGet.getDate() - 7));
    setDateToGet(newDate);
  };


  return (
    <>
      <Grid spacing={1} container direction="row" justifyContent="space-between">
        {user.isSuperAdmin || user.isProctorAdmin ? <PartnerSelector setFacilityCode={setFacilityCode} facilityCode={facilityCode} /> : null}
        <div>
          {user.isSuperAdmin || user.isPartnerAdmin ? <AddScheduledTest facilityCode={facilityCode !== "All" ? facilityCode : ""} refresh={() => trackPromise(getData(facilityCode, dateToGet))} /> : null}
          {user.isSuperAdmin || user.isPartnerAdmin ? <TriggerTest facilityCode={facilityCode !== "All" ? facilityCode : ""} refresh={() => trackPromise(getData(facilityCode, dateToGet))} /> : null}
        </div>
      </Grid>
      <Grid spacing={1} container direction="row" justifyContent="space-between">
        <Button onClick={decrementDate} className={classes.dateButton}>
          <ArrowBackIosIcon fontSize="large" />
        </Button>
        {days.map((day, i) => {
          const testingDay = new Date(dateToGet);
          testingDay.setDate(testingDay.getDate() + day);
          const dayOfMonth = testingDay.getDate();
          const weekday = testingDay.toLocaleString("default", {
            weekday: "long",
          });
          const monthName = testingDay.toLocaleString("default", {
            month: "short",
          });

          return (
            <Grid className={classes.dateContainer} item key={`${day}-${i}`}>
              <Typography className={classes.dateHeader} variant="h4" color="textSecondary" align="center">
                {monthName} {dayOfMonth}
              </Typography>
              <Typography className={classes.weekDay} variant="h6" align="center">
                {weekday}
              </Typography>
              <DailyTestSchedule refresh={() => trackPromise(getData(facilityCode, dateToGet))} userTestingDays={weeklySchedule[testingDay.toLocaleDateString()] || []} date={testingDay.toLocaleDateString()} key={`daily-test-schedule-${i}`} />
            </Grid>
          );
        })}
        <Button onClick={incrementDate} className={classes.dateButton}>
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </Grid>
    </>
  );
};
export default WeeklyTestSchedule;
