import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { SelectInput, SimpleForm } from "react-admin";
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TestCubeLines from "./TestCubeLines";
import { observer } from "mobx-react";
import { ITestPanel } from "../../Models/TestPanel";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ITestResult } from "../../Models/TestResult";
import TabNextButton from "../Proctoring/TabNextButton";
import { Divider } from "semantic-ui-react";
import { TestTypes } from "../../Models/TestTypes/TestTypes";

interface TestCubeSideProps {
    testResult: ITestResult;
    whichSide: "A" | "B";
    hasPhoto: boolean
    setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
    tabIndex: number;
    onChangeTestCubeTypeOverride: (testCubeType: string | undefined) => void;
}
const TestCubeSideGrid: React.FC<TestCubeSideProps> = (props) => {
    const { testResult, whichSide, setActiveTabIndex, tabIndex, hasPhoto, onChangeTestCubeTypeOverride } = props;
    const [panelResults, setPanelResults] = useState(testResult.sideAResults);
    const [isSideRecorded, setIsSideRecorded] = useState(false);
    const [currentTestCubeType, setCurrentTestCubeType] = useState(testResult.testCubeTypeOverride ? testResult.testCubeTypeOverride : testResult.testCubeType);

    useEffect(() => {
        if (whichSide === "A") {
            setIsSideRecorded(testResult.isSideARecorded);
        }
    }, [
        testResult.isSideARecorded,
        testResult.isSideBRecorded,
        whichSide])

    useEffect(() => {
        if (whichSide === "A") {
            setPanelResults(testResult.sideAResults);
        } else {
            setPanelResults(testResult.sideBResults);
        }
    }, [
        testResult.sideAResults,
        testResult.sideBResults,
        whichSide]);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            header: {
                textAlign: "center",
                transform: "rotate(-90deg)",
                color: "white",
                minWidth: 60,
                marginTop: -60
            },
            button: {
                margin: theme.spacing(1),
                backgroundColor: "#4395CA",
                "&:hover": {
                    backgroundColor: "#3EC0BE",
                    color: "#FFF"
                }
            },
            buttonContainer: {
                justifyContent: "flex-end"
            },
            controlRow: { minWidth: 60, marginTop: 10 },
            testRow: { minWidth: 60, marginTop: 30 },
            container: {
                paddingTop: 90
            },
            infoSpan: {
                color: "#4395CA",
                fontWeight: 600
            },
            infoIcon: {
                color: "#4395CA",
                paddingTop: 12
            },
            warningSpan: {
                color: "#E5AC2E",
                fontWeight: 600
            },
            warningIcon: {
                color: "#E5AC2E",
                paddingTop: 12
            },
            successSpan: {
                color: "#2DAF79",
                fontWeight: 600
            },
            successIcon: {
                color: "#2DAF79",
            },
            infoContainer: {
                flexFlow: "row",
                color: "#333"
            }
        }),
    );
    const classes = useStyles();

    const HeaderRow = () => {
        return <>
            {panelResults.map(({ headerBackgroundColor, headerLabel }) =>
                <Paper style={{ backgroundColor: headerBackgroundColor }} className={classes.header}>
                    {headerLabel}
                </Paper>
            )
            }
        </>
    }

    const testSideCapturedAlert = () => {
        if (hasPhoto) {
            return (
                <>
                    <Grid item><CheckCircleOutlineIcon className={classes.successIcon} /></Grid>
                    <Grid item><span className={classes.successSpan}>Test Side {whichSide} confirmed by AI.</span></Grid>
                </>
            )
        }
        return (
            <>
                <Grid item><WarningIcon className={classes.warningIcon} /></Grid>
                <Grid item><span className={classes.warningSpan}>Unable to automatically verify Test Side {whichSide}. Please verify manually in video.</span></Grid>
            </>
        )
    }

    const ControlRow = () => {
        return panelResults.map((panel: ITestPanel, index) => {
            return <div className={classes.controlRow}>
                <TestCubeLines
                    onChange={(e) => panel.setControlLineChecked(e.target.checked)}
                    checked={panel.controlLine ?? false}
                    key={`control-line-${index}`}
                />
            </div>
        }
        )
    }
    const TestRow = () => {
        return panelResults.map((panel, index) =>
            <div className={classes.testRow}>
                <TestCubeLines
                    onChange={(e) => panel.setTestLineChecked(e.target.checked)}
                    checked={panel.testLine ?? false}
                    key={`test-line-${index}`}
                />
            </div>
        );
    }

    const InvalidRow = () => {
        return panelResults.map((panel, index) =>
            <div className={classes.testRow}>
                <TestCubeLines
                    onChange={(e) => panel.setInvalid(e.target.checked)}
                    checked={panel.invalidChecked ?? false}
                    key={`invalid-line-${index}`}
                />
            </div>
        );
    }

    const AllowableSubstanceRow = () => {
        return panelResults.map((panel, index) =>
            <div className={classes.testRow}>
                <TestCubeLines
                    onChange={(e) => panel.setPassByAllowableSubstance(e.target.checked)}
                    checked={panel.passByAllowableSubstance ?? false}
                    key={`allowable-substance-line-${index}`}
                />
            </div>
        );
    }

    const handleTestCubeTypeOverrideChange = (event: any) => {
        if (event.target.value !== currentTestCubeType) {
            setCurrentTestCubeType(event.target.value);
            onChangeTestCubeTypeOverride(event.target.value);
        }
    }

    return (
        <>
            <SimpleForm toolbar={false}>
                {tabIndex === 1 ? <SelectInput
                    onChange={handleTestCubeTypeOverrideChange}
                    source="testCubeTypeOverride"
                    choices={[
                        { id: TestTypes.SalivaConfirm12, name: TestTypes.SalivaConfirm12 },
                        { id: TestTypes.SalivaConfirm14, name: TestTypes.SalivaConfirm14 }
                    ]}
                    fullWidth
                /> : undefined}
            </SimpleForm>
            <Grid container className={classes.infoContainer} direction="row" spacing={3}>
                <Grid item><InfoIcon className={classes.infoIcon} /></Grid>
                <Grid item>
                    <span className={classes.infoSpan}>
                        To record the test results from the video,
                        match the lines of Side {whichSide} shown on the test cube in the video to
                        the lines below
                    </span></Grid>
            </Grid>
            <Grid container className={classes.infoContainer} direction="row" spacing={3}>
                {testSideCapturedAlert()}
            </Grid>
            <Grid container className={classes.container}>
                <Grid item container direction="row" justifyContent="space-between"
                    alignItems="center">
                    {HeaderRow()}
                </Grid>
                <Grid style={{ paddingTop: whichSide === "B" ? "30px" : "0px" }} item container direction="row" justifyContent="space-between"
                    alignItems="center">
                    {ControlRow()}
                </Grid>
                <Grid style={{ paddingTop: whichSide === "B" ? "30px" : "0px" }} item container direction="row" justifyContent="space-between"
                    alignItems="center">
                    {TestRow()}
                </Grid>
                <div style={{ color: "#333", textAlign: "center", width: "100%" }}>
                    <Divider />
                    Click box below if Positive Result is due to Allowable Substance
                </div>
                <Grid item container direction="row" justifyContent="space-between"
                    alignItems="center">
                    {AllowableSubstanceRow()}
                </Grid>
                <div style={{ color: "#333", textAlign: "center", width: "100%" }}>
                    <Divider />
                    Check box below if neither line is visible
                </div>
                <Grid item container direction="row" justifyContent="space-between"
                    alignItems="center">
                    {InvalidRow()}
                </Grid>
            </Grid >
            {isSideRecorded && <TabNextButton setTabIndex={setActiveTabIndex} currentTabIndex={tabIndex} />}
        </>
    );
};

export default observer(TestCubeSideGrid)