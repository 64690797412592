import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";

interface PreferenceSelectionProps {
    emailValue: boolean,
    textValue: boolean,
    label: string,
    onTextPreferenceChange: (value: boolean) => void;
    onEmailPreferenceChange: (value: boolean) => void;
}

const PreferenceSelection: React.FC<PreferenceSelectionProps> = (props) => {
    const {
        emailValue,
        textValue,
        label,
        onTextPreferenceChange,
        onEmailPreferenceChange,
    } = props;
    const [emailChecked, setEmailChecked] = useState(true)
    const [textChecked, setTextChecked] = useState(true)
    useEffect(() => {
        if (emailValue !== undefined) {
            setEmailChecked(emailValue)
        }
        if (textValue !== undefined) {
            setTextChecked(textValue)
        }
    }, [emailValue, textValue])

    return (
        <Grid style={{ borderBottom: "1px solid", marginBottom: "16px" }} container direction="row" >
            <Grid item xs={10}>
                <Typography style={{ marginRight: 12 }} variant="subtitle1">{label}</Typography>
            </Grid>
            <Grid item xs={2} container wrap="nowrap">
                <Grid item>
                    <Grid>
                        Email
                    </Grid>
                    <Grid>
                        <Checkbox
                            checked={emailChecked}
                            color="primary"
                            onChange={e => {
                                onEmailPreferenceChange(e.target.checked);
                                setEmailChecked(e.target.checked);
                            }} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid>
                        Text
                    </Grid>
                    <Grid>
                        <Checkbox
                            color="primary"
                            checked={textChecked}
                            onChange={e => {
                                onTextPreferenceChange(e.target.checked)
                                setTextChecked(e.target.checked)
                            }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default observer(PreferenceSelection);