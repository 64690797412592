import {
  List,
  Datagrid,
  TextField,
  Show,
  DateField,
  BooleanField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ProctorReview from "../Proctoring/ProctorReview";

export const TestSessionList = (props: any) => (
  <List {...props} filter={{ reviewed: false, testCompleted: true }} pagination={false}>
    <Datagrid rowClick="show">
      <DateField source="dateTestTaken" label="Date of Test" showTime />
      <BooleanField source="reviewed" />
      <BooleanField source="testCompleted" label="Test Completed" />
      <BooleanField source="notificationConsentAgreement" label="Notification Consent" />
      <BooleanField source="wifiConfirmationAgreement" label="Test Protocol Agreement" />
      <BooleanField
        source="requiredMaterialsAgreement"
        label="Device\Materials Confirmation"
      />
      <TextField source="acceptedSubstances" label="Acceptable Substances" />
    </Datagrid>
  </List>
);

export const TestSessionShow = (props: any) => {
  const classes = makeStyles({
    root: {
      overflow: "visible",
    },
  })();
  return (
    <Show
      {...props}
      classes={{
        card: classes.root,
      }}
    >
      <ProctorReview {...props} />
    </Show>
  );
};
