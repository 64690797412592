import axios from "axios";
import { types, Instance, SnapshotIn, flow, applySnapshot } from "mobx-state-tree";
import { getAllNotificaionPreferences, getMemberNotificaionPreferences } from "../Services/GetNotificationPreference";


const _NotificationPreferenceModel = types.model("NotificationPreferenceModel", {
    monitoringPartnerUserId: types.maybe(types.string),
    memberId: types.maybe(types.string),
    isDefault: types.maybe(types.boolean),
    passResultEmail: types.maybe(types.boolean),
    passResultText: types.maybe(types.boolean),
    failResultEmail: types.maybe(types.boolean),
    failResultText: types.maybe(types.boolean),
    memberDeleteAccountEmail: types.maybe(types.boolean),
    memberDeleteAccountText: types.maybe(types.boolean),
    memberUpdateAllowableSubstancesEmail: types.maybe(types.boolean),
    memberUpdateAllowableSubstancesText: types.maybe(types.boolean),
    dateCreated: types.maybe(types.string),
    dateUpdated: types.maybe(types.string),
    hasDbRecord: types.maybe(types.boolean)
}).views((self) => ({
    getPreferenceFieldValue(preferenceField: string): string {
        const propertiesMap: {
            [key: string]: { email: boolean | undefined; text: boolean | undefined };
        } = {

            passResult: { email: self.passResultEmail, text: self.passResultText },
            failResult: { email: self.failResultEmail, text: self.failResultText },
            memberDeleteAccount: { email: self.memberDeleteAccountEmail, text: self.memberDeleteAccountText },
            memberUpdateAllowableSubstances: { email: self.memberUpdateAllowableSubstancesEmail, text: self.memberUpdateAllowableSubstancesText },
        };
        const properties = propertiesMap[preferenceField];
        if (properties.email && properties.text) {
            return "both";
        } else if (properties.email) {
            return "email";
        } else if (properties.text) {
            return "text";
        } else {
            return "none";
        }
    },
    get isLoaded() {
        return self.dateCreated === undefined
    },
    get PassResultLabel() {
        return "Receive Pass Result Alerts?"
    },
    get FailResultLabel() {
        return "Receive Failure Result Alerts?"
    },
    get MemberDeleteAccountLabel() {
        return "Receive Member Account Deletion Alerts?"
    },
    get MemberUpdateAllowableSubstancesLabel() {
        return "Receive Member Updated Allowable Substance Alerts?"
    },
})).actions((self) => ({
    loadDefaultPreferences: flow(function* (partnerId: string) {
        const result = yield getAllNotificaionPreferences(partnerId)
        const defaultPreferences = result.find((preference: any) => preference.memberId === "")
        const passResultEmail = defaultPreferences.passResultEmail
        const passResultText = defaultPreferences.passResultText
        const failResultEmail = defaultPreferences.failResultEmail
        const failResultText = defaultPreferences.failResultText
        const memberDeleteAccountEmail = defaultPreferences.memberDeleteAccountEmail
        const memberDeleteAccountText = defaultPreferences.memberDeleteAccountText
        const memberUpdateAllowableSubstancesEmail = defaultPreferences.memberUpdateAllowableSubstancesEmail
        const memberUpdateAllowableSubstancesText = defaultPreferences.memberUpdateAllowableSubstancesText
        const dateCreated = defaultPreferences.dateCreated
        const dateUpdated = defaultPreferences.dateUpdated

        const currentSnapshot = {
            passResultEmail: passResultEmail,
            passResultText: passResultText,
            failResultEmail: failResultEmail,
            failResultText: failResultText,
            memberDeleteAccountEmail: memberDeleteAccountEmail,
            memberDeleteAccountText: memberDeleteAccountText,
            memberUpdateAllowableSubstancesEmail: memberUpdateAllowableSubstancesEmail,
            memberUpdateAllowableSubstancesText: memberUpdateAllowableSubstancesText,
            dateCreated: dateCreated,
            dateUpdated: dateUpdated,
        }
        applySnapshot(self, currentSnapshot ?? {});
    }),
    loadMemberPreference: flow(function* (partnerId: string, memberId: string) {
        const result = yield getMemberNotificaionPreferences(partnerId, memberId)
        const memberPreference = result.find((preference: any) => preference.memberId === memberId)
        if (memberPreference) {
            const passResultEmail = memberPreference.passResultEmail
            const resultId = memberPreference.memberId
            const passResultText = memberPreference.passResultText
            const failResultEmail = memberPreference.failResultEmail
            const failResultText = memberPreference.failResultText
            const memberDeleteAccountEmail = memberPreference.memberDeleteAccountEmail
            const memberDeleteAccountText = memberPreference.memberDeleteAccountText
            const memberUpdateAllowableSubstancesEmail = memberPreference.memberUpdateAllowableSubstancesEmail
            const memberUpdateAllowableSubstancesText = memberPreference.memberUpdateAllowableSubstancesText
            const dateCreated = memberPreference.dateCreated
            const dateUpdated = memberPreference.dateUpdated

            const currentSnapshot = {
                passResultEmail: passResultEmail,
                partnerId: partnerId,
                memberId: resultId,
                passResultText: passResultText,
                failResultEmail: failResultEmail,
                failResultText: failResultText,
                memberDeleteAccountEmail: memberDeleteAccountEmail,
                memberDeleteAccountText: memberDeleteAccountText,
                memberUpdateAllowableSubstancesEmail: memberUpdateAllowableSubstancesEmail,
                memberUpdateAllowableSubstancesText: memberUpdateAllowableSubstancesText,
                dateCreated: dateCreated,
                dateUpdated: dateUpdated,
                hasDbRecord: true
            }
            applySnapshot(self, currentSnapshot ?? {});
        }

    }),
    updateDefaultPreferences: flow(function* (partnerId: string) {
        const dateUpdated = new Date().toLocaleTimeString()
        const defaultMemberId = ""
        const patch = {
            monitoringPartnerUserId: partnerId,
            memberId: defaultMemberId,
            passResultEmail: self.passResultEmail,
            passResultText: self.passResultText,
            failResultEmail: self.failResultEmail,
            failResultText: self.failResultText,
            memberDeleteAccountEmail: self.memberDeleteAccountEmail,
            memberDeleteAccountText: self.memberDeleteAccountText,
            memberUpdateAllowableSubstancesEmail: self.memberUpdateAllowableSubstancesEmail,
            memberUpdateAllowableSubstancesText: self.memberUpdateAllowableSubstancesText,
            dateUpdated: dateUpdated,

        }
        yield axios({
            url: `/monitoring-partner-notification-preferences`,
            baseURL: process.env.REACT_APP_API_BASE_URL,
            method: "patch",
            data: patch
        })
    }),
    updateMemberPreferences: flow(function* (partnerId: string) {
        const dateUpdated = new Date().toLocaleTimeString()
        const patch = {
            monitoringPartnerUserId: partnerId,
            memberId: self.memberId,
            passResultEmail: self.passResultEmail,
            passResultText: self.passResultText,
            failResultEmail: self.failResultEmail,
            failResultText: self.failResultText,
            memberDeleteAccountEmail: self.memberDeleteAccountEmail,
            memberDeleteAccountText: self.memberDeleteAccountText,
            memberUpdateAllowableSubstancesEmail: self.memberUpdateAllowableSubstancesEmail,
            memberUpdateAllowableSubstancesText: self.memberUpdateAllowableSubstancesText,
            dateUpdated: dateUpdated,

        }
        if (self.hasDbRecord) {
            yield axios({
                url: `/monitoring-partner-notification-preferences`,
                baseURL: process.env.REACT_APP_API_BASE_URL,
                method: "patch",
                data: patch
            })
        } else {
            yield axios({
                url: `/monitoring-partner-notification-preferences`,
                baseURL: process.env.REACT_APP_API_BASE_URL,
                method: "post",
                data: patch
            })
        }

    }),
    setPassResultEmail(value: boolean) {
        self.passResultEmail = value;
    },
    setPassResultText(value: boolean) {
        self.passResultText = value;
    },
    setFailResultEmail(value: boolean) {
        self.failResultEmail = value;
    },
    setFailResultText(value: boolean) {
        self.failResultText = value;
    },
    setMemberDeleteAccountEmail(value: boolean) {
        self.memberDeleteAccountEmail = value;
    },
    setMemberDeleteAccountText(value: boolean) {
        self.memberDeleteAccountText = value;
    },
    setMemberUpdateAllowableSubstancesEmail(value: boolean) {
        self.memberUpdateAllowableSubstancesEmail = value;
    },
    setMemberUpdateAllowableSubstancesText(value: boolean) {
        self.memberUpdateAllowableSubstancesText = value;
    },
    setMemberId(memberId: string) {
        self.memberId = memberId
    },
    setMemberPreference(preferenceField: string, value: string) {
        const setNotifications = (email: boolean, text: boolean) => {
            (self as any)[`${preferenceField}Email`] = email;
            (self as any)[`${preferenceField}Text`] = text;
        };

        if (value === "email") {
            setNotifications(true, false);
        } else if (value === "text") {
            setNotifications(false, true);
        } else if (value === "both") {
            setNotifications(true, true);
        } else if (value === "none") {
            setNotifications(false, false);
        }
    }
}))

export const NotificationPreferenceModel = types.snapshotProcessor(_NotificationPreferenceModel, {
    preProcessor(snapshot: INotificationPreferenceSnapshotIn) {
        const defaults = {
            isDefault: true,
            PassResultEmail: true,
            PassResultText: true,
            FailResultEmail: true,
            FailResultText: true,
            MemberDeleteAccountEmail: true,
            MemberDeleteAccountText: true,
            MemberUpdateAllowableSubstancesEmail: true,
            MemberUpdateAllowableSubstancesText: true,
            DateCreated: Date.now().toLocaleString(),
            DateUpdated: Date.now().toLocaleString(),
        };

        if (!snapshot) {
            return defaults;
        }

        return { ...snapshot, ...defaults };
    }
});

export interface INotificationPreferenceModel extends Instance<typeof NotificationPreferenceModel> { }
export interface INotificationPreferenceSnapshotIn
    extends SnapshotIn<typeof _NotificationPreferenceModel> { }

