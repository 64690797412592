import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React from "react";
import { observer, } from "mobx-react";
interface TestCubeLinesProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean
}

const TestCubeLines: React.FC<TestCubeLinesProps> = (props) => {
    const { onChange, checked } = props;
    const useStyles = makeStyles(() =>
      createStyles({
        root: {
          flexGrow: 1,
          paddingBottom: "2rem",
        },
        checkboxInput: {
          display: "none",
        },
        checkboxShown: {
          color: "#98717b",
          cursor: "pointer",
          fontSize: "30px",
        },
      })
    );
    const classes = useStyles();

    return (
      <div style={{ textAlign: "center" }}>
        <label>
          <input type="checkbox" onChange={onChange} checked={checked} className={classes.checkboxInput} />
          {checked ? <IndeterminateCheckBoxOutlinedIcon className={classes.checkboxShown} /> : <CheckBoxOutlineBlankIcon className={classes.checkboxShown} />}
        </label>
      </div>
    );
}
export default observer(TestCubeLines)