import React from "react";
import { TextField, EmailField, Show, SimpleShowLayout, BooleanField } from "react-admin";
import Grid from "@material-ui/core/Grid";
import PasswordResetModal from "../Members/PasswordResetModal";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import ChangeActiveStatus from "../Members/ChangeActiveStatus";

export const AdminUserShow = (props: any) => {
  const { user } = useClearMindAdminContext();

  const isAuthorizedUser = () => {
    return user.isSupportAdmin || user.isSuperAdmin;
  };
  const isAuthorizedAdmin = () => {
    return user.isSuperAdmin;
  };
  return (
    <>
      <Show {...props}>
        <Grid container>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="id" />
              <EmailField source="email" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="firstName" label="First Name" />
              <TextField source="lastName" label="Last Name" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <TextField source="role" />
              <TextField source="facilityCode" label="Facility Code" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <TextField label="Phone Number" source="phoneNumber" />
              <BooleanField source="active" />
            </SimpleShowLayout>
          </Grid>
          <Grid item>
            <SimpleShowLayout>
              <BooleanField source="mfaEnabled" label="MFA Enabled" />
            </SimpleShowLayout>
          </Grid>
          <Grid style={{ flexBasis: "5%" }} item xs={12}>
            {isAuthorizedUser() && <PasswordResetModal baseUrl={process.env.REACT_APP_API_BASE_URL} {...props} />}
            <br />
            {isAuthorizedAdmin() && <ChangeActiveStatus baseUrl={process.env.REACT_APP_API_BASE_URL} {...props} />}
          </Grid>
        </Grid>
      </Show>
    </>
  );
};
