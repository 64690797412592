import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
    FacilityPartnerResponse,
    GetPartners,
} from "../Services/PartnerService";
import { trackPromise } from "react-promise-tracker";

interface ParnterCodeSelectorProps {
    facilityCode: string;
    setFacilityCode: (facilityCode: string) => void;
}
const PartnerSelector: React.FC<ParnterCodeSelectorProps> = (props) => {

    const [facilities, setFacilities] = useState([]);
    const { setFacilityCode, facilityCode } = props;

    useEffect(() => {
        trackPromise(
            GetPartners()
                .then((res: any) => {
                    setFacilities(res);
                })
        );
    }, []);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                margin: theme.spacing(1),
                maxWidth: 360,
            },
            selectEmpty: {
                marginTop: theme.spacing(2),
            },
        }));

    const handleFacilityChange = (e: any) => {
        setFacilityCode(e.target.value);
    };

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel shrink>Filter by Facility</InputLabel>
            <NativeSelect value={facilityCode} onChange={(e) => handleFacilityChange(e)} name="facility" className={classes.selectEmpty} inputProps={{ "aria-label": "facility" }}>
                <option value={""}>None</option>
                <option value={"All"}>All Members</option>
                {facilities?.map((facility: FacilityPartnerResponse, i) => {
                    return <option value={facility.facilityCode} key={`partner-${i}`}>{facility.facilityName}</option>;
                })}
            </NativeSelect>
        </FormControl>
    );
}

export default observer(PartnerSelector);