import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useCreate, useNotify } from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import MemberAutocomplete from "./MemberAutocomplete";
import { useClearMindAdminContext } from "../../ClearMindAdminContext/ClearMindAdminContext";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            backgroundColor: "#2196f3",
            marginTop: "12px",
            marginRight: "12px",
            marginBottom: "10px",
            color: "#FFF",
            "&:hover": {
                backgroundColor: "#66a7dc",
            },
        },
    })
)
interface AddScheduledTestProps {
    facilityCode: string;
    refresh: () => void;
}

export const AddScheduledTest: React.FC<AddScheduledTestProps> = (props) => {
    const { user } = useClearMindAdminContext();
    const { facilityCode, refresh } = props;
    const notify = useNotify();
    const styles = useStyles();
    const [create, { loading }] = useCreate();

    const [date, setDate] = useState("");
    const [open, setOpen] = useState(false);
    const [hour, setHour] = useState(7);
    const [memberId, setMemberId] = useState("");
    const [selectedTimeZone, setSeectedTimeZone] = useState<string | null>(null);

    useEffect(() => {
        const getUserTimeZone = () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setSeectedTimeZone(timeZone);
        };
        getUserTimeZone();
    }, []);

    const onHourChange = (e: any) => {
        const value = +e.target.value;
        setHour(value);
    }
    const onTimeZoneChange = (e: any) => {
        const value = e.target.value;

        setSeectedTimeZone(value);
    }
    const onDateChange = (e: any) => {
        setDate(e.target.value);
    }
    const getUtcDateStrings = () => {
        const parts = date.split("-");
        const selectedDate = new Date(+parts[0], +parts[1] - 1, +parts[2]);
        const localHour = hour;

        // Set the local date and hour based on the selected timezone
        selectedDate.setHours(localHour);

        // Format the local date in the selected timezone
        const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZone: selectedTimeZone,
        } as Intl.DateTimeFormatOptions;

        const localDateString = selectedDate.toLocaleString("en-US", options);
        const formattedLocalDate = new Date(localDateString);
        const utcYear = formattedLocalDate.getUTCFullYear();
        const utcMonth = formattedLocalDate.getUTCMonth() + 1; // Months are 0-based in JavaScript
        const utcDay = formattedLocalDate.getUTCDate();
        const utcHour = formattedLocalDate.getUTCHours();
        return [`${utcMonth}/${utcDay}/${utcYear}`, utcHour.toString()];
    }


    const onAddClick = () => {
        const [utcDate, utcHour] = getUtcDateStrings();
        const data = {
            testDateUtc: utcDate,
            userId: memberId,
            testHourUtc: utcHour,
            schedulerId: user.id
        }

        const options = {
          onSuccess: () => {
            notify("Test added to schedule.  Test may not currently be visible because of current selected week.");
            refresh();
            setOpen(false);
          },
          onError: () => {
            notify("Failed to add test.");
          },
          onFailure: (error: any) => {
            notify(error.body.errors[0].message, {
              type: "error",
              messageArgs: { smart_count: 1 },
            });
          },
        };
        create("scheduled-tests", data, options);
    }

    const validHours = [
        { value: 7, display: "7:00 AM" },
        { value: 8, display: "8:00 AM" },
        { value: 9, display: "9:00 AM" },
        { value: 10, display: "10:00 AM" },
        { value: 11, display: "11:00 AM" },
        { value: 12, display: "12:00 PM" },
        { value: 13, display: "1:00 PM" },
        { value: 14, display: "2:00 PM" },
        { value: 15, display: "3:00 PM" },
        { value: 16, display: "4:00 PM" },
        { value: 17, display: "5:00 PM" },
        { value: 18, display: "6:00 PM" },
        { value: 19, display: "7:00 PM" },
        { value: 20, display: "8:00 PM" }
    ];
    const validTimeZones = [
        { value: "America/New_York", display: "Eastern Time" },
        { value: "America/Chicago", display: "Central Time" },
        { value: "America/Denver", display: "Mountain Time", },
        { value: "America/Los_Angeles", display: "Pacific Time" },
    ];
    const disableAddButton = () => {
        return hour < 0 || !date || !memberId;
    }

    const onMemberChange = (newValue: any) => {
        if (!newValue) {
            setMemberId("");
        } else {
            setMemberId(newValue.id);
        }
    }

    return <>
        <Button onClick={() => setOpen(true)} className={styles.button} startIcon={<AddIcon />}>
            Add Test
        </Button>
        <Dialog
            open={open}
        >
            <DialogTitle>Add Scheduled Test</DialogTitle>
            <DialogContent>
                <TextField
                    label="Test Date"
                    type="date"
                    value={date}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={onDateChange}
                    fullWidth
                />
                <br />
                <br />
                <FormControl fullWidth>
                    <InputLabel shrink>Hour</InputLabel>
                    <NativeSelect value={hour} onChange={(e) => onHourChange(e)}>
                        {validHours?.map((validHour: any, i) => {
                            return <option value={validHour.value} key={`valid-hour-${i}`}>{validHour.display}</option>;
                        })}
                    </NativeSelect>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <InputLabel shrink>Time Zone</InputLabel>
                    <NativeSelect value={selectedTimeZone} onChange={(e) => onTimeZoneChange(e)}>
                        {validTimeZones?.map((validTimeZone: any, i) => {
                            return <option value={validTimeZone.value} key={`valid-hour-${i}`}>{validTimeZone.display}</option>;
                        })}
                    </NativeSelect>
                </FormControl>
                <br />
                <br />
                <MemberAutocomplete facilityCode={facilityCode} fullWidth label="Member" onChange={onMemberChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button onClick={onAddClick} disabled={loading || disableAddButton()} >
                    {loading ? <CircularProgress size={21} /> : "Add"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}