import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { ITestResult } from "../../Models/TestResult";
import { observer } from "mobx-react";
import Paper from "@material-ui/core/Paper";

interface AlcoholSliderProps {
    testResult: ITestResult
}

const alcoholSliderMarks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

const AlcoholSlider: React.FC<AlcoholSliderProps> = (props) => {
  const { testResult } = props;

  const getSliderColor = (value: number) => {
    switch (value) {
      case 0:
        return "#d8dcc4";
      case 25:
        return "#b0d4d4";
      case 50:
        return "#6cbed0";
      case 75:
        return "#3c6286";
      case 100:
        return "#5a3d39";
      default:
        return "#d8dcc4";
    }
  };

  const useStyles = makeStyles({
    root: {
      height: "auto",
      minWidth: 140,
      display: "flex",
      flexDirection: "row-reverse",   
      alignItems: "center",  
    },
    slider: {
      color: getSliderColor(testResult.alcoholValue),
      alignSelf: "center",
      marginBottom: 0, 
      marginLeft: 6
    },
    thumb: {
      border: "8px solid currentColor",
      marginLeft: 0,   
      marginBottom: 0,
    },
    track: {
      height: "5px !important",
    },
    rail: {
      height: "5px !important",
    },
    colorBox: {
      backgroundColor: getSliderColor(testResult.alcoholValue),
      width: "20px",
      height: "20px",
      alignSelf: "center",
    },
  });

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Slider
        step={null}
        classes={{ thumb: classes.thumb, rail: classes.rail, track: classes.track }}
        className={classes.slider}
        orientation="horizontal"
        value={testResult.alcoholValue}
        onChange={(_, value) => testResult.setAlcoholResult(value)}
        aria-labelledby="horizontal-slider"
        marks={alcoholSliderMarks}
      />
      <Paper className={classes.colorBox} />
    </div>
  );
};

export default observer(AlcoholSlider)